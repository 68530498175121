import { AuthService, ErrorService } from '@akeela/auth';

import { LocalService } from '@akeela/local-storage';
import { HttpService } from '@akeela/properties';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { WorkflowService } from 'src/app/services/workflow.service';
import {  Router } from '@angular/router';
import { timer } from 'rxjs';

@Component({
  selector: 'app-akeela-dashboard',
  templateUrl: './akeela-dashboard.component.html',
  styleUrls: ['./akeela-dashboard.component.css']
})
export class AkeelaDashboardComponent implements OnInit, OnDestroy {
  usuario;
  identityCompleat = null;
  init_meet;
  user_meet;

  _second = 1000;
  _minute = this._second * 60;
  _hour = this._minute * 60;
  _day = this._hour * 24;
  end: any;
  now: any;
  day: any;
  hours: any;
  minutes: any;
  seconds: any;
  source = timer(0, 1000);
  clock: any;
  rowInterviews;


  env = environment;
  animate_init = true;
  animate_init_bottom = true;
  buttomModal;
  clientDetail;
  back = false;
  menuSelect = false;
  shared =  false;
  newInterview = false
  dataAndSelfi;
  filePreview;
  client;
  dataIdentity;
  compleatVerifi;
  loading = true;
  loadingTransaction = true;

  verifiedIdentification;
  copy;
  interviews = [];
  interviewsObj= {};
  rows = [];
  transactions;
  transaction;
  interviewTypeIcon = [];
  interviewType = [];
  formsDataResponse = {};
  interviewsTime={};
  interviewsTimeProgram;
  interviewsTimeProgramTemp;

  constructor(
    private _wfService: WorkflowService,
    private router: Router,
    private _httpService: HttpService,
 
    private datePipe: DatePipe,
    private _auth: AuthService,
    private localStorage: LocalService,



  ) { }

  ngOnInit(): void {

    this.getTransactions();

    this.usuario = this._auth.getSession();
    this._wfService.setShowBackDashboard(false);
    this._wfService.getIdentityCompleat$.subscribe((data: any) => {
      this.identityCompleat = data;
             
    });

  }

   ngOnDestroy(): void {
    this._wfService.setShowBackDashboard(true);
   }



  redirectTransactions(){

    this._wfService.setshowIdentityVerification(false);
    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    const url = 'INTER_IDENTITY_VERIF/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';


    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



  }



setIdentity(){

    this._wfService.setshowIdentityVerification(true);
    this.router.navigate(['private/ACCOUNT_OPENING/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_INTRO'], {
      queryParams: {
        role: 'CLIENT',
        profile: 'CLIENT',
        audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
      }
    });


}

redirectInbox(code, sidebar) {

  const prefixed = 'private';
  const url = 'ACCOUNT_OPENING/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';

  const queryParams = {
    role: this.localStorage.getItem('role'),
    profile: this.localStorage.getItem('profile'),
    audience: this.localStorage.getItem('audience'),
    code: code
  };



  this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



}

getMeet(){
  this.user_meet = this._auth.getUsername();
  this.init_meet = true;
}

setMeetData(e) {
    this.init_meet = false;
    this.user_meet = null;
    
}


redirectProducts(){

  window.location.href = `${environment.defaultStorefrondRoute}`;  
}


getTransactions() {
  const params = {
    name: 'account$myformalities_paginated$get',
    params: {
      request: {
        page: 0,
        size: 60
      },
      path: {
        username: this._auth.getUsername()
      },
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.transactions = response.content;
    this.rows = [];

    let  trans;
    let dateInt  = 0;

    for (const transaction of this.transactions) {

      let row = transaction;

      if(row.accountStatus[0].dateOfThing.startDate > dateInt && (row.accountStatus[0].additionalType == 'PI_IN_PROCESS' ||  row.accountStatus[0].additionalType == 'AC_INCOMPLETE')){

          trans = transaction;

      }
      this.getInterview(row?.identifier, row?.transactionNumber);
    
    }

    this.transaction = trans;


    this.interviews= [];
    this.interviewsObj = {};
    this.getInterviewType();
    this.loadingTransaction = false;

  }, error => {
    this.loadingTransaction = false;

  });
}


getInterview(transaction,transactionNumber) {
      
  const params = {
    name: 'interview$interviews_transaction$get',
    params: {
      request: {
        idTransaction: transaction
      },
      path: { 

      },
      body: {}
    }
  };

     this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    if(response){
      response['transaction'] = transactionNumber; 
      response['idTransaction'] = transaction; 


      this.interviews = [...this.interviews, response];

      this.interviewsObj[transaction] = response;
  
  this.interviewsTimeProgramTemp = null;
      this.setTimeInterview(response);

    }

  }, error => {
  });

  

}


setTimeInterview(response){
  this.interviewsTime[response?.idTransaction]= {
    now:'',
    end:'',
    day:'',
    hours:'',
    minutes:'',
    seconds:'',
    valid:''
  }

  this.clock = this.source.subscribe(t => {

  this.interviewsTime[response?.idTransaction]['now'] = new Date();
  //  this.end = new Date('01/01/' + (this.now.getFullYear() + 1) +' 00:00');
  let temp = this.toDateStringTime(response?.interviewDate);
 // let temp2 = '01/01/' + (this.interviewsTime[response?.idTransaction]['now'].getFullYear() + 1) +' 00:00';

  this.interviewsTime[response?.idTransaction]['end'] = new Date(temp);
  this.interviewsTime[response?.idTransaction]['valid'] = this.interviewsTime[response?.idTransaction]['end'] > this.interviewsTime[response?.idTransaction]['now'];
   // this.interviewsTime[response?.idTransaction]['end'] = this.toDateString(response?.interviewDate);
  if(this.interviewsTime[response?.idTransaction]['valid']){
     this.showDate(response?.idTransaction);

     if(this.interviewsTimeProgramTemp == null || this.interviewsTime[response?.idTransaction]['end'] < this.interviewsTimeProgramTemp){
      this.interviewsTimeProgramTemp = this.interviewsTime[response?.idTransaction]['end'];
      this.interviewsTimeProgram = response?.idTransaction;
      this.rowInterviews = this.interviewsObj[this.interviewsTimeProgram];
     }
  }

  });
}

getInterviewType() {    
  const params = {
    name: 'workflow_manager$attributes_options$get',
    params: {
      request: {},
      path: { 
        code: 'interviewType'
      },
      body: {}
    }
  };
  

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
    let nameItem = '';

    if(response.length){


      this.interviewType = [];
      for (let value of response) {
        nameItem = value.item.alternateName;
      if(nameItem){

        this.interviewType[nameItem] =   {value:nameItem, label: value.name} ;

      }
                


      }}
  
    });

}


convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
  
}

private toDateStringTime(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (  ("0" + (date.getMonth() + 1)).slice(-2)  + '/' + ("0" + (date.getDate())).slice(-2)  + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}



 toDateString(fecha): string {
  let temp = this.convertUTCDateToLocalDate(new Date(fecha)).toISOString().slice(0, 16);
  let date:Date = this.parseDateString(temp);

  return (("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' +date.getFullYear().toString())
   + ' ' + date.toTimeString().slice(0,5);
}


showDate(idTransaction){
  let distance = this.interviewsTime[idTransaction]['end'] - this.interviewsTime[idTransaction]['now'];
  this.interviewsTime[idTransaction]['day'] = Math.floor(distance / this._day);
  this.interviewsTime[idTransaction]['hours']  = Math.floor((distance % this._day) / this._hour);
  this.interviewsTime[idTransaction]['minutes']  = Math.floor((distance % this._hour) / this._minute);
  this.interviewsTime[idTransaction]['seconds']  = Math.floor((distance % this._minute) / this._second);
}



 parseDateString(date:string): Date {
  date = date.replace('T','-');
  let parts:any = date.split('-');
  let timeParts = parts[3].split(':');
  
 // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1]-1, parts[2], timeParts[0], timeParts[1]); // Note: months are 0-based

}



}
