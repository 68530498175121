<nav class="navbar navbar-expand-lg ak-nav-a">
    <div class="container-fluid">

        <app-logo-img class="animate__animated animate__pulse" (click)="redirectHome()" [theme]="env?.theme?.logoConf?.themeColorNav" [type]="env?.theme?.logoConf?.typeNav"></app-logo-img>
        <div class="navbar-nav" >
            <a class="active-cursor nav-item nav-link text-primary " (click)="login()">     {{ '@i18n-text-login' | i18n }}</a>
            <button *ngIf="env?.theme?.showRegister" type="button" class="nav-item   btn btn-outline-primary" (click)="register()">     {{ '@i18n-text-register' | i18n }}</button> &nbsp;
           
            <a class="nav-item btn btn-outline-primary d-none d-md-block" [href]="env.theme.site.url" *ngIf="env?.theme?.images==='MIBANCO'">Regresar</a>
          
        </div>

    </div>
</nav>