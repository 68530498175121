<h1>ngx-face-api-js</h1>

Comparativa de fotos
<!-- <div>
  <img allFaces [src]="imageSrc" width="300px" />
</div> -->

<!-- <div>
  <img
    allFaces
    [with]="['expressions', 'landmarks', 'ageAndGender']"
    [src]="imageSrc"
    width="300px"
  *ngIf="imageSrc!==''" />
</div>

{{ response | json }}

<h2>Select File</h2>
<div style="background-color: red">
  <input class="form-control" type="file" value="" (change)="selectFile($event)" accept=".jpg, .jpeg, .png" />
</div> -->