import { Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { AuthGuard } from '../../../guards/auth.guard';

export const PRIVATE_ROUTES: Route = {
    path: 'private',
    component: WorkflowComponent,
    children: WorkflowRoutes,
    canActivate: [AuthGuard]
};
