<div style="overflow-y: scroll;max-height:95vh!important;">
                    <div class="container box_scroll">
                        <div>

                                
                            <div class="card mb-4 pb-3">
                                <div class="card-body">

                                        <div class='text-secondary float-right font-weight-bold  mt-3'> 
                                                {{(transactionData?.dateOfThing?.modifiedDate?(transactionData?.dateOfThing?.modifiedDate | date: 'MMM d, yyyy'):(transactionData?.dateOfThing?.startDate | date: 'MMM d, y h:mm a'))}}
                                        </div>
                                    <h5 class="mt-3" >
                                 <span [innerHTML]="'Respuesta' | i18n" class="font-weight-bold"></span>
                                 <i class="text-primary far fa-comments ml-2"></i> 
                                 
                                    </h5>
                                <hr class="d-block d-md-none" >
                                    <br>
                                <div class="d-flex bd-highlight mt-2">


                                    <div class=" pr-2  bd-highlight" style=" width: 50px;">
                                            <div class="mr-2 d-inline-flex ak-abatar ak-dinamic-color-abatar " role="img"> AB </div>
                                    </div>

                                    <div class=" bd-highlight ">
                                            <div>
                                                <span [innerHTML]="env?.theme?.site?.name | i18n" class="font-weight-bold pr-2  "></span>
                                                <span [innerHTML]="'ha respondido' | i18n" class="font-weight-normal text-secondary"></span>
        
                                            </div>
        
                                            <div>
                                                    <i class=" far fa-comment-dots mr-2  text-secondary" style="font-size: 20px"> </i> 
                                                    <span [innerHTML]="transactionData?.accountStatus[0]?.clientName | i18n" class="font-weight-bold text-secondary"></span>
                                            </div>
        
        
                                            <div>
                                            <span [innerHTML]="transactionData?.accountStatus[0]?.responseDescription | i18n" class="font-weight-normal text-secondary"></span>
        
                                    </div>
                                </div>
        
                           </div>
                           
                           <ng-container *ngIf="!transactionData">
                                        <div class="float-right">
                                            <img  src="./assets/public/images/loading.gif" alt="">
                                        </div>
                            </ng-container>

                              </div>
                              </div>

                              <ng-container>
                                        <div class="active-cursor " *ngIf="sectionRequirementsIncompleats.length>0  " (click)="redirectRequirementsUpload();event_close()" >


                           <div class="card mb-4 pb-3">
                                        <div class="card-body">
        
                                <h5 class="mt-3  pb-3"  >
       
                                               <span class="font-weight-normal title">
                                                        <i class="fas fa-paperclip mr-1 text-primary "></i>Actualice los siguientes recaudos
                                                   
                                                       <button  class=" mt-md-0 btn text-secondary btn-link blink_me" >
                                                            Actualizar
                                                      </button>
                                    
                                                    </span>

              


                                        </h5>
       
                                                <ng-container *ngFor="let section of sectionRequirementsIncompleats ; let first3 = first; let y = index">
                                               
                                                    <div class="d-flex mt-3 pb-2">


                                                            <div class=" pr-2 pr-md-3 " >
                                                                    <i class=" far fa-circle text-primary" > </i> 

                    
                                                             </div>
                                                            <div>
                                                                    <span [innerHTML]="section.name | i18n:lang"></span>
                                                                    <div [innerHTML]="section.reasonIncomplete?section.reasonIncomplete.alternateName:'' | i18n:lang" class="font-weight-normal pb-1"></div>
                                                                    <div [innerHTML]="section.description | i18n:lang"class="font-weight-normal text-secondary"></div>
                    
                                                            </div>
                                                        
                                                     </div>
                    


                                                </ng-container>
                                        </div>

                                        <ng-container *ngIf="loadRecaudos">
                                                        <div class="float-right">
                                                            <img  src="./assets/public/images/loading.gif" alt="">
                                                        </div>
                                                    </ng-container>
                                   
                                                </div></div>

                                    </ng-container>


                 <ng-container *ngIf="(sectionIncompleats?.length > 0 )">


                                    <div class="card mb-4 pb-3">
                                                <div class="card-body">
                                                             
                                    


                                 <h5 class="mt-3 pb-3" >
                                        <span class="font-weight-normal title">
                                             <i class="fas fa-id-card-alt mr-1 text-primary"></i>Actualice la información solicitada
                                        </span>
                                       
                                 </h5>
   
                                 <ng-container *ngFor="let stage of sectionIncompleats; let first = first; let i = index">

                                        <ng-container *ngFor="let step of stage?.howToSteps ; let first2 = first; let x = index">

                                            <ng-container *ngFor="let section of step?.sections ; let first3 = first; let y = index">
                                             

                                 <div class="d-flex mt-3 pb-2">

                                        <div class=" pr-2  pr-md-3">
                                                <i class=" fas fa-circle text-primary" style="font-size: 12px"> </i> 

                                         </div>
                                        <div class="pb-1 active-cursor" (click)="gotoNexUrl(stage?.alternateName,step?.alternateName);event_close()" >

                                                    <span [innerHTML]="section?.sectionTitle | i18n:lang " class="font-weight-normal pb-1 " ></span>  

                                                    <button   class="  mt-md-0 btn text-secondary btn-link blink_me" >
                                                               
                                                        Actualizar

                                                    </button>

                                                    <br>
                                                
                                                    <div [innerHTML]="section?.sectionIncompleted?.description | i18n:lang" class="font-weight-normal text-secondary"></div>

                                       </div>

                                        
                                    
                                 </div>
                                 </ng-container>
                                 </ng-container>
                                 </ng-container>

                                 <ng-container *ngIf="loadDatos">
                                                <div class="float-right">
                                                    <img  src="./assets/public/images/loading.gif" alt="">
                                                </div>
                                            </ng-container>

     
                                        </div>
                                </div>
                                    </ng-container>

                             
                        </div>

                    </div>
                </div>

