<!--
<span  [innerHTML]="'1000000' | number: '1.0-0' | numberI18n:'es' " >    </span>
-->

<div class="w-100 animate__animated animate__fadeInDown ">

    <ul class="scrollmenu pl-0 pb-3 ">
        <li class="d-inline  mt-2 active-cursor">
            <div class="item-rd text-secondary fz-14 " [class.text-primary]="!code || code == 'inbox' || code == 'home'" [class.text-secondary]="!(!code || code == 'inbox' || code == 'home')" (click)="redirectInbox('inbox','HOME')">
                <span [innerHTML]="'@i18n-menu-label-client-inbox' | i18n "></span>
                <span>({{ ( inboxFormalities ) ? inboxFormalities : '0' }})</span>
            </div>
        </li>
        <li class="d-inline  mt-2 active-cursor">
            <div class="item-rd text-secondary fz-14 " [class.text-primary]="code == 'sent'" [class.text-secondary]="code != 'sent'" (click)="redirectInbox('sent','SENT')">
                <span [innerHTML]="'@i18n-menu-label-client-sent' | i18n "></span>
                <span>({{( sentFormalities ) ? sentFormalities : '0' }})</span>

            </div>
        </li>
        <li class="d-inline  mt-2 active-cursor">
            <div class="item-rd text-secondary fz-14 " [class.text-primary]="code == 'draft'" [class.text-secondary]="code != 'draft'" (click)="redirectInbox('draft','DRAFT')">
                <span [innerHTML]="'@i18n-menu-label-client-draft' | i18n "></span>
                <span>({{( draftFormalities ) ? draftFormalities : '0' }})</span>
            </div>
        </li>
    </ul>
</div>

<ng-container *ngIf="active">


    <ng-container *ngIf="totalFormalities == 0">

        <div class="animate__animated animate__fadeInUp mt-3 text-center col-12 border pl-2 pr-2  pt-3 pb-3 section-box">

            <div>

                <h5 class="mt-4 pt-1 mb-3">
                    Somos tu aliado de negocios y estamos listos para apoyarte
                    <br class="d-none d-md-block"> Abre tu primera cuenta con
                    <span [innerHTML]="'clientName' | i18n "> </span>
                </h5>

                <div (click)="new()" style=" width: fit-content;border-radius: 20px ;" class="btn-new active-cursor mt-5 p-4 border border-primary  section-box ml-auto mr-auto">

                    <div class="">
                        <i class="fas fa-money-check text-primary fa-2x"> </i>
                    </div>

                    <a href="javascript:void(0)" class=" mt-md-0  text-primary">
                    Crear Solicitud
            </a>

                </div>

            </div>

            <div class="text-left lines" [innerHTML]="'@i18n-new-solicitud' | i18n ">
            </div>


        </div>

    </ng-container>
    <ng-container *ngIf="formalities">
        <div class="d-flex align-items-start mb-4">
            <div class="w-90">
                <app-sub-header [subtitle]="formalitieI18n" [title]="'Tus solicitudes'"> </app-sub-header>
            </div>
            <div class="w-10 align-self-end" *ngIf="env.theme.images !== 'FACEBANK' ">
                <button (click)="new()" type="button" class="btn btn-outline-primary float-right btn-normal">
                    
                    
                    <span [innerHTML]="'@i18n-new' | i18n "></span>
                </button>
            </div>

        </div>
        <div class="animate__animated animate__fadeInUp">

            <hr>
            <div class="pt-4">

                <ng-container *ngFor="let row of formalities; let first = first; let i_stf = index">

                    <div style="border-radius: 10px;" class="w-100 bg-white section-box mb-4 p-2 p-md-3 ">

                        <div class=" list d-flex bd-highlight list-flex flex-wrap pb-0 pt-0 pb-md-1 pt-md-1">

                            <div class="mb-1 bd-highlight font-weight-bold w-95 order-1  " style="font-size: 1.2rem;">
                                {{row?.plan?.name}}
                            </div>


                            <div class="mb-1 bd-highlight  w-5 order-2 pull-right text-right text-primary">

                                <!-- comenntado por  juan /hay ue revisarlo row?.requestUser?.person?.identifier===usuario_session -->

                                <i class=" fa fa-trash " aria-hidden="true" *ngIf=" env.theme.images !== 'FACEBANK' &&  row.accountStatus[0].additionalType==='PI_IN_PROCESS' " (click)="confirmDelete(row)"></i>

                                <i *ngIf="!(cargando == row?.transactionNumber) && row?.accountStatus[0].additionalType == 'AC_INCOMPLETE'" class=" fas fa-eye"></i>
                                <i *ngIf="!(cargando == row?.transactionNumber) && row?.accountStatus[0].additionalType == 'AC_RECEIVED'" class=" fas fa-check "></i>


                            </div>

                            <div class="mb-1 bd-highlight  w-60 w-sm-100 order-3  text-secondary ">
                                <span [innerHTML]="'@i18n-transaction-m' | i18n "></span> {{row?.transactionNumber}}
                            </div>

                            <div class="mb-1 bd-highlight  w-40 w-sm-100 order-4  text-secondary  text-md-right">
                                <span class="badge badge-secondary">{{ row?.accountStatus? row?.accountStatus[0]?.clientName : '-'}}</span>
                            </div>

                            <div class="mb-1 bd-highlight  w-80 order-5">
                                {{ row?.accountStatus?row?.accountStatus[0]?.clientDescription: '-'}}
                            </div>

                            <div class="mb-1 bd-highlight  w-20 order-6  small text-right">
                                {{row?.dateOfThing?.modifiedDate | date: 'MMM d' }}
                            </div>


                            <div class="mb-1 bd-highlight  w-100 order-7  text-secondary ">
                                <div class="row pt-3">
                                    <div class="col-12 col-md-3 mb-3" *ngIf="row?.accountStatus">

                                        <button (click)="openFormalities(row?.transactionNumber)" type="button" class="btn-block btn-tramite btn btn-outline-primary btn-normal">
                                            <span [innerHTML]="'Datos de cliente' | i18n "></span>
                                        </button>
                                    </div>
                                    <div class="col-12 col-md-3 mb-3">

                                        <button (click)="openFormalities(row?.transactionNumber,true)" type="button" class="btn-tramite btn-block btn-link btn btn-outline-primary btn-normal">
                                        <span [innerHTML]="'Recaudos' | i18n "></span>
                                    </button>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-12 col-md-8">
                                <div class="d-flex  bd-highlight" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                    <div class="bd-highlight">
                                        <span class="small text-secondary">Datos del Cliente {{((indicatorsProgres[row?.transactionNumber])?.module_AO?.progress.completed+'/'+(indicatorsProgres[row?.transactionNumber])?.module_AO?.progress.total)}}</span>
                                    </div>
                                </div>

                                <div class="d-flex  bd-highlight pb-2" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                    <div class="bd-highlight w-100">
                                        <ng-container>
                                            <div class=" d-flex bd-highlight w-70 w-sm-100">
                                                <div class=" w-5">
                                                    <i class="fas fa-align-left text-secondary"></i>
                                                </div>
                                                <div class="line-2 bar w-80 pt-2" style="min-height: 20px">
                                                    <akeela-graphics [percent]="(indicatorsProgres[row?.transactionNumber])?.AO_range" [type]="'line'">
                                                    </akeela-graphics>
                                                </div>

                                                <div class=" w-15 text-right">
                                                    <span class="pl-2">{{(indicatorsProgres[row?.transactionNumber])?.AO_range}}%</span>
                                                </div>

                                            </div>
                                        </ng-container>

                                    </div>

                                </div>


                                <div class="d-flex  bd-highlight" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                    <div class="bd-highlight">
                                        <span class="small text-secondary">Recaudos {{((indicatorsProgres[row?.transactionNumber])?.module_RQ?.progress.completed+'/'+(indicatorsProgres[row?.transactionNumber])?.module_RQ?.progress.total)}}</span>
                                    </div>
                                </div>

                                <div class="d-flex  bd-highlight pb-2" *ngIf="row?.accountStatus[0].additionalType =='PI_IN_PROCESS'">
                                    <div class="bd-highlight w-100">
                                        <ng-container>
                                            <div class=" d-flex bd-highlight w-70 w-sm-100">
                                                <div class=" w-5">
                                                    <i class="fas fa-align-left text-secondary"></i>
                                                </div>
                                                <div class="line-2 bar w-80 pt-2" style="min-height: 20px">
                                                    <akeela-graphics [percent]="(indicatorsProgres[row?.transactionNumber]).RQ_range" [type]="'line'">
                                                    </akeela-graphics>
                                                </div>

                                                <div class=" w-15 text-right">
                                                    <span class="pl-2">{{(indicatorsProgres[row?.transactionNumber]).RQ_range}}%</span>
                                                </div>

                                            </div>
                                        </ng-container>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </ng-container>
            </div>
            <div class="w-100 ">
                <!-- PAGINATOR -->
                <div class="table-container ">
                    <div class="externo " *ngIf="paginator">
                        <akeela-paginator [paginator]="paginator" (response)="response_paginator($event)">
                        </akeela-paginator>
                    </div>
                </div>
            </div>

            <div class="w-100 text-center mb-3" *ngIf="formalities.length===0">
                <div>

                    <span class="icon animate__animated animate__fadeIn" [innerHTML]="(formalitieI18n + '-icon') | i18n "></span>
                    <i class="animate__animated animate__fadeIn animate__delay-1s exclamation fas fa-exclamation"></i>
                </div>
                <div class=" text-dark mt-2 mb-5 ">

                    <span style="font-size: 1rem" [innerHTML]="'@i18n-register-text-standard-paperwork' | i18n "></span>
                </div>
            </div>
        </div>
    </ng-container>


</ng-container>
<ng-container *ngIf="loading_table || !active ||totalFormalities > 0">
    <div>
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</ng-container>