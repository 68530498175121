import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpService } from '@akeela/properties';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService, RouteUtilsService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService, ErrorService } from '@akeela/common';
import { MiddlewareService } from '../../../../services/middleware.service';
import { Subscription } from 'rxjs';
import { RequirementsService } from '@akeela/requirements';
import { environment } from '../../../../../environments/environment';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { isNullOrUndefined } from 'util';
import { LocalService } from '@akeela/local-storage';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-private-sworn',
  templateUrl: './sworn.component.html',
  styleUrls: ['./sworn.component.css']
})
export class SwornComponent implements OnInit, OnDestroy, AfterContentInit {
  form;
  workflow;
  stage;
  step;
  audience;
  role;
  download_file = false;
  transactionNumber;
  loading = true;
  loadingCheck = false;


  activeSend = false;
  showContract = false;
  sectionsIncomplete: any = false;
  showWindowError; false;
  sections;

  formSheet;
  _back: any = null;
  _next: any = null;

  subscriptor_transaction: Subscription;
  subscriptor_verify_active: Subscription;

  transactionData;
  isRequirementModuleActive = null;

  requireLoadRequirements: any = false;
  requireReloadRequirements: any = true;
  requirementsIsStatusIncompleted: any = false;

  loadView = false;
  environment;

  queryParams;
  requirementsModuleActive = false;


  preview: any = null;
  tmpAcept = true;
  documents: any = [];

  autorizado = [];
  legalRepresentative = ''

  readonly_2 = false;

  paramsSubscription: Subscription;
  jsonData:any = {};


  constructor(
    private formBuilder: FormBuilder,
    private _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private _upload: UploadService,
    private _error: ErrorService,
    private _middleware: MiddlewareService,
    private _akeelaRequirementsService: RequirementsService,
    private i18n: I18nPipe,
    private localStorage: LocalService,
    private _properties: PropertiesService,
    private router: Router,
    private datePipe: DatePipe,
    private routerUtilService: RouteUtilsService

  ) { }

  ngOnInit() {

    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      if (!isNullOrUndefined(readonly)) {
        this.readonly_2 = readonly;
      }
    });

    this.akeelaWorkflowService.transactionData$.subscribe((transactionData) => {
      if (!isNullOrUndefined(transactionData)) {

        this.transactionData = transactionData;

        this._middleware.isModuleRequirementsActive().then((res: any) => {
          if (res !== true) {
            if (Object.keys(res).length === 0) {
              res = false;
            }
          }
          this.requirementsModuleActive = res;
          this.init();

        }, error => {
          this.init();
        });
      }
    });

    this.akeelaWorkflowService.worflowEvents$.subscribe((event: any) => {
      if (!isNullOrUndefined(event)) {
        console.log('CASO DE RESPUESTA EN ');
        console.log(event);
        if (event && event.action === 'showLoadingSuppliers') {

        }
      }
    });

  }

  getItemListLegalRepresentative() {

    if (!isNullOrUndefined(this.transactionData.person) || !isNullOrUndefined(this.transactionData.organization)) {
      const params = {
        name: 'account$related_persons$get',
        params: {
          request: {},
          path: {
            typeRelatedPerson: 'LEGAL_REPRESENTATIVE',
            personId: this.transactionData.organization ? this.transactionData.organization.identifier : this.transactionData.person.identifier
          },
          body: {}
        }
      };
      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

        if (response.length) {
          this.autorizado = [];

          for (let value of response) {

            if (value.person.condition.identifier == "2") {


              let json = {
                name: value.person.givenName + " " + value.person.familyName,
                title: value.person.jobTitle.name
              };

              this.autorizado = [...this.autorizado, json];

            } else {

              this.legalRepresentative = value.person.givenName + " " + value.person.familyName;

            }

          }
        };

        this.jsonData['autorizados'] = this.autorizado;
        this.jsonData['legalRepresentative'] = this.legalRepresentative;
        this.carFb(this.jsonData);
      });
    }
  }



  requirementsUpload(transactionNumber, requirements = false) {

    let url = [environment.staticLinks.requirementsUpload];
    const queryParams = {};
    this.router.navigateByUrl(
      this.router.createUrlTree(url,
        {
          queryParams: queryParams
        }
      )
    ).then(() => {
    });
  }

  encryptData(data) {

    try {
      return btoa(JSON.stringify(data));
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = atob(data);
      if (bytes.toString()) {
        return JSON.parse(bytes);
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  async init() {
    this.environment = environment;
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionNumber = this.akeelaWorkflowService.transactionNumber$.getValue();
    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.role = await this.routerUtilService.getParamAsync(this.route, 'role');
    this.audience = await this.routerUtilService.getParamAsync(this.route, 'audience');

    // this.audience = queryParams['audience'] ? queryParams['audience'] : this.localStorage.getItem('audience');
    // this.role = queryParams['role'] ? queryParams['role'] : this.localStorage.getItem('role');

    this.resetTransaction();
    this.form = this.formBuilder.group({
      check: ['', Validators.required]
    });
    //  this.verifyRequirements();
    this.getPreview();



  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.verifyRequirements();
    }, 6000);
  }


  getPreview() {
    this._properties.getProperty({
      endpointKey: 'app_properties$getProperty$get',
      propertyKey: 'app.display.template'
    }).subscribe(resp => {
      if (resp === true || resp === 'true') { // Mario: se condiciona para mostrar o no el preview
        const params = {
          name: 'account_opening$preview-document$get',
          params: {
            request: {
              role: this.role,
              audience: this.audience
            },
            body: {},
            path: {
              transactionNumber: this.transactionNumber
            }
          }
        };
        return this._httpService.executeRequest(params.name, params.params, true).subscribe((resp) => {
          this.preview = resp;

          if (!this.download_file && this.preview && this.transactionData.accountStatus[0].additionalType !== 'AC_REJECTED' && this.transactionData.accountStatus[0].additionalType !== 'AC_RECEIVED') {
            this.documents['all'] = {};

            this.documents['all']["ficha"] = { response: this.preview[0], preview: true };
          }

          if (this.download_file && this.environment.appEndPontDefaultConfig.showPayroll && this.transactionData.accountStatus[0].additionalType !== 'AC_REJECTED' && this.transactionData.accountStatus[0].additionalType !== 'AC_RECEIVED') {
            this.documents['all'] = {};

            this.documents['all']["ficha"] = { response: this.formSheet[0], preview: false };
          }


        }, error => {
          this.loading = false;
          this.preview = [];
        });
      } else {
        this.loading = false;
        this.preview = null;
      }
    }, (error) => {
      this.loading = false;
    });

  }


  show_filePreview(data) {
    this.show_file(data);

  }





  verifyRequirements() {
    this._middleware.isModuleRequirementsActive().then((response) => {
      this.isRequirementModuleActive = response;
      if (response === true) {
        const config = {
          statusResponseRequirement: {
            verified: 'VERIFIED',
            inconsistent: 'INCONSISTENT',
            incomplete: 'INCOMPLETED',
            rejected: 'REJECTED'
          },
          endpoints: {
            getRequirements: {
              identifier: '',
              name: 'requirements$transaction_requirements$get',
              params: {
                request: {
                  audience: this.transactionData.holder && this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
                },
                path: {
                  idPlan: this.transactionData.plan.identifier,
                  transactionNumber: this.transactionData.transactionNumber,
                  idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
                },
                body: {}
              }
            },
            saveRequirement: {
              params: {
                request: {},
                path: {},
                body: {
                  'transaction': {
                    'identifier': this.transactionData.identifier,
                    'name': this.transactionData.transactionNumber
                  },
                  'requirement': {
                    'identifier': null
                  },
                  'requirementArchives': [
                    {
                      'creator': {
                        'identifier': null
                      }
                    }
                  ],
                  'person': {
                    'identifier': this.transactionData.holder.identifier,
                    'identity': this.transactionData.holder.alternateName,
                  }

                }
              }
            },
            deleteRequirement: {
              name: 'requirements$requirement$delete',
              params: {
                request: {},
                path: {},
                body: {
                  alternateName: 'ACCREDITED',
                  transaction: {
                    'identifier': this.transactionData.identifier,
                    'name': this.transactionData.transactionNumber
                  },
                  requirement: {
                    'identifier': null
                  },
                  person: {
                    'identifier': this.transactionData.holder.identifier,
                  },
                  requirementArchives: [{
                    identifier: null
                  }]
                }
              }
            }
          }
        };
        // verifico si ya cargo todos sus recaudos...
        this._akeelaRequirementsService.verifyStatusUploadFiles(config).then((resp: any) => {
          // console.log('LA RESPUESTA ES ACAAAFFF');
          // console.log(resp);
          // if (resp.total_required_requirements_completed < resp.total_required_requirements) {
          //   this.requireLoadRequirements = true;
          // }
          this.verifyAllFilesUpload().subscribe((respon: any) => {

            this.requireLoadRequirements = !respon;
            this.loadView = true;

          }, error => {
            this.requireLoadRequirements = false;
            this.loadView = true;
          });



        }, error => {
          this.isRequirementModuleActive = false;
          this.loadView = true;
          this.verifyAllFilesUpload().subscribe((resp) => {

            this.requireReloadRequirements = resp;
          });
        });




      } else {
        this.loadView = true;
      }
    }, error => {
      this.loadView = true;
    });
  }


  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this.transactionNumber
          },
          body: {}
        }
      };

      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      });
    });
  }



  resetTransaction() {
    this.destroyTransactionDataSubscription();

    this.subscriptor_transaction = this.getTransactionData().subscribe((response: any) => {
      this.transactionData = response;
      let row = this.transactionData;


      //natural 


      if (row.person) {

        console.log("sss:",row );
        
        this.jsonData = {
          identifier:"123456789",
          name: this.capitalize(row.person.givenName) + " " + this.capitalize(row.person.familyName),
          nationality: row.person.nationality ? row.person.nationality.identifier : '',
          coutry: row.person.homeAddress ? row.person.homeAddress.addressCountry.description : '',
          city: row.person.homeAddress.city ? row.person.homeAddress.city.name : '',
          state: row.person.homeAddress ? row.person.homeAddress.state.name : '',
          postalCode: row.person.homeAddress ? row.person.homeAddress.postalCode : '',
          streetAddress: row.person.homeAddress ? row.person.homeAddress.streetAddress : '',
          homeAddressName: row.person.homeAddress ? row.person.homeAddress.name : '',
          floor: row.person.homeAddress ? row.person.homeAddress.floor : '',
          office: row.person.homeAddress ? row.person.homeAddress.office : '',

        }

      } else {

        if (row.organization) {

          if (row.organization.postalAddressList.length > 0) {
            for (let data of row.organization.postalAddressList) {

              if (data.addressType && data.addressType.name == "FISCAL_ADDRESS_PRINCIPAL") {

                this.jsonData = {
                  coutry: data.addressCountry ? data.addressCountry.description : '',
                  city: data.city ? data.city.name : '',
                  state: data.state ? data.state.name : '',
                  postalCode: data.postalCode ? data.postalCode : '',
                  streetAddress: data.streetAddress ? data.streetAddress : '',
                  floor: data.floor ? data.floor : '',
                  office: data.office ? data.office : '',
                  description: data.description ? data.description : ''
                }

              }
            }
          }


          this.jsonData['name'] = this.capitalize(row.organization.legalName);
          this.jsonData['organization'] = this.capitalize(row.organization.legalName);
          this.jsonData['taxID'] = row.organization.taxID;
          this.jsonData['nationality'] = row.holder.adminOrganization.address.addressCountry.name ? row.holder.adminOrganization.address.addressCountry.name : '';
          this.jsonData['acronym'] = row.organization.acronym;

         // debugger;
          this.jsonData['foundingDate'] = row.organization.foundingDate;
          this.jsonData['autorizados'] = this.autorizado;
          this.jsonData['legalRepresentative'] = this.legalRepresentative;

        }
      }

      if (environment.theme.images === 'FACEBANK') {
        this.documents['dowload'] = {};
        if (row.organization.nationality && row.organization.nationality.identifier == 'US') {

        //  debugger;
          this.w9Fb(this.jsonData);


        } else {

          this.w8Fb(this.jsonData);

        }



        this.cliFb(this.jsonData);
        this.getItemListLegalRepresentative();




      }

      if (environment.theme.images === 'AKEELA') {
        /*  this.w8(this.jsonData);
          this.w9(this.jsonData);
         */
      }


      // se verifica que el anterior trámite sea o no incompleto
      this.getHistoryStatusTransaction().then((history: any) => {
        console.log(history);
        if (this.requirementsModuleActive) {
          if (history.length >= 2) {
            if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {
              console.log('APLICA PARA RECAUDOS INCOMPLETOS DEVUELTOS');
              this.verifyAllFilesUpload().subscribe((resp) => {
                this.requirementsIsStatusIncompleted = !resp;
              }, error => {
                this.requirementsIsStatusIncompleted = false;
              });
            }
          }
        }



        this.subscriptor_verify_active = this.verifySectionsCompleted(response.identifier, response.holder.identifier).subscribe((actives: any) => {
          this.sections = actives;
          if (Object.keys(actives).length < 2) {
            if (response.accountStatus[0].additionalType !== 'AC_INCOMPLETE') {
              if (actives.length > 0) {
                if (actives[0].alternateName === 'ACCOUNT_CONTRACT' || actives[0].alternateName === 'ACCOUNT_CONTRACT_DECLARATION') {
                  this.showContract = true;
                  this.sectionsIncomplete = false;
                  this.formSheet = response.formsheets;
                  if (response.accountStatus[0].additionalType === 'AC_PENDING' || response.accountStatus[0].additionalType === 'AC_RECEIVED' || response.accountStatus[0].additionalType === 'AC_REJECTED') {
                    this.download_file = true;
                    this.loading = false;
                    this.akeelaStepService.setModeCurrentStep(true);
                  } else {
                    this.download_file = false;
                    this.loading = false;
                    this.akeelaStepService.setModeCurrentStep(false);
                  }
                } else {
                  this.showWindowError = true;
                  this.download_file = false;
                  this.loading = false;
                  this.sectionsIncomplete = true;
                  this.showContract = false;
                }
              } else {
                if (response.accountStatus[0].additionalType === 'AC_RECEIVED' || response.accountStatus[0].additionalType === 'AC_REJECTED' || response.accountStatus[0].additionalType === 'AC_PENDING') {
                  this.showContract = true;
                  this.sectionsIncomplete = false;
                  this.formSheet = response.formsheets;
                  this.loading = false;
                  this.download_file = true;
                  this.showWindowError = true;
                  this.akeelaStepService.setModeCurrentStep(true);

                } else {
                  this.showContract = false;
                  this.sectionsIncomplete = false;
                  this.formSheet = response.formsheets;
                  this.loading = false;
                  this.download_file = false;
                }

              }
            } else {
              // consultar si hay recaudos marcados como incompletos...



              if (actives.length > 0 && this.requirementsModuleActive) {
                if (actives[0].alternateName === 'ACCOUNT_CONTRACT' || actives[0].alternateName === 'ACCOUNT_CONTRACT_DECLARATION') {
                  this.showContract = true;
                  this.sectionsIncomplete = false;
                  this.formSheet = response.formsheets;


                  if (response.accountStatus[0].additionalType === 'AC_PENDING' || response.accountStatus[0].additionalType === 'AC_RECEIVED' || response.accountStatus[0].additionalType === 'AC_REJECTED') {
                    this.download_file = true;
                    this.loading = false;

                    this.akeelaStepService.setModeCurrentStep(true);


                  } else {
                    this.download_file = false;
                    this.loading = false;

                    this.akeelaStepService.setModeCurrentStep(false);

                  }
                } else {
                  console.log('Entra por el else');
                  this.sectionsIncomplete = true;
                  this.loading = false;
                  this.showWindowError = true;
                }
              } else {
                this.showWindowError = false;
                this.download_file = false;
                this.showContract = true;
                this.sectionsIncomplete = false;
                this.loading = false;
                this.formSheet = response.formsheets;
              }
            }
          } else {
            this.showWindowError = true;
            this.download_file = false;
            this.loading = false;
            this.sectionsIncomplete = true;
            this.showContract = false;
          }
        });
      });
    });
  }



  w9({ name, nationality, coutry, city, state, postalCode, streetAddress, homeAddressName, floor, office, organization }) {
    let stringJSON =
      {
        "type": "w9",
        "dowload": false,
        "nameDoc": "w9-" + name,
        "params": {
          "nationality": nationality,
          "coutry": coutry,
          "address1": (streetAddress ? streetAddress + ", " : '') + (homeAddressName ? homeAddressName + ", " : '') + (floor ? "#" + floor + " " : '') + (office ? "#" + office + "" : ''),
          "address2": (city ? city + ", " : '') + (state ? state + ", " : '') + (postalCode ? postalCode + ", " : ''),
          "name": name,
          "organization": organization
        }
      };

    this.documents['dowload']["w9"] = this.encryptData(stringJSON);

  }


  w9Fb({ name, nationality, coutry, city, state, organization, description }) {
    let stringJSON =
      {
        "type": "w9Fb",
        "dowload": false,
        "nameDoc": "w9-" + name,
        "params": {
          "nationality": nationality,
          "coutry": coutry,
          "address1": description,
          "address2": (city ? city + ", " : '') + (state ? state : ''),
          "name": name,
          "organization": ''
        }
      };

    this.documents['dowload']["w9"] = this.encryptData(stringJSON);

  }

  w8Fb({ name, nationality, coutry, city, state, description,identifier,taxID }) {
    let stringJSON =
      {
        "type": "w8Fb",
        "dowload": false,
        "nameDoc": "w8-" + name,
        "params": {
          "nationality": nationality,
          "coutry": coutry,
          "address1": description,
          "address2": (city ? city + ", " : '') + (state ? state : ''),
          "name": name,
          "identifier":taxID
        }
      };

    this.documents['dowload']["w8"] = this.encryptData(stringJSON);

  }

  cliFb({ name, nationality, coutry, city, state, description, taxID, acronym }) {
    let stringJSON =
      {
        "type": "cliFb",
        "dowload": false,
        "nameDoc": "cli-" + name,
        "params": {
          "acronym": acronym,
          "taxID": taxID,
          "nationality": nationality,
          "coutry": coutry,
          "address1": description,
          "city": (city ? city + ", " : ''),
          "state": (state ? state : ''),
          "name": name
        }
      };

    this.documents['dowload']["cli"] = this.encryptData(stringJSON);

  }


  carFb({ name, nationality, coutry, city, state, description, taxID, acronym, foundingDate, autorizados, legalRepresentative }) {


    let date1 = new Date(foundingDate);
    date1.setDate(date1.getDate() + 1);

    let dia = date1.getDate() ;
    let mes = date1.getMonth() + 1;
    let year = date1.getFullYear();
    let date2 = (year + "/" + mes + "/" + dia);

    let date3 = new Date();

    dia = date3.getDate();
    mes = date3.getMonth() + 1;
    year = date3.getFullYear();
    let date4 = (year + "/" + mes + "/" + dia);

    let stringJSON =
      {
        "type": "carFb",
        "dowload": false,
        "nameDoc": "car-" + name,
        "params": {
          "acronym": acronym,
          "taxID": taxID,
          "nationality": nationality,
          "coutry": coutry,
          "address1": description,
          "address2": (coutry ? coutry + ", " : '') + (city ? city + ", " : '') + (state ? state : ''),
          "name": name,

          "foundingDate": this.datePipe.transform(date2),
          "date": this.datePipe.transform(date4),
          "legalRepresentative": legalRepresentative,
          "autorizados": autorizados
        }
      };


    this.documents['dowload']["car"] = this.encryptData(stringJSON);

  }






  w8({ name, nationality, coutry, city, state, postalCode, streetAddress, homeAddressName, floor, office }) {
    let stringJSON =
      {
        "type": "w8",
        "dowload": false,
        "nameDoc": "w8-" + name,
        "params": {
          "nationality": nationality,
          "coutry": coutry,
          "address1": (streetAddress ? streetAddress + ", " : '') + (homeAddressName ? homeAddressName + ", " : '') + (floor ? "#" + floor + " " : '') + (office ? "#" + office + "" : ''),
          "address2": (city ? city + ", " : '') + (state ? state + ", " : '') + (postalCode ? postalCode + ", " : ''),
          "name": name

        }
      };

    this.documents['dowload']["w8"] = this.encryptData(stringJSON);

  }

  destroyTransactionDataSubscription() {
    if (this.subscriptor_transaction) {
      this.subscriptor_transaction.unsubscribe();
    }
  }
  destroySubscriptorVerifyActive() {
    if (this.subscriptor_verify_active) {
      this.subscriptor_verify_active.unsubscribe();
    }
  }

  verifyAllFilesUpload() {



    const params = {
      name: 'requirements$upload_all_docs$get',
      params: {
        request: {
          audience: this.audience
        },
        path: {
          idPlan: this.transactionData.plan.identifier,
          idTransaction: this.transactionData.identifier,
          idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  verifySectionsCompleted(idAccount, holder) {
    const params = {
      name: 'account_opening$sections-active$get',
      params: {
        request: {
          role: this.role,
          audience: this.audience
        },
        path: {
          idAccount: idAccount,
          holder: holder
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);

  }

  send() {
    //  if (this.form.get('check').value) {

    swal.fire({
      title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
      text: this.i18n.transform('@i18n-confirmation-are-you-shure-send-analisys'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
      cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
      reverseButtons: true
    }).then((result) => {


      if (!isNullOrUndefined(result.value) && result.value) {

        // validación: los recaudos que poseen fechas obligatorias, deben tenerlas registradas
        if (this.isRequirementModuleActive) {
          this.getRequirements().subscribe((response: any) => {
            const validation_dates_requirements = [];
            for (const i_req in response) {
              if (!isNullOrUndefined(i_req)) {
                for (const req of response[i_req]) {
                  if (!isNullOrUndefined(req.requirementArchives)) {
                    for (const file of req.requirementArchives) {
                      let mandatoryIssueDateError = false;
                      let mandatoryExpirationDateError = false;
                      if (req.requirement.mandatoryExpirationDate === true) {
                        if (file.expires) {
                          if (isNullOrUndefined(file.expires.orderDate)) {
                            mandatoryExpirationDateError = true;
                          }
                        }
                      }

                      if (req.requirement.mandatoryIssueDate === true) {
                        if (file.dateCreated) {
                          if (isNullOrUndefined(file.dateCreated.startDate)) {
                            mandatoryIssueDateError = true;
                          }
                        }
                      }

                      if (mandatoryIssueDateError === true || mandatoryExpirationDateError === true) {
                        validation_dates_requirements.push({
                          name: req.requirement.name,
                          mandatoryIssueDateError: mandatoryIssueDateError,
                          mandatoryExpirationDateError: mandatoryExpirationDateError
                        });
                      }
                      // alert(mandatoryIssueDateError);

                    }
                  }
                }
              }
            }
            if (validation_dates_requirements.length === 0) {
              this.sendForm();
            } else {
              let html_err = '<br>';
              let html_err_type = '';
              for (const err of validation_dates_requirements) {
                console.log(err);
                if (err.mandatoryIssueDateError === true) {
                  html_err_type = html_err_type + this.i18n.transform('@collections-text-emission');
                  if (err.mandatoryIssueDateError) {
                    html_err_type = html_err_type + ',';

                  }
                }
                if (err.mandatoryExpirationDateError === true) {
                  html_err_type = html_err_type + this.i18n.transform('@collections-text-expiration');
                }
                html_err_type =
                  html_err = html_err + '<br>' + err.name + ' (' + (html_err_type) + ')';
              }
              swal.fire({
                title: '',
                html: '<div style="text-align: center"><b>' + this.i18n.transform('@i18n-text-error-requirements-files-need-dates-information') + '</b>' + html_err + '</div>'
              });
            }
          });
        } else {
          this.sendForm();
        }

      } else {

      }


    });

    //  }
  }


  sendForm() {
    this.loadingCheck = true;
    const params = {
      name: 'account_opening$send_analisys$put',
      params: {
        request: {
          role: this.role,
          audience: this.audience
        },
        path: {
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step,
          event: 'SEND_ANALYSIS_ACCOUNT_OPENING_CONTRACT'
        },
        body: {}
      }
    };
    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.formSheet = response;
      this.download_file = true;
      this.loadingCheck = false;
      this.getTransactionData().subscribe((transactionData: any) => {
        this.akeelaWorkflowService.transactionData$.next(transactionData);
      });

      // ACTUALIZAR EL ESTATUS SUPERIOR
      this.akeelaWorkflowService.worflowEvents$.next({
        response: 'UPDATE_REQUIREMENT_FINISH',
        action: null,
        forms: null
      });

      this.resetTransaction();
    }, (error: any) => {
      this._error.show(error);
      this.loadingCheck = false;
    });
  }

  getRequirements() {
    const params = {
      name: 'requirements$transaction_requirements$get',
      params: {
        request: {
          audience: this.transactionData.holder && this.transactionData.holder.additionalType ? this.transactionData.holder.additionalType : 'LEGAL'
        },
        path: {
          idPlan: this.transactionData.plan.identifier,
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }



  show_file(file) {


    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: file.dateCreated.modifiedDate
      }
    };


    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor = true;
    }
  }

  ngOnDestroy() {
    this.destroyTransactionDataSubscription();
    this.destroySubscriptorVerifyActive();

    this.paramsSubscription.unsubscribe();

  }


  capitalize(word) {
    if (word[0]) {
      return word[0].toUpperCase() + word.slice(1);

    }
    return '';
  }

  objlength(data) {
    return Object.keys(data).length;
  }
}
