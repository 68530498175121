<div class="row">

    <div class="col-12 col-md-10 offset-md-1">
        <div class="row">

            <div class="col-12 mb-5">
                <div class="text-left">
                    <span class="pt-2 pb-2 font-weight-light">Verifícate</span>
                    <div class="line">
                        <div class="line4 bg-primary font-weight-light"></div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7 d-none d-md-block">
                <div class="photo-prev text-center bg-light">
                    <i class="fa-2x fas fa-camera"></i>
                </div>
            </div>
            <div class="col-12 col-md-5">

                <h5 class="font-weight-light mb-3">Selfi + ID</h5>
                <div class=" font-weight-light text-dark">
                    <div class="mb-3">Sácate una felfie con el ID</div>

                    <div class="mb-5 ml-4">
                        Formato vertical<br> Cara bien iluminada<br> En lo posible, hacerlo con un fondo blanco
                    </div>

                    <div class="mt-4 mb-3 text-center">
                        <button (click)="redirect()" class="btn btn-outline-primary btn-block ">Subir - Presiona OK al terminar</button>
                        <div class="small text-secondary">Tamaño máximo de archivo 10 MB!</div>
                    </div>

                    <div class="mb-3 mt-2 text-center">
                        <span class="font-weight-bold">123136273626332.jpg</span>
                        <a href="" class="float-right ">Ver</a>
                    </div>

                    <div class="mt-4 pt-4 mb-3 text-center">
                        <button (click)="redirect()" class="btn btn-primary  btn-block">Ok</button>
                        <div class="small text-secondary">Tamaño máximo de archivo 10 MB!</div>
                    </div>

                </div>
            </div>

        </div>




    </div>
</div>