import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
import { Table, Row, Column } from '@akeela/ak-table';


@Component({
  selector: 'app-section-commercial-references',
  templateUrl: './section-commercial-references.component.html',
  styleUrls: ['./section-commercial-references.component.css']
})
export class SectionCommercialReferencesComponent implements OnInit, OnDestroy {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  checkDoNotHaveRelatedCompany;
  showLoading = true;
  role;

  loading_table = false;
  configuration_table: Table;
  paramsSubscription: Subscription;


  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService
  ) { }

  ngOnInit() {

    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    });

    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.role = this.localStorage.getItem('role');
    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });

    // this.transactionDataSubscription = this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
    //   this.transactionData = transactionData;
    //   if (!isNullOrUndefined(this.transactionData) &&
    //     (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.person))) {
    //     this.getItemList();
    //   }
    // });


    this.transactionDataSubscription = this.getTransactionData().subscribe((response: any) => {

      this.transactionData = response;      
      if (!isNullOrUndefined(this.transactionData) && (!isNullOrUndefined(this.transactionData.holder.person) || !isNullOrUndefined(this.transactionData.organization) )) {
        if (isNullOrUndefined(this.transactionData.itHasNoCommercialReferences) || !this.transactionData.itHasNoCommercialReferences) {
          this.setCheckDoNotHaveCommercialReferences(this.transactionData.itHasNoCommercialReferences);
          this.getItemList();
        } else {
          this.setCheckDoNotHaveCommercialReferences(true);
          this.setShowLoading(false);

        }
      }
      this.setShowLoading(false);

    });





    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        if (response && (response.action === 'account_contacts$contact$post')) {
          this.getItemList();
        } else if (!isNullOrUndefined(response) && response.action === 'account_contacts$contact_has_not_reference$put') {
          this.setShowLoading(false);
          this.setCheckDoNotHaveCommercialReferences(response.response.itHasNoCommercialReferences);
          if (!response.response.itHasNoCommercialReferences) {

            this.getItemList();
          }
        } else if (response && response.action === 'showLoadingCommercialReferences') {
          
          if (!response.value) {
            this.openItem({});
          }
          
          this.setShowLoading(false);
          this.setCheckDoNotHaveCommercialReferences(false);
        } else {
          this.setShowLoading(false);
        }
      }
    });

  }

  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  setShowLoading(value: boolean) {
    this.showLoading = value;
  }
  setCheckDoNotHaveCommercialReferences(value: boolean) {
    this.setShowLoading(false);
    this.checkDoNotHaveRelatedCompany = value;
  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      // Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      // Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_board_of_directors') === 0) {
        return false;
      }
    }
    return true;
  }

  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {
    const params = {
      name: 'account_contacts$contacts_commercial_reference$get',
      params: {
        request: {},
        path: {
          idPerson: !isNullOrUndefined(this.transactionData.organization) ? this.transactionData.organization.identifier : this.transactionData.holder.person.identifier
        },
        body: {}
      }
    };

    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.setShowLoading(false);
      this.itemList = response;
      this.setConfigurationTable();

        this.akeelaWorkflowService.setResetEstatus(true);
      
      this.updateFather();
    }, error => {
      this.setShowLoading(false);
    });
  }

  updateFather() {
    const self = this;
    const modalFather = self.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather)) {
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)) {
        self.akeelaWorkflowService.worflowEvents$.next({
          response: fatherToUpdate,
          action: 'update_board_of_directors_pep'
        });
      }
    }
  }

  openItem(row) {
    this.akeelaSectionService.addModalSection({ name: 'COMMERCIAL_REFERENCES_MODAL' });
    this.akeelaSectionService.showModalSection('COMMERCIAL_REFERENCES_MODAL');
    this.akeelaSectionService.setModalFormValue('COMMERCIAL_REFERENCES_MODAL', row);
  }

  confirmDelete(row: any) {
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    const contactId = !isNullOrUndefined(row.organizationContact) ? row.organizationContact.identifier : row.person.identifier;
    const configuration = {
      name: 'account_contacts$contact$delete',
      params: {
        path: {
          idContact: contactId,
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step
        },
        request: {
          audience: this.queryParams.audience,
          role: this.queryParams.role
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params, true).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account_contacts$contact$post');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      // dfutrille esto debe borrarse.
      this.updateList('account_contacts$contact$post');
    });
  }

  updateList(action: string) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }





  /**tabla */
  
  setConfigurationTable() {
    const base: Column[] = this.setHeadTable();
    this.configuration_table = {
      cssCustom: '',
      width: '100%',
      header: {
        cssCustom: 'class-color-title-table-section',
        rows: [{
          backgroundColor: '#fff',
          columns: base
        }],
      },
      body: {
        cssCustom: '',
        rows: this.setContentTable(base)
      },
      footer: {
        cssCustom: '',
        rows: []
      }
    };
    console.log("configuration_table",this.configuration_table);
    
    
  }

  setHeadTable(){
    
    const base: Column[] = [
      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '400',
        html: 'Razón Social',
        cssCustom: '',
        enableRightBorder: false

      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      },
      {
        alignContent: 'right',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html: '',
        cssCustom: ''
      }
  ]
    return base;
  }
  
  setContentTable(configuration): Row[] {
    let content: Row[] = [];
    let i = 0;
   // console.log("setContentTable setContentTable : ");

    for (const row of  this.itemList) {
      i++;

      let temp = '-';
      if(row.alternateName == 'NATURAL'){

        temp = '@i18n-quote-option-list-applicantType-option-natural';

      }else if(row.alternateName == 'LEGAL'){
        temp = '@i18n-quote-option-list-applicantType-option-legal';


      }

 
        content = [...content,
          {
          identifier: row,
          cssCustom: '',
          enabled: true,
          columns: [
              this.setRowContentTable({

                configuration,
                number:0,
                html: row.organizationContact.legalName,
                cssCustom:'text-capitalize'
            
              }), 
              this.setRowContentTable({

                configuration,
                number: 1,
                html: 'Ver',
                cssCustom: 'ak-tabled-row-active text-capitalize'
    
              }),
              this.setRowContentTable({
                configuration,
                number:2,
                html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
                click: 'delete_item',
              })
          ]
        }
      ];
    }
    return content;
  }


  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click?row.click:null ;
    let dropdown = row.dropdown?row.dropdown:null ;
    let cssCustom = row.cssCustom?row.cssCustom:configuration[number].cssCustom;


let result = {
      
      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click:click,
      dropdown:dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }
  
return result;

  }



  table_response(response) {

    if (response.action === 'open') {
      this.openItem(response.object.identifier);

    }else if (response.action === 'action') {
       if (response.object.action === 'delete_item' && !this.readonlyMode) {

        this.confirmDelete(response.object.obj.identifier);
      }
    }
  }

  isNull(param){    

    return isNullOrUndefined(param);

  }

}



