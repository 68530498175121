




<footer (window:resize)="onResize($event)" class="footer animate__animated animate__fadeIn " #footer>
    
    <!--
    <div  *ngIf="environment.theme?.images==='AKEELA' && !isAuth" class="w-100 pt-3 d-none d-md-block" style="min-height: 200px">
                <img class="w-100 animate__animated animate__fadeIn  animate__delay-3s" src="./assets/public/images/banner_acmebank_horizontal.png" alt="">
    
        </div>

    <div *ngIf="environment.theme?.images==='AKEELA'&& !isAuth"  class="w-100 pt-3 d-md-none "  style="min-height: 444px">
            <img class="w-100 animate__animated animate__fadeIn  animate__delay-3s " src="./assets/public/images/banner_acmebank_mobile.png" alt="">
    </div>
-->
    <div class="container foter-preguntas">
        <div [ngClass]="[parent == 'dashboard'?'preg-dash':'','float-right']">
        </div>
    </div>
    <div *ngIf="line" class="w-100 p-b-10" style="border-top : 1px solid #979797;"></div>
    <div class="container-fluid  bg-white text-dark p-b-20 p-t-20">
        <div class="container ">
            <div class="p-b-10 b-b-1">
                <span [innerHTML]="'@i18n-quote-footer-text-standard' | i18n:lang "></span>
            </div>
            <div class="p-t-10">
                <span [innerHTML]="'@i18n-quote-footer-more-information' | i18n:lang"></span>
                <a [href]="environment.i18nConfig.constants.url">{{ environment.i18nConfig.constants.url }}</a>
            </div>
        </div>
    </div>
</footer>