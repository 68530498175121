<akeela-template>


    <ng-template akeela-content componentName="SectionShareHoldersRelated">
        <app-section-shareholders-relateds></app-section-shareholders-relateds>
    </ng-template>
    <ng-template akeela-content componentName="SwornSectionComponents">
        <app-private-sworn></app-private-sworn>
    </ng-template>
    <ng-template akeela-content componentName="CommentsSectionComponent">
        <private-resumen [short]="resumen_requirements"></private-resumen>
    </ng-template>
    <ng-template akeela-content componentName="PaperworkSectionComponent">
        <app-paperwork></app-paperwork>
        <!-- <app-requirements-camera></app-requirements-camera> -->
    </ng-template>
    <ng-template akeela-content componentName="SectionLegalRepresentativeComponent">
        <app-section-legal-representative></app-section-legal-representative>
    </ng-template>
    <ng-template akeela-content componentName="SectionShareholdersComponent">
        <app-section-shareholders></app-section-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="SectionBoardOfDirectorComponent">
        <app-section-board-of-directors></app-section-board-of-directors>
    </ng-template>
    <ng-template akeela-content componentName="SectionSuppliersComponent">
        <app-section-suppliers></app-section-suppliers>
    </ng-template>
    <ng-template akeela-content componentName="SectionCustomersComponent">
        <app-section-customers></app-section-customers>
    </ng-template>
    <ng-template akeela-content componentName="SectionRelatedCompanyComponent">
        <app-section-related-company></app-section-related-company>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountInstitutionComponent">
        <app-section-bank-account-institution></app-section-bank-account-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionBankAccountOtherInstitutionComponent">
        <app-section-bank-account-other-institution></app-section-bank-account-other-institution>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepComponent">
        <app-section-pep></app-section-pep>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepShareHoldersComponent">
        <app-section-pep-shareholders></app-section-pep-shareholders>
    </ng-template>
    <ng-template akeela-content componentName="SectionPepBoardOfDirectorsComponent">
        <app-section-pep-boardofdirectors></app-section-pep-boardofdirectors>
    </ng-template>
    <ng-template akeela-content componentName="ConstitutionDataComponent">
        <app-section-constitution-data></app-section-constitution-data>
    </ng-template>
    <ng-template akeela-content componentName="UploadSectionComponent">
        <app-private-requirements [isActiveResume]="false"></app-private-requirements>
    </ng-template>
    <ng-template akeela-content componentName="commercialReferencesSectionComponent">
        <app-section-commercial-references></app-section-commercial-references>
    </ng-template>

    <!-- PN -->

    <ng-template akeela-content componentName="legalRepresentativeSectionComponent">
        <app-section-legal-representative-pn></app-section-legal-representative-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepComponent">
        <app-section-pep-pn></app-section-pep-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionPNPepAssociationComponent">
        <app-section-pep-pn-association></app-section-pep-pn-association>
    </ng-template>



    <ng-template akeela-content componentName="customersPNSectionComponent">
        <app-section-customers-pn></app-section-customers-pn>
    </ng-template>

    <ng-template akeela-content componentName="supplierPNSectionComponent">
        <app-section-suppliers-pn> </app-section-suppliers-pn>
    </ng-template>

    <ng-template akeela-content componentName="personalReferencesPNSectionComponent">
        <app-section-personal-references-pn></app-section-personal-references-pn>
    </ng-template>

    <ng-template akeela-content componentName="SectionFiscalDirections">
        <div>
            <app-section-fiscal-directions></app-section-fiscal-directions>
        </div>
    </ng-template>

    <ng-template akeela-content componentName="imgLogo">
        <div class="mt-2">
            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
        </div>
    </ng-template>

    <ng-template akeela-content componentName="identityVerificationComponent">

        <app-identity-verification-start></app-identity-verification-start>
    </ng-template>


    <ng-template akeela-content componentName="identityVerificationPhotosComponent">
       <!-- <app-identity-verification-photos></app-identity-verification-photos> -->
               <app-requirements-camera></app-requirements-camera>

    </ng-template>


    <ng-template akeela-content componentName="identityVerificationEndComponent">

        <app-identity-verification-end></app-identity-verification-end>
    </ng-template>




</akeela-template>


<div class="ak-full-modal bg-gray " [ngClass]="{'size-menu-left': sizeMenuLeft}" *ngIf="showChangePass">



    <div class="header">
        <div class="d-flex bd-highlight pr-2 pl-2 pb-2">

            <div *ngIf="!sizeMenuLeft" class=" pt-1">
                <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
            </div>
            <div class="  pr-0 pl-md-3 flex-grow-1 bd-highlight">
                <h4 class=" pt-3" [innerHTML]="'@i18n-change-password' | i18n"></h4>
            </div>
            <div class=" bd-highlight pt-2 ">
                <a class=" text-dark" href="javascript:void(0)" (click)="setShowChangePassword(false)">
                    <img class="size-img close" src="assets/public/images/close.svg">
                </a>
            </div>
        </div>
    </div>
    <div class="body">
        <app-change-user-password></app-change-user-password>
    </div>
</div>
<div class="container">
    <div class="row">
        <ng-container *ngTemplateOutlet="templates['topAlert'];"></ng-container>
    </div>
</div>
<ng-container *ngIf="isAuth">



    <div class="container d-none d-md-block">
        <akeela-breadcrumb (response)="eventBreadcrumb($event)">
        </akeela-breadcrumb>
    </div>

    <ng-container *ngIf="showSubHeaderStep == true">
        <div class=" d-none " [ngClass]="{'d-block':showSubHeaderStep}">
            <app-breadcrumb [currentWorkflowDefault]="currentWorkflowDefault" [title]="title" [subtitle]="subtitle" [link]="link" [currentWorkflowChange]="currentWorkflowChange" (response)="get_response_breadcrub($event)"></app-breadcrumb>
            <!-- <div class="container">
                        <div class="row">
                            <ng-container *ngTemplateOutlet="templates['topAlert'];"></ng-container>
                        </div>
                    </div> -->
        </div>


    </ng-container>


    <div class=" container  {{ showWorkflow ? '' : 'd-none' }}">
        <workflow-embed-components></workflow-embed-components>
        <div class="{{ borderBox?'box-principal col-12 mb-5' :' p-0' }}">
            <main>
                <router-outlet class="animate__animated animate__fadeInDown "></router-outlet>
            </main>
        </div>
    </div>

</ng-container>

<div class="container mt-2" *ngIf="(!showWorkflow) || (!isAuth)">
    <div class="col-12  p-t-1 p-b-1">
        <img src="./assets/public/images/loading.gif" alt="">
    </div>
</div>