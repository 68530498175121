
<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
     <div class="col-12 mt-2">
            <ng-container *ngIf="itemList  && !showLoading && !checkDoNotHavePersonalReference">

                    <div *ngIf="!((itemList && itemList.length===0) && !showLoading && !checkDoNotHavePersonalReference)">

                        <div class="text-dark ak-title-table mt-2 mb-4 ">
                            <span [innerHTML]="'@i18n-register-text-standard-table' | i18n:lang "></span>
                            <span [innerHTML]="'@i18n-quote-PersonalReferences-section.title' | i18n:lang "></span>
                        </div>
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>

                    </div>
            
 
                <!--


                    <div *ngIf="!((itemList && itemList.length===0) && !showLoading && !checkDoNotHavePersonalReference)" class="d-none d-lg-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2 header-table">
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30  order-1  ml-4 ml-md-0 ">
                                <div >
                                    Nombre y Apellido
                                </div>
                            </div>
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25  order-2  ml-4 ml-md-0 ">
                                    <div >

                                         Documento de Identidad

                                    </div>
                                </div>
                               
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25  order-2  ml-4 ml-md-0 ">
                                    <div>

                                        Número de Documento
                                        
                                    </div>
                                </div>
                         </div>

                <div class="w-100 fz-body" *ngFor="let row of itemList; let first = first; let i_stf = index">
                    <hr  class="m-0 ">
                 
                         <div class="d-flex bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
    
                                <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30 order-1  ml-4 ml-md-0 ">
                                    <div class=" text-capitalize">
                                        {{row?.personContact?.givenName}} {{row?.personContact?.familyName}}
                                    </div>
                                </div>
                                <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25 order-2  ml-4 ml-md-0 ">
                                        <div class=" text-capitalize" [innerHTML]="identitys[row?.personContact?.additionalType] | i18n:lang ">
                                        </div>
                                    </div>
                                    <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-25 order-3  ml-4 ml-md-0 ">
                                            <div class=" text-capitalize">
                                                {{row?.personContact?.identity}} 
                                            </div>
                                        </div>
        
                                <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-4">
                                    <a *ngIf="!readonlyMode" href="javascript:void(0)" (click)="confirmDelete(row)" class="font-weight-bold">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </div>
        
                                <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-5 d-none d-md-block ">
                                    <a href="javascript:void(0)" (click)="openItem(row)" class="font-weight-bold">
                                        <i class="fas fa-angle-right text-primary"></i>
                                    </a>
                                </div>
                            </div>
                </div>
            -->
            </ng-container>
       
            <div class="w-100 text-left " *ngIf="(itemList && itemList.length===0) && !showLoading && !checkDoNotHavePersonalReference">
                    <div class="text-dark mt-2 mb-4">
                            <i class="text-secondary far fa-comment mr-3"></i>                     <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                    <span [innerHTML]="'@i18n-quote-PersonalReferences-section.title' | i18n:lang "></span>
                </div>
            </div>

            <ng-container *ngIf="showLoading && !checkDoNotHavePersonalReference">
                <div class="w-50 ">
                    <img class="" src="./assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>