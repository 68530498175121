<akeela-requirements-template>

    <ng-template akeelaRequirementsContent componentName="imgLogo">
        <div>
            <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
        </div>
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailHeaderContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsDetailFooterContent">
    </ng-template>
    <ng-template akeelaRequirementsContent componentName="requirementsHeaderContent">
        <ng-container *ngIf="isActiveResume">
            <!-- Acá irá el HEADER -->
            <div class="box-2" [innerHTML]="getInitialsHolder()"></div>
            <div class="box-3" [innerHTML]="getNameHolder()"></div>
            <div class="box-3 font-weight-bold">
                <b>
                    <small [innerHTML]="'#'+transactionData.transactionNumber"></small>
                </b>
            </div>
            <br>

            <div class="w-100">

                <ng-container *ngIf="transactionData && config_requirements">
                    <!-- Datos de la Operación-->
                    <h5 class="  ak-text-color-4" [innerHTML]="'Datos de la Solicitud' | i18n:lang">
                    </h5>

                    <div class="ak-line mb-3"></div>

                    <div class="row  mt-4 mb-4">
                        <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                            <div>
                                <div [innerHTML]="'Producto' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.plan?.financialProduct?.name" class="text "></div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 mb-sm-2">
                            <div>
                                <div [innerHTML]="'@i18n-quote-productInformation-form-label-planName' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.plan?.name" class="text"></div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4">
                            <div>
                                <div [innerHTML]="'Canal' | i18n:lang" class="label"></div>
                                <div [innerHTML]="transactionData?.availableChannel?.name" class="text"></div>

                            </div>
                        </div>

                    </div>

                </ng-container>
            </div>
        </ng-container>

        <div class="w-100" *ngIf="balance_requirements && config_requirements.isActiveProgressBarUpload">

            <div class="row">
                <div class="col-12 col-lg-10" style="font-size: 14px">
                    <akeela-requirements-progress [balanceRequirements]="balance_requirements" [configuration]="config_requirements"></akeela-requirements-progress>
                </div>
            </div>


        </div>

    </ng-template>
</akeela-requirements-template>


<app-template>
    <ng-template appContent componentName="topAlert">
        <!--

        <div class="container" *ngIf="transactionData?.accountStatus[0]?.additionalType==='AC_INCOMPLETE' && balance_requirements?.total_required_responses_user_requirements_completed_in_status_incompleted>0">

            <div class="alert alert-secondary" role="alert">
                Por favor revise los comentarios de cada recaudo y complete la información solicitada y vuelva para enviar a análisis.
            </div>
        </div> -->
    </ng-template>
</app-template>
<ng-container *ngIf="activeRequirements">

    <div class="row">

    <div class="col-12 col-md-8">
        <akeela-requirements [folder]="folder" [config]="config_requirements" (showFile)="show_file($event)" (openWindowRequirement)="openWindowRequirement($event)"
            (response)="response_requirements($event)" (finishupload)="finish_upload($event)" (responseTransactionChange)="response_transaction_change($event)"
            (responsetransaction)="response_transaction($event)" *ngIf="config_requirements"></akeela-requirements>

    </div>


   <div class="col-12 col-md-4">

    <div class=" mb-3 p-2 text-center" style="margin-top: -40px">
    <hr class="pb-4 d-none d-md-block">
        <h3  class="text-dark mb-3 " [innerHTML]="'@i18n-workflow-name-docsupport' | i18n" > </h3>
        <img  style=" width: 60px;" src="./assets/public/images/Adj_recaudos.png" alt="">
    
        <h5  class=" text-md-center text-left font-weight-bold text-dark mt-4 " [innerHTML]="'Por favor adjunte sus recaudos' | i18n" > </h5>

    </div>

    <div class="mt-3 des-rq p-3  w-100 p-2" style="font-size: 14px;">
        <div class="d-flex justify-content-star">
            <div >
                    Todos los recaudos identificados con   <i class="fas fa-star-of-life text-danger pl-2 pr-2 small"></i>    son requeridos digitalmente

            </div>
        </div>
        <div class=" mt-2 d-flex justify-content-star">
                <div>
                        Todos los recaudos identificados con   <i class="fas fa-star-of-life  pl-2 pr-2 small"></i>    son requeridos en físico a la hora de retirar su producto en la agencia
                </div>
            </div>
    </div>
</div>

    </div>

    <div class="mb-2 row ml-2 mr-2">
        <div class="col-12   text-center bg-primary-ligth mt-4  rounded ak-shadow p-3">
                <div class="mb-2">
                        <i class=" fas fa-tachometer-alt text-primary fa-3x"></i> 
        
                </div>
        
                    <div class="font-weight-bold pb-1 ">¿Quieres mayor rapidez en la aprobación?</div>
        
                    <div class="mt-2">Para mayor rapidez adjunte todos los recaudos que tienes disponible</div>

            </div>
     </div>


    <ng-container *ngIf="balance_requirements && (!config_requirements.isReadonly  || config_requirements.enableEspecialUpload.active)">
        <ng-container *ngIf="balance_requirements.total_required_requirements_completed >= balance_requirements.total_required_requirements">
            <div class="box  m-b-2 p-b-1 p-t-1">
                <div class="w-100 ">
                    <div class="">
                        <div class="col-12 col-md-4 offset-md-8 text-right">
                            <button type="button" [disabled]="submit_disabled" class="btn btn-block btn-primary btn-lg" (click)="continuar()">
                                <span [innerHTML]="'Continuar' | i18n:lang"></span>&nbsp;
                                <span *ngIf="submit_disabled">
                                    <!--  -->
                                    <i class="fa fa-refresh fa-spin"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="!config_requirements">
    <div class="container animate__animated animate__fadeIn ">
        <div>
            <img src="./assets/public/images/loading.gif" alt="">
        </div>
    </div>
</ng-container>