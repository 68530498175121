<div *ngIf="dinamicCssUrl">
    <!-- Estilos Dinámicos-->
    <link rel="stylesheet" [href]="dinamicCssUrl+'/style.css' | safe">
    <link rel="stylesheet" [href]="dinamicCssUrl+'/ak-styles-config.css' | safe">
    <link rel="stylesheet" [href]="dinamicCssUrl+'/ak-library-styles-config.css' | safe">

</div>
<input type="file" accept="image/*" capture="camera">


<div *ngIf="loadingAutoSave || loadingAutoSaveOut" class="ak-alert toast botton-right  {{loadingAutoSave?'animate__animated animate__fadeIn animate__faster ':'' }}  {{loadingAutoSaveOut?'d-none ':'' }} ">
    <!-- <div class="toast-header">
    <strong class="mr-auto">Bootstrap</strong>
    <small class="text-muted">11 mins ago</small>
    <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
    <span aria-hidden="true">&times;</span>
    </button>
    </div>-->
    <div class="toast-body">
        Guardando
        <i class="ml-1 fas fa-circle-notch fa-spin"></i>
    </div>
</div>


<!-- <ngx-doc-viewer 
    [viewerUrl]="'https://docs.google.com/gview?url=%URL%&embedded=true'" 
    [url]="'https://services.dev.arn.akeela.co:8443/archive/downloadFile?idArchive=149&contentDisposition=inline'"
    style="width:100%;height:100vh;">
</ngx-doc-viewer> -->


<!-- <ng-container *ngIf="env?.auth?.inactivity?.active === true">
    <app-keepalive [timeinactivity]="timeinactivity" *ngIf="reloadkeepalive">
    </app-keepalive>
</ng-container> -->


<!-- *SECCIÓN DE TEMPLATE TEMPLATE AUXILIAR* -->
<ng-container *ngTemplateOutlet="templates['mainContentApp'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBotton'];" style="font-weight: bold"></ng-container>
<ng-container *ngTemplateOutlet="templates['responsesWindowContent'];"></ng-container>
<ng-container *ngTemplateOutlet="templates['mainContentModalBottonMeet']; " style="font-weight: bold"></ng-container>

<!-- WINDOW REQUIREMENTS -->
<ng-container *ngTemplateOutlet="templateRequirements['requirementsWindowContent'];"></ng-container>

<div *ngIf="upload.visor" class="animated" [class.zoomOut]="!upload.visor">

    <ng-container *ngIf="env?.visor==='alternative'">
        <akeela-visor-google [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor-google>
    </ng-container>
    <ng-container *ngIf="env?.visor!=='alternative'">
        <akeela-visor [filename]="upload.visor_params.name" [filedate]="upload.visor_params.date" [visorparams]="upload.visor_params" [back]="this.upload._back" [next]="this.upload._next" (close)="window_close()" (eventaction)="action($event)">
        </akeela-visor>
    </ng-container>
</div>


<!-- aqui-->
<div class="capa-menu" *ngIf="showSidebarLeft && !showIdentityVerification" (click)="toggleMenu()"> </div>



<sd-akeela-template>
    <ng-template sd-akeela-content componentName="menuHeader">
        <app-logo-img (click)="redirectHome()" [theme]="env?.theme?.logoConf?.themeColorMenu" [type]="env?.theme?.logoConf?.typeMenu"></app-logo-img>
    </ng-template>

    <ng-template sd-akeela-content componentName="menuBody">
        <div class="menu-body mb-3 font-weight-bold " style="font-size: 16px">
            <span class="ak-font-roboto" [innerHTML]="'@i18n-title-header-menu-left' | i18n:lang "></span>

        </div>
    </ng-template>


    <ng-template sd-akeela-content componentName="menuFooter">
        <div class="container w-100 text-left mt-2 mb-2 menu-footer" style="font-size: 14px">

            <div class=" ak-font-roboto mb-2  font-weight-bold">
                <span>USUARIO</span>
            </div>

            <div *ngIf="_auth.getSession()?.businessName">
                <span>
                    <b> {{_auth.getSession()?.businessName}}</b>
                </span>
            </div>

            <div>
                <span *ngIf="_auth.getSession()?.firstName"> {{_auth.getSession()?.firstName}}</span>
                <span *ngIf="_auth.getSession()?.lastName"> {{_auth.getSession()?.lastName}}</span>
                <br>
                <span *ngIf="_auth.getSession()?.email"> {{_auth.getSession()?.email}}</span>
            </div>
            <div>
                <div class="mt-2">
                    <span *ngIf="_auth.getSession()?.lastLoginDate" class="text-menu-footer">Ultima Sesión:
                        <br>{{_auth.getSession()?.lastLoginDate | date: 'MMM d, y h:mm a' }}</span>

                </div>
            </div>
        </div>
    </ng-template>
</sd-akeela-template>

<akeela-sidebarLeft *ngIf="!showIdentityVerification" class="" [class.d-none]="!showSidebarLeft" (response)="eventMenu($event)">
</akeela-sidebarLeft>

<div class="">
    <app-header></app-header>


    <div class="container">
        <!--
   <div *ngIf="env?.theme?.images == 'AKEELA'" class=" bg-light  animate__animated animate__fadeInDown animate__delay-1s ak-font-roboto  mb-3 mt-3 alert alert-secondary w-100 text-center text-secondary p-1" style=" background: #fffbf0;">
                    Este es un sitio demostrativo y los trámites no serán procesados.
             </div>


-->

        <div *ngIf="localStorage.getItem('objToken')" class=" bg-light alert alert-secondary mt-3">

            <div class="row">
                <div class="col-12 col-md-10">
                    Su solicitud de
                    <span class="font-weight-bold" [innerHTML]=" localStorage.getItem('objTokenPlan') | i18n"></span> ha sido almacenada temporalmente. Ingrese o cree su cuenta de usuario para continuar.

                </div>

                <div class="col-12 mt-1 mt-md-2   col-md-2 text-right">
                    <a class=" text-primary" href="javascript:void(0)" (click)="deleteToken()">
                        Descartar
                    </a>
                </div>
            </div>
        </div>
    </div>


    <app-subheader></app-subheader>

    <router-outlet></router-outlet>

    <app-footer></app-footer>





</div>