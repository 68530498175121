<app-template>
    <ng-template appContent componentName="responsesWindowContent">
        <div class="full-modal-2 compleat-modal" style="height: 100vh!important;" *ngIf="showModalResponses">
            <div class="header active-cursor" style="text-align:right">
                <div class="float-right" (click)="showModalResponses=!showModalResponses">
                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                </div>
                <div class="pr-2 pl-2 pb-2 float-left">
                    <div class=" pt-1">
                        <app-logo-img [theme]="env?.theme?.logoConf?.themeColorNavModal" [type]="env?.theme?.logoConf?.typeNavModal"></app-logo-img>
                    </div>
                </div>
            </div>
            <div class="body" style="overflow:auto">
                <ak-responses (close)="showModalResponses = $event" [showCommentsBoolean]="showCommentsBoolean"></ak-responses>
            </div>
        </div>
    </ng-template>
</app-template>

<div>
    <div class=" mt-4" style="line-height: 1.4rem">

        <div *ngIf="!(link!==null) ">
            <div class="h4 font-weight-normal title " [innerHTML]="title | i18n"></div>
        </div>

        <div *ngIf="(link!==null) ">
            <div class="h4 font-weight-normal title" [innerHTML]="_transactionData?.plan?.name | i18n"></div>
        </div>

        <div *ngIf="subtitle!=='' && !(link!==null) ">
            <span class="font-weight-light sub-title text-secondary-2 " [innerHTML]="subtitle | i18n"></span>
        </div>

    </div>
    <div *ngIf="_transactionData" style="font-size: 14px">

        <div [innerHTML]="'Solicitud N° '+'<b>'+(_transactionData?.transactionNumber)" class=""></div>
        <!--
            <span [innerHTML]="'<b>Estatus: </b>'+ _transactionData?.accountStatus[0]?.clientName" class=""></span>
        &nbsp;
        <a (click)='view_mode_active()' href="javascript:void()">{{ (view_more?'Ver menos': 'Ver más') }}</a>
        <div [innerHTML]="'<span class=\'color-gray\'>'+(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'MMM d, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'MMM d, y h:mm a'))+'.</span> '+_transactionData?.accountStatus[0]?.clientDescription+''"
            class="" *ngIf="view_more"></div>
        -->
    </div>


    <div class="mt-4 mb-4">
    
    <div class="xx" id ="enviar">

            <div (click)="redirectEnviar()"  *ngIf="_transactionData && (compleatTransaction && (_transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS') || _transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE') " class=" p-2 active-cursor mb-2 mt-2 alert alert-secondary w-100 text-secondary p-1 trans-bg bg-white">
                   
                         <div class="font-weight-bold section-title  text-left  d-inline">
                            <i class="far fa-paper-plane "></i>
                             <span style="font-size: 14px" class="pl-1 text-dark font-weight-bold">
                                 Su solicitud esta lista para enviar 
                                 <!--
                                 , al enviar esta aceptado los 
                                 <a class="pt-1  text-primary text-description "> terminos y condiciones</a>
                                 -->
                             </span>
                         </div>
    
                         <div  class="float-md-right mt-md-0 mt-2 text-right">
                             <button  type="button" class=" blink_me btn btn-sm btn-outline-primary " >  Ir a Enviar Solicitud</button>
                         </div>
    
             </div>
</div>  

  <ng-container *ngIf="(_transactionData  &&   (compleatTransaction === false || (compleatTransaction && _transactionData?.accountStatus[0]?.additionalType != 'PI_IN_PROCESS'))) && !(_transactionData && (compleatTransaction && (_transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS') || _transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE') ) ">
  

        <!--     <app-template>
                     <ng-template appContent componentName="topAlertStatus"> -->
 
            
 
             <div  *ngIf="showTemplates" class="mt-2 mb-3 ak-font-roboto animate__animated animate__fadeInDown mt-2 alert ak-status alert-secondary  w-100 text-left text-dark p-1 pt-2 pb-2 " 
               [class.alert-warning]="_transactionData?.accountStatus[0]?.additionalType == 'AC_INCOMPLETE' "
               [class.alert-primary]="transactio_transactionDatanData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS'" 
               [class.alert-primary]="_transactionData?.accountStatus[0]?.additionalType == 'PI_IN_PROCESS' || _transactionData?.accountStatus[0]?.additionalType == 'AC_PENDING' ">
                     <i class=" text-secondary pl-2 pr-2 fas fa-info-circle " aria-hidden="true" style="    line-height: 26px;" ></i>
 
                 <span  class="font-weight-bold ">
                     {{_transactionData?.accountStatus[0]?.clientName}}
                 </span>
 
                 <span class='text-secondary float-right d-block d-md-none'> 
                         {{(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'MMM d, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'MMM d, y h:mm a'))}}
                        </span>
                 
                <br class="d-block d-md-none" >
                   <span class="text-dark pr-3 pl-2 pl-md-0" >{{_transactionData?.accountStatus[0]?.clientDescription}}</span> 
                    
                   <button *ngIf="incomplete" (click)="showModalResponses = true" class="ml-2 ml-md-0  mt-2 mt-md-0 btn btn-outline-secondary  btn-sm" >
                         <i class=" mr-1 far fa-comments text-primary" ></i>
                         Respuesta y comentarios
                         <i class=" pl-2 fas fa-circle text-danger blink_me" style="font-size: 7px" ></i>
                   </button>
 
 
                   <span class='text-secondary float-right d-none d-md-block'> 
                         {{(_transactionData?.dateOfThing?.modifiedDate?(_transactionData?.dateOfThing?.modifiedDate | date: 'MMM d, yyyy'):(_transactionData?.dateOfThing?.startDate | date: 'MMM d, y h:mm a'))}}
                        </span>
                 </div>  
                 
            <!-- </ng-template>
         </app-template> -->
                
    </ng-container>
</div>
    <app-menu-module *ngIf="_transactionData" [transactionData]="_transactionData" [selected]="currentWorkflowDefault" (response)="event_response($event)"></app-menu-module>


</div>