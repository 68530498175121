import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@akeela/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { AkeelaStepService, AkeelaWorkflowService } from '@akeela/workflow';
import { EmailService } from '../../../../services/email.service';
import { I18nService, I18nPipe } from '@akeela/i18n';
import { Subscription, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ErrorService } from '@akeela/common';
import { AlertService } from '@akeela/alert';
import { isNullOrUndefined } from 'util';
import { Utf8RegexService } from '../../../../services/utf8-regex.service';
import { LocalService } from '@akeela/local-storage';
import swal from 'sweetalert2';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit, OnDestroy {

  showSubHeaderStepSubscription;
  showSubHeaderStep;
  env: any = environment;


  subcription_events: Subscription;
  emailSubscription: Subscription;
  sendMailSubscription: Subscription;

  constructor(
    private i18n: I18nPipe,

    private akStepService: AkeelaStepService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private _email: EmailService,
    private _i18n: I18nService,
    private i18nPipe: I18nPipe,
    private _error: ErrorService,
    private _alert: AlertService,
    private _utf8: Utf8RegexService,
    private localStorage: LocalService
  ) { }

  ngOnInit() {
    
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.localStorage.setItem('created', 'false');

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {
      this.showSubHeaderStep = data;
    });

    this._auth.isAuth$.next(null);

    if (this.subcription_events) {
      this.subcription_events.unsubscribe();
    }
    this.subcription_events = this.akeelaWorkflowService.worflowEvents$.subscribe((event: any) => {
      if (event) {
        if (event.action === 'setNewClient') {
          this.localStorage.setItem('created', 'true');
          // this.login();

          this.sendMail(event.response.person.email, event.response).subscribe((event: any) => {

            swal.fire({
              title: this.i18nPipe.transform('@i18n-text-user-registred'),
              text: this.i18nPipe.transform('@i18n-text-user-registred-detail'),
              showCancelButton: false,
              confirmButtonText: this.i18n.transform('@i18n-popup-text-ok'),
              reverseButtons: true
            }).then((result) => {

              this.login();

            });
          });

          if (this.env.demoProd) {
            this.sendMailTemp("jesuli.montenegro@hazling.com", event.response).subscribe((event: any) => { });
            this.sendMailTemp("daniel@hazling.com", event.response).subscribe((event: any) => { });
            this.sendMailTemp("juan@hazling.com", event.response).subscribe((event: any) => { });

          }
        }
      }
    });

    document.body.classList.add('teme-gray');
  }

  login() {
    this.router.navigate(['security/login'], {});
  }

  sendMail(emailTo, data) {

    return new Observable(resolve => {
      const url = environment.urlFront + '/activate-account/' + data.identifier + '/' + data.name;
      const portal = environment.theme.site.name;
      const properties: object = {};
      properties['template'] = 'templates/' + this._i18n.lang + '/userconfirmation.html';

      let site_header = environment.theme.site.name;
      if (!isNullOrUndefined(environment.theme.image)) {
        if (!isNullOrUndefined(environment.theme.image.active)) {
          if (environment.theme.image.active) {
            site_header = '<img src="' + environment.theme.image.urlImageHeader + '" ></img>';
          }
        }
      }

      properties['attr'] = [{
        'key': '%%USERNAME%%',
        'value': data.identifier
      }, {
        'key': '%%URLDESCRIPTION%%',
        'value': this.i18nPipe.transform('@i18n-mail-verify-account')
      },
      {
        'key': '%%NOMBRE%%',
        'value': this._utf8.utf8Encode(data.person.givenName)
      },
      {
        'key': '%%PORTAL%%',
        'value': portal
      },
      {
        'key': '%%URL%%',
        'value': url
      },
      {
        'key': '%%PORTAL%%',
        'value': environment.theme.site.name
      },
      {
        'key': '%%FONT_COLOR%%',
        'value': environment.theme.fontColor
      },
      {
        'key': '%%BACKGROUND_COLOR%%',
        'value': environment.theme.backgroundColor
      },
      {
        'key': '%%PROCEDURE%%',
        'value': environment.procedureMail
      },
      {
        'key': '%%BACKGROUND_BORDER%%',
        'value': environment.theme.backgroundColorBorderMail
      },
      {
        'key': '%%ALIAS_APP%%',
        'value': site_header
      },
      {
        'key': '%%TEXTFOOTER%%',
        'value': !isNullOrUndefined(environment.theme.textFooterMail) ? environment.theme.textFooterMail : ''
      }];
      const email_message = {
        identifier: 1,
        dateOfThing: {},
        status: null,
        sender: null,
        text: null,
        type: 'text/html',
        subjectOf: 'Creación de Usuario',
        toRecipient: emailTo
      };
      const template = this._email.getTemplate(properties);
      this.emailSubscription = template.subscribe(template_mail => {
        const response = this._email.sendMail(
          email_message,
          properties,
          template_mail
        );


        this.sendMailSubscription = response.subscribe(mail_result => {
          resolve.next(mail_result);
          resolve.complete();
        }, error => {

          resolve.next(false);
          resolve.complete();

        });
      }, error => {

        resolve.next(false);
        resolve.complete();

      });


    });

  }




  sendMailTemp(emailTo, data) {


    return new Observable(resolve => {
      const properties: object = {};

      const email_message = {
        identifier: 1,
        dateOfThing: {},
        status: null,
        sender: null,
        text: null,
        type: 'text/html',
        subjectOf: 'Registro Nuevo :' + (data.person.givenName + ` ` + data.person.familyName) + " / " + (data.person.email),
        toRecipient: emailTo
      };

      let html = `
      <div>
      USUARIO : ` + data.identifier + `<br>
      NOMBRE : ` + (data.person.givenName + ` ` + data.person.givenName) + `<br>
      TELEFONO : ` + (data.person.telephone) + `<br>
      CORREO : ` + (data.person.email) + `
      </div>`;

      const response = this._email.sendMail(
        email_message,
        {},
        html

      );


      this.sendMailSubscription = response.subscribe(mail_result => {
        resolve.next(mail_result);
        resolve.complete();
      }, error => {

        resolve.next(false);
        resolve.complete();

      });




    });

  }




  deleteSubscription() {
    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }

    if (this.subcription_events) {
      this.subcription_events.unsubscribe();
    }

    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }

    if (this.sendMailSubscription) {
      this.sendMailSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
  }

}
