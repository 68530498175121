<div class="container  px-0">
    <div class="mb-0 row box  box-section skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">
            <ng-container *ngIf="itemList">
                <div *ngIf="!((itemList && itemList.length===0) || (!transactionData?.organization && !transactionData?.person))">
                    <div class="text-dark ak-title-table mt-2 mb-4 ">
                        <span [innerHTML]="'Dirección de domicilio, oficinas y/o sucursales del negocio' | i18n:lang "></span>
                    </div>

                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>


                <!-- <div class="text-dark ak-title-table mt-2 mb-4 ">
                        <span [innerHTML]="'@i18n-register-text-standard-table' | i18n:lang "></span>
                        <span [innerHTML]="'@i18n-quote-fiscal-directions-section.title-plural' | i18n:lang "></span>
                    </div>

                    <div class="col-12">
                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30  order-1 ml-4 ml-md-0  ">
                            <div class="font-weight-bold text-capitalize">
                                Dirección
                            </div>
                        </div>

                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  order-2 ml-4 ml-md-0 ">
                            <div class="font-weight-bold text-capitalize">
                                País
                            </div>
                        </div>
                        <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-30  order-3   ml-4 ml-md-0 ">
                            <div class="font-weight-bold text-capitalize">
                                Tipo
                            </div>
                        </div>
                    </div> -->
            </ng-container>


            <div class="text-dark mt-2 mb-4 ak-table-0-comment "  *ngIf="(itemList && itemList.length===0) || (!transactionData?.organization && !transactionData?.person)">
                    <div class="text-dark mt-2 mb-4">
                            <i class="text-secondary fas fa-map-marker-alt mr-3"></i>      
                                           <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                    <span [innerHTML]="'@i18n-quote-fiscal-directions-section.title-plural' | i18n:lang "></span>
                </div>
            </div>


        </div>

        <ng-container *ngIf="!itemList && (transactionData?.organization || transactionData?.person)">
            <div class="row">
                <div class="w-50">
                    <img class="w-75 float-left" src="./assets/public/images/loading.gif" alt="">
                </div>
            </div>
        </ng-container>
    </div>
</div>
