import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { PropertiesService } from '../../../services/index';
import { AuthService} from "@akeela/auth";
import { AkeelaSidebarMenuService } from '@akeela/menu';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { WorkflowService } from 'src/app/services/workflow.service';
import { HttpService } from '@akeela/properties';
import { I18nPipe } from '@akeela/i18n';
import { UploadService } from '@akeela/common';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navbarauth',
  templateUrl: './navbarauth.component.html',
  styleUrls: ['./navbarauth.component.css']
})



export class NavbarauthComponent implements OnInit {
    showSidebarLeft:boolean;
    env = environment;
    showIdentityVerification;

    dataAndSelfi;
    filePreview;
    showBackDashboard ;
    verifiedIdentification;
    compleatVerifi;

  constructor(  
    private router: Router,
    public  config: NgbDropdownConfig,
    public _auth:AuthService,public _properties: PropertiesService,      public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _wfService: WorkflowService,

    private _httpService: HttpService,
    private i18n: I18nPipe,
    private _upload: UploadService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer

    ) { 

    
    config.placement = 'top-left';
    config.autoClose = true;
    
  }

  logout(){

    this._auth.logout();

  }

  setShowChangePassword(param){
    this._auth.setShowChangePassword(param);
  
  }

  
  ngOnInit() {
    
    this._wfService.showIdentityVerification$.subscribe((data: any) => {

      this.showIdentityVerification = data;
              });

              this._wfService.showBackDashboard$.subscribe((data: any) => {

                this.showBackDashboard = data;
                       
              });

    

    this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
                        
      this.showSidebarLeft = data;

  });

  this.getUser();
  this.get_dataAndSelfi();
  }

  toggleMenu(){

    this._akeelaSidebarMenu.setshowSidebarLeft(!this.showSidebarLeft);  

  }
  
  redirectHome(){
    this.router.navigate(['/'], {});
  }

  redirectDashboard(){
    this.router.navigate(['/dashboard'], {});
  }

  



get_dataAndSelfi(){

  const params = {
    name: 'person$selfie$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };



this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

  this.dataAndSelfi = response;
  this.filePreview = 'data:image/jpg' + ';base64,' + response?.description;

  
});

}



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


getUser() {
  const params = {
    name: 'person$enduser$get',
    params: {
      request: {
        'username': this._auth.getUsername()
      },
      path: {},
      body: {}
    }
  };

  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    this.compleatVerifi = response ?.person ?.completeIdentification ;
    this.verifiedIdentification = response ?.person ?.verifiedIdentification ;

    this._wfService.setGetIdentityVerification(this.verifiedIdentification);
    this._wfService.setGetIdentityCompleat( this.compleatVerifi);



  });

}

setIdentity(){

  if(!this.compleatVerifi){

    this._wfService.setshowIdentityVerification(true);
    this.router.navigate(['private/ACCOUNT_OPENING/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_INTRO'], {
      queryParams: {
        role: 'CLIENT',
        profile: 'CLIENT',
        audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL'
      }
    });

  }

}

}


