import { Component, OnInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { isNullOrUndefined } from 'util';
import { 
  AkeelaWorkflowService, 
  AkeelaStageService, 
  AkeelaStepService, 
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-section-board-of-directors',
  templateUrl: './section-board-of-directors.component.html',
  styleUrls: ['./section-board-of-directors.component.css']
})
export class SectionBoardOfDirectorComponent implements OnInit {
  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;


  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe
  ) { }

  ngOnInit() {
    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.


 
    this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
     });

    this.akeelaWorkflowService.worflowEvents$.next(null);

    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    });

    this.transactionDataSubscription = this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
      this.transactionData = transactionData;
      if (!isNullOrUndefined(this.transactionData) && 
        (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.person))) {
        this.getItemList();
      }
    });

    this.paramsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response && (response.action === 'account$related_person_bod$post' || response.action === 'account$related_person_pep_bod$post')) {
        this.getItemList();
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_board_of_directors') {
        //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua

        const markYes = response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
         
        if (markYes && !this.haveAtLeastOnePep()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-have-to-add-pep'),
          });
        } else {
          this.sendEventToContinue(response.response);
        }
      } else if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_delete_all_board_of_directors_pep') {
        //Si marco SI, entonces valida sino o si tiene al menos un PEP, entonces continua
        const radioButtonNo =  response.response.forms.controls.person.controls.isRelatedToPep ? response.response.forms.controls.person.controls.isRelatedToPep.value:null;
        if (radioButtonNo && this.haveAtLeastOnePep()) {
          //Ha marcado NO y tiene PEP, Preguntar si quiere eliminar todos los PEP de REP LEG
          swal.fire({
            title: this.i18n.transform('@i18n-confirmation-are-you-shure'),
            text: this.i18n.transform('@i18n-confirmation-are-you-shure-delete-pep'),
            showCancelButton: true,
            confirmButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-true'),
            cancelButtonText: this.i18n.transform('@i18n-quote-option-list-boolean-option-false'),
            reverseButtons: true
          }).then((result) => {
            if (!isNullOrUndefined(result.value) && result.value) {
              //Si el usuario responde que si a la pregunta... Elimina sus PEP
              this.akeelaWorkflowService.worflowEvents$.next({
                response: 'BOARD_OF_DIRECTORS',
                action: 'account_opening$related_person_all_pep_bod$delete'
              });
            } else {
              //Asigno nuevo valor cuando el usuario CANCELE la operacion
              response.response.forms.controls.person.controls.isRelatedToPep.patchValue(true);
            }
          });
        }
      }
    });

  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      //Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      //Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_board_of_directors') === 0) {
        return false;
      }
    }
    return true;
  }

  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.transactionDataSubscription) {
        this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
        this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {
    const params = {
      name: 'account$related_persons$get',
      params: {
        request: {},
        path: {
          typeRelatedPerson: 'BOARD_OF_DIRECTORS',
          personId: !isNullOrUndefined(this.transactionData.organization) ? this.transactionData.organization.identifier : this.transactionData.person.identifier
        },
        body: {}
      }
    };

    this.requestSubscription = this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;
      if(this.itemList.length === 0){
        this.akeelaWorkflowService.setResetEstatus(true);
      }
      this.updateFather();
    });
  }

  updateFather(){
    const self = this;
    const modalFather = self.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather)){
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)){
        self.akeelaWorkflowService.worflowEvents$.next({
            response: fatherToUpdate,
            action: 'update_board_of_directors_pep'
        });
      }
    }
  }

  openItem(row) {
    this.akeelaSectionService.addModalSection({ name: 'BOARD_DIRECTORS_MODAL' });
    this.akeelaSectionService.showModalSection('BOARD_DIRECTORS_MODAL');
    this.akeelaSectionService.setModalFormValue('BOARD_DIRECTORS_MODAL', row);
  }

  confirmDelete(row: any){
    swal.fire({
      title: this.i18n.transform('@i18n-popup-text-delete-question'),
      text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (!isNullOrUndefined(result.value) && result.value) {
        this.deleteItem(row);
      }
    });
  }

  deleteItem(row: any) {
    const relatedPersonId = !isNullOrUndefined(row.organization) ? row.organization.identifier : row.person.identifier;
    const configuration = {
      name: 'account$related_persons$delete',
      params: {
        path: {
          relatedPersonId: relatedPersonId,
          transactionNumber: this.transactionNumber,
          workflow: this.workflow,
          stage: this.stage,
          step: this.step
        },
        request: {
          audience: this.queryParams.audience,
          role: this.queryParams.role
        }
      }
    };

    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
      swal.fire({
        title: this.i18n.transform('Éxito'),
        html: this.i18n.transform('@i18n-text-information-deleted')
      });
      this.updateList('account$related_person_bod$post');
      this.akeelaWorkflowService.setResetEstatus(true);

    }, error => {
      //dfutrille esto debe borrarse.
      this.updateList('account$related_person_bod$post');
    });
  }

  updateList(action: string){
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {},
      action: action
    });
  }


}
