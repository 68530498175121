import { Component, OnInit, OnDestroy, DoCheck, AfterViewInit } from '@angular/core';
import { AkeelaSectionService, AkeelaStepService, AkeelaWorkflowService, AkeelaStageService } from '@akeela/workflow';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { AuthService } from '@akeela/auth';
import { PathService, HttpService } from '@akeela/properties';
import { environment } from '../../../../../environments/environment';
import { isNullOrUndefined } from 'util';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { Subscription } from 'rxjs';
import { TransitionService } from '../../../../services/transition.service';
import { MiddlewareService } from '../../../../services/middleware.service';
import { WorkflowService } from '../../../../services/workflow.service';
import { LocalService } from '@akeela/local-storage';
import { TemplateService } from '../../../../services/template.service';


@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class WorkflowComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

  breadcrumb;
  borderBox: Boolean = false;
  showSubHeaderStep;
  showWorkflow;
  showSubHeaderStepSubscription: Subscription;
  transitionSubscription: Subscription;
  breadcrumbSubscription: Subscription;
  workflowSubscription: Subscription;
  stageSubscription: Subscription;
  queryParamsSubscription: Subscription;
  stepServiceSubscriptor: Subscription;
  breadcrumbServiceSubscriptor: Subscription;
  env = environment;

  isAuth = false;
  link = null;

  title = '';
  subtitle = '';

  showWindow: Boolean = true;
  queryParams;
  transitionShowWorkflowSubscription: Subscription;
  transitionShowHeaderStageSubscription: Subscription;
  transitionShowHeaderStepSubscription: Subscription;
  stepServiceBreadcrumbStepSubscription: Subscription;
  showSidebarLeft: boolean;
  showChangePass;
  sizeMenuLeft;
  dataWorkflowCode_subscriber: Subscription;
  wfSubscription: Subscription;
  currentWorkflowDefault;
  currentWorkflowChange = [];
  clientinfo = {};
  requirements = {};
  transactionData = null;

  resumen_requirements = null;
  templates: any = {};
  paramsSubscription: Subscription;




  constructor(
    private akeelaSectionService: AkeelaSectionService,
    private route: ActivatedRoute,
    private router: Router,
    private stepService: AkeelaStepService,
    private _auth: AuthService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,
    private _pathService: PathService,
    private workflowService: AkeelaWorkflowService,
    private akStepService: AkeelaStepService,
    private akStageService: AkeelaStageService,
    private _transition: TransitionService,
    private _breadcrumbService: AkeelaBreadcrumbService,
    private _middleware: MiddlewareService,
    private _wfService: WorkflowService,
    private _httpService: HttpService,
    private localStorage: LocalService,
    private templateService: TemplateService
  ) {
  }

  public ngDoCheck() {
    if (this.showSidebarLeft && this._auth.isAuthenticated()) {

      this.sizeMenuLeft = true;

    } else {

      this.sizeMenuLeft = false;
    }
  }

  setShowChangePassword(param) {
    this._auth.setShowChangePassword(param);

  }

  ngOnInit() {
    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }
    this.wfSubscription = this.workflowService.currentWorkflow$.subscribe(workflowCode => {
      this.getDataWorkflowCode(workflowCode);



    });

    this.workflowService.worflowEvents$.subscribe((event: any) => {

//debugger;
      if (!isNullOrUndefined(event)) {
        if (event && event.action === 'showLoadingSuppliers') {

        } else if (event && event.action === 'openModal') {
          if (event && event.value === 'SHAREHOLDERS_MODAL') {
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_LEGAL_FIELDS', 'hidden': true });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_NATURAL_FIELDS', 'hidden': false });
            this.workflowService.hiddenSection$.next({ 'code': 'SHAREHOLDERS_MODAL_PEP', 'hidden': false });
          }
        }
      }


    });

    this._auth.showChangePassword$.subscribe((data: boolean) => {

      this.showChangePass = data;
    });

    this._akeelaSidebarMenu.showSidebarLeft.subscribe((data: boolean) => {
      this.showSidebarLeft = data;
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });

    this._auth.isAuth$.subscribe((isAuth: boolean) => {
      this.isAuth = isAuth;
    });


    this.showWorkflow = false;

    this.showSubHeaderStepSubscription = this.akStepService.showSubHeaderStep$.subscribe(data => {
      this.showSubHeaderStep = data;
      this._transition.showWorkflow$.next(true);
    });


    this.transitionShowWorkflowSubscription = this._transition.showWorkflow$.subscribe((showWorkflow) => {
      this.showWorkflow = showWorkflow;
    });

    this.workflowSubscription = this._wfService.showContainer$.subscribe((response) => {
      this.showWindow = response;
    });

    this.workflowService.transactionData$.subscribe((response: any) => {
      if (!isNullOrUndefined(response)) {
        this.transactionData = response;
      }
    });

    this.workflowService.currentWorkflow$.subscribe((resp) => {
      if (!isNullOrUndefined(resp)) {
        if (resp === 'DOC_SUPPORT') {
          this.resumen_requirements = false;
        } else {
          this.resumen_requirements = false;
        }
      }
    });

    this.stageSubscription = this.akStageService.showHeaderStage$.subscribe(data => {

      const stage = this.akStageService.currentStage$.getValue();

      if ((stage === 'DOC_SUPPORT' || stage === 'SUMMARY_ACCOUNT_OPENING' || stage === 'PRODUCT_INFORMATION' || stage === 'CLIENT_IDENTIFICATION' || stage === 'ACCOUNT_CONTRACT')) {

        this.link = true;

      } else {

        this.link = false;

      }


      this.clientinfo = {};
      this.clientinfo['name'] = 'clientinfo';
      this.clientinfo['description'] = '@i18n-workflow-name-accountopening';

      if (this.workflowService.currentWorkflow$.getValue() === 'ACCOUNT_OPENING') {

        this.currentWorkflowDefault = 'ACCOUNT_OPENING';

      }

      this.setCurrentWorkflowChange();

      this._middleware.isModuleRequirementsActive().then((response) => {
        if (response === true) {
          if (this.workflowService.currentWorkflow$.getValue() === 'DOC_SUPPORT') {
            this.currentWorkflowDefault = 'REQUIREMENT';
          }
          this.requirements['name'] = 'requirements';
          this.requirements['description'] = '@i18n-workflow-name-docsupport';
        }
        this.setCurrentWorkflowChange();
      });

      if (data === true) {

        this.borderBox = true;

      } else {

        this.borderBox = false;

      }

      this.akStepService.showHeaderStep$.subscribe(data2 => {


        if (data2 || data) {

          this.borderBox = true;

        } else {

          this.borderBox = false;

        }

      });

    });

    this.processBreacrum();
    if (this.localStorage.getItem('showSidebarLeft') === null) {
      if (screen.width > 768) {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      }
    } else {

      if (this.localStorage.getItem('showSidebarLeft') === 'true') {
        this._akeelaSidebarMenu.setshowSidebarLeft(true);
      } else {
        this._akeelaSidebarMenu.setshowSidebarLeft(false);
      }
    }



  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.templates = this.templateService.getTemplates();
    }, 1);
  }

  setBreacrumb(breadcrumbKey) {
    this._breadcrumbService.setbreadcrumbKey(breadcrumbKey);
  }

  processBreacrum() {
    const breadcrumb = null;
    this.stepServiceBreadcrumbStepSubscription = this.stepService.breadcrumbStep$.subscribe(breadcrumbKey => {

      if (breadcrumbKey) {
        this.setBreacrumb(breadcrumbKey);
      } else {
        this._breadcrumbService.setbreadcrumbOptions(null);
      }
    });

  }



  getDataWorkflowCode(workflow) {
    if (workflow) {
      this.subtitle = '';

      const param = {
        name: 'workflow$workflow$get',
        params: {
          request: { content: 'none' },
          path: { workflow: workflow },
          body: {}
        }
      };

      this.dataWorkflowCode_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

        this.subtitle = response.name;

      });
    }
  }


  deleteSubscription() {
    if (this.showSubHeaderStepSubscription) {
      this.showSubHeaderStepSubscription.unsubscribe();
    }

    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }

    if (this.transitionShowWorkflowSubscription) {
      this.transitionShowWorkflowSubscription.unsubscribe();
    }

    if (this.transitionShowHeaderStageSubscription) {
      this.transitionShowHeaderStageSubscription.unsubscribe();
    }

    if (this.transitionShowHeaderStepSubscription) {
      this.transitionShowHeaderStepSubscription.unsubscribe();
    }

    if (this.stepServiceBreadcrumbStepSubscription) {
      this.stepServiceBreadcrumbStepSubscription.unsubscribe();
    }

    if (this.dataWorkflowCode_subscriber) {
      this.dataWorkflowCode_subscriber.unsubscribe();
    }
    if (this.wfSubscription) {
      this.wfSubscription.unsubscribe();
    }

  }

  ngOnDestroy(): void {
    this.deleteSubscription();
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
    this.akeelaSectionService.background$.next(false);
  }

  get_response_breadcrub(response) {
    console.log(response);
    if (!isNullOrUndefined(response) && response !== '') {
      this.router.navigateByUrl(this.router.createUrlTree
        (['private/' + response],
        {
          queryParams: this.queryParams
        })
      );
    }
    // if (response === 'REQUIREMENT') {
    //   // redirecciona a requirements wf

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.requirementsUpload],
    //     {
    //       queryParams: this.queryParams
    //     }
    //     )
    //   );

    // } else {

    //   this.router.navigateByUrl(this.router.createUrlTree
    //     ([environment.staticLinks.detailClientResumen],
    //     {
    //       queryParams: {}
    //     }
    //     )
    //   );

    // }
  }


  setCurrentWorkflowChange() {
    this.currentWorkflowChange = [];
    if (!(Object.keys(this.clientinfo).length === 0)) {
      this.currentWorkflowChange.push(this.clientinfo);
    }
    if (!(Object.keys(this.requirements).length === 0)) {
      this.currentWorkflowChange.push(this.requirements);
    }



  }

  eventBreadcrumb(response) {
    if (response.action === 'navigate') {
      if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
        //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
        this.breakfreeTransactionsByUser();
      } else {
        this.breakfreeTransactionsByUser();
      }
    }
  }


  breakfreeTransactionsByUser() {
    //alert('voy a ejecuctar 3');

    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this._auth.getPersonIdentifier()

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    });
  }

  breakfreeTransactionEdit() {
    //alert('voy a ejecuctar 333333');

    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this._auth.getPersonIdentifier()

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
      this.workflowService.transactionData$.next(null);

    });
  }


}
