<div class="container  px-0">
    <div class="mb-0 row box skin-white  animate__animated animate__fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">
            <ng-container *ngIf="itemList">

                    <div *ngIf="(itemList && itemList.length>0) && transactionData?.organization">
                        <div class="text-dark ak-title-table mt-2 mb-2">

                                <span *ngIf="!transactionData.person" class="pr-2 fa fa-building ak-text-color-1" style="font-size: 22px;">  </span>

                            <span [innerHTML]=" transactionData?.holder?.name?transactionData?.holder?.name:'-' | i18n:lang "></span>
                        </div> 
                        <div class="pb-4 ">
                                Identifique a todos los accionistas con participación mayor a 1%. <br> En caso que algún accionista sea una empresa con participación mayor a 1%, usted deberá identificar la participación accionaria de esta.
                        </div>
                            <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                            </akeela-table>
                        </div>

            </ng-container>

            <div  class="text-secondary mb-2 mt-3 " *ngIf="itemList  && itemList.length>0">Total participación accionaria identificada <span class="font-weight-bold"> {{this.sumSharePercentage}}% de 100%</span>
            </div>

            <div class="w-100 ak-table-0-comment" *ngIf="(itemList && itemList.length===0) || !transactionData?.organization">
                    <div class="text-dark mt-2 mb-4">
                            <i class="text-secondary fas fa-sitemap mr-3"></i>                         <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang"></span>
                        <span [innerHTML]="'@i18n-quote-shareholders-shareholdersTable-section.title' | i18n:lang "></span>
                    </div>
            </div>

            <ng-container *ngIf="!itemList && transactionData?.organization">
                <div>
                    <img  src="/assets/public/images/loading.gif" alt="">
                </div>
            </ng-container>
        </div>
    </div>
</div>

