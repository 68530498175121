<akeela-meet-detail [selected]="init_meet" [username]="user_meet" (change)="setMeetData($event)">
</akeela-meet-detail>

<div class="container mb-4">
    <div class=" mt-2 pb-3">
        <div class="w-100 animate__animated animate__fadeInDown ">
            <ul class="scrollmenu pl-0 pt-2 ">


                <li class="d-inline  mt-2 active-cursor" (click)="getMeet()">
                    <div class="item-rd text-secondary fz-14 ">
                        <i class="fas fa-calendar pr-2"></i> Reuniones
                        <div *ngIf="interviewsTimeProgram" class="fas fa-circle text-danger blink_me_circle noti" aria-hidden="true"></div>
                    </div>
                </li>

                <li class="d-inline  mt-2 active-cursor" (click)="redirectTransactions()">
                    <div class="item-rd text-secondary fz-14 ">
                        <i class="fas fa-plus pr-2"></i> Solicitudes
                        <div *ngIf="transaction" class="fas fa-circle text-danger blink_me_circle noti" aria-hidden="true"></div>

                    </div>
                </li>
                <li class="d-inline  mt-2 active-cursor">
                    <div class="item-rd text-secondary fz-14 ">
                        <i class="fas fa-users pr-2"></i> Conecta
                    </div>
                </li>
                <li class="d-inline  mt-2 active-cursor">

                    <div class="item-rd text-secondary fz-14 ">
                        <i class="fab fa-product-hunt pr-2"></i>
                        <span>Productos</span>

                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!--
    <div class=" mt-5">

        <div class="w-100 text-center">
            <h3 class="color-title font-weight-normal pb-3 animate__animated animate__fadeIn">
                <div class="pl-2 d-inline-flex text-capitalize"> Hola {{usuario?.firstName | lowercase}}, <br> Permítenos ayudarte </div>
            </h3>
        </div>


    </div>
 -->

    <div class="alert alert-secondary  mb-4 animate__animated animate__fadeIn" role="alert" *ngIf="!identityCompleat && identityCompleat != null">

        <div class="d-flex fz-12">
            <div class="pr-3 ">
                <i class="mt-2 fas fa-fingerprint  text-secondary fz-16  "></i>
            </div>
            <div>
                <div class="font-weight-bold"> Verificación Intermedia </div>
                Complete su verificación
            </div>
            <div class="ml-auto text-right pt-2 fz-16">
                <a class=" " (click)="setIdentity()" href="javascript:void(0)">Ir</a>
            </div>
        </div>

    </div>

    <div class="alert alert-secondary  mb-4 animate__animated animate__fadeIn" role="alert" *ngIf="rowInterviews">
        <div class="d-flex fz-12">
            <div class="pr-3 ">
                <i class="fas  text-secondary fz-16 " [ngClass]="{
                    'fa-video':(interviewType[rowInterviews?.type])['label']  == '@i18n-quote-option-list-interviewType-option3',
                    'fa-phone-alt':(interviewType[rowInterviews?.type])['label']  == '@i18n-quote-option-list-interviewType-option2',
                    'fa-user':(interviewType[rowInterviews?.type])['label'] == '@i18n-quote-option-list-interviewType-option1'}"></i>
            </div>
            <div>
                <div class="font-weight-bold"> Próxima reunion programada </div>
                <div class="mb-2  pt-0 text-danger bg-light ">
                    <div class="pr-2 text-dark ">{{toDateString(rowInterviews?.interviewDate)}}</div>
                    <div class="fz-12 " (click)="getMeet()">
                        Faltan
                        <span class="pr-2" *ngIf="interviewsTime[interviewsTimeProgram]['day'] > 0"><span>{{ interviewsTime[interviewsTimeProgram]['day']}}</span>Dia(s)</span>
                        <span class="pr-2" *ngIf="interviewsTime[interviewsTimeProgram]['day'] < 1 && interviewsTime[interviewsTimeProgram]['hours'] > 0"><span   >{{ interviewsTime[interviewsTimeProgram]['hours'] }}</span>Hora(s)</span>
                        <span class="pr-2" *ngIf="interviewsTime[interviewsTimeProgram]['day'] < 1 && interviewsTime[interviewsTimeProgram]['minutes'] > 0"><span >{{interviewsTime[interviewsTimeProgram]['minutes'] }}</span>Min</span>
                        <span class="pr-2 " *ngIf="interviewsTime[interviewsTimeProgram]['day'] < 1 && interviewsTime[interviewsTimeProgram]['hours'] < 1 &&  interviewsTime[interviewsTimeProgram]['seconds'] > 0"><span   >{{interviewsTime[interviewsTimeProgram]['seconds'] }}</span>Seg</span>
                        <span class="far fa-clock blink-me rounded "></span>
                    </div>
                </div>


            </div>
            <div class="ml-auto text-right pt-2">
                <a class=" " (click)="getMeet()" href="javascript:void(0)">Ir</a>
            </div>
        </div>

    </div>



    <div *ngIf="transaction" class="fz-12 alert alert-secondary mt-4 mb-3 animate__animated animate__fadeIn" role="alert">

        <div class="d-flex ">
            <div class="pr-3 ">
                <i class="mt-2 fab fa-product-hunt   text-secondary fz-16  "></i>
            </div>
            <div>
                <div> <span class="font-weight-bold">{{transaction?.plan?.name}}</span> / {{transaction?.transactionNumber}}</div>
                <div class="text-secondary">{{ transaction?.accountStatus[0]?.clientName}}</div>
            </div>

            <div class="ml-auto text-right pt-1 ">
                <div (click)="redirectInbox('draft', 'DRAFT')" class="item-rd2 text-secondary fz-14 ">
                    Continuar
                </div>
            </div>

        </div>
        <div class="mt-2" style="font-size: 14px;">
            {{ transaction?.accountStatus[0]?.clientDescription}}
        </div>

        <div>
        </div>


    </div>



    <div *ngIf="transactions &&  transactions?.length < 1 " class="fz-12 alert alert-secondary mt-4 mb-3 animate__animated animate__fadeIn" role="alert">

        <div class="d-flex ">
            <div class="pr-3 ">
                <i class="mt-2 fab fa-product-hunt   text-secondary fz-16 "></i>
            </div>
            <div>
                <div> <span class="font-weight-bold"> Solicita un nuevo producto </span></div>
                <div class="text-secondary">Atrevete, y solicita tu primer prducto en <b>{{env?.theme?.site?.name}}</b></div>
            </div>


            <div class="ml-auto text-right pt-1 ">
                <div (click)="redirectInbox('inbox', 'Home')" class="item-rd2 text-secondary fz-14 ">
                    Ir
                </div>
            </div>

        </div>


        <div>
        </div>


    </div>




    <div class="pt-3 mt-5">

        <div class="w-100 text-dark">
            <div class="color-title font-weight-bold animate__animated animate__fadeIn">
                <div class="pl-2 d-inline-flex "> A continuación, agregue los detalles de su negocio
                </div>
            </div>
        </div>


    </div>

    <div class="w-100 pt-1 text-dark">
        <div class=" color-title font-weight-normal pb-3 animate__animated animate__fadeIn">
            <div class="pl-2 d-inline-flex "> Cuéntanos un poco más sobre tu negocio para activar tu cuenta.
            </div>
        </div>
    </div>

    <div class=" mt-2">
        <button type="button" class=" btn btn-primary">
           <span [innerHTML]="'Continuar >' | i18n"></span>
        </button>
    </div>

</div>