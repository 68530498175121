<div class="text-center row">

    <div class="col-12 col-md-10 offset-md-1">
        <div class="text-left">
            <span class="pt-2 pb-2 font-weight-light">Verifícate</span>
            <div class="line">
                <div class="line1 bg-primary font-weight-light"></div>
            </div>
        </div>
        <div class="mt-4 mb-4 ">

            <h5 class="font-weight-light mb-3">Hola, {{usuario?.firstName | titlecase}}</h5>
            <h5 class="font-weight-bold mb-4">Verificación de identidad</h5>


            <i class="far fa-id-card text-primary " style=" font-size: 75px;"></i>
            <div class="font-weight-light text-dark mt-4 mb-4">
                Vamos a comenzar con el proceso de verificación de identidad.
            </div>

            <div class="font-weight-light text-dark mb-2">
                Te solicitaremos:
            </div>

            <div class=" font-weight-bold text-dark">
                <div>Información Personal</div>
                <div>Documento de Identidad emitido por el Gobierno</div>
                <div> Selfie </div>
                <div>Selfie + Documento de Identidad</div>


            </div>


            <div class="mt-4 mb-5 row">
                <div class="col-12 col-md-4 offset-md-4">
                    <button (click)="redirect()" class="btn btn-outline-primary btn-block">Ok</button>
                </div>

            </div>
        </div>

    </div>
</div>