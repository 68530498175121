import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from "@angular/core";

//MODULES...
import { I18nModule } from "@akeela/i18n";
import { SharedModule } from "../../shared/shared.module";
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";

//COMPONENTS
import { ResumenComponent } from './components/resumen/resumen.component';

import { WorkflowComponent } from './components/workflow/workflow.component';
import { EmbedComponentsComponent } from './components/workflow/embed-components/embed-components.component';
import { PaperworkComponent } from './components/paperwork/paperwork.component';
import { SectionLegalRepresentativeComponent } from './components/section-legal-representative/section-legal-representative.component';
import { SectionBoardOfDirectorComponent } from './components/section-board-of-directors/section-board-of-directors.component';
import { SectionBankAccountInstitutionComponent } from './components/section-bank-account-institution/section-bank-account-institution.component';
import { SectionBankAccountOtherInstitutionComponent } from './components/section-bank-account-other-institution/section-bank-account-other-institution.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SectionShareholdersComponent } from './components/section-shareholders/section-shareholders.component';
import { SectionSuppliersComponent } from './components/section-suppliers/section-suppliers.component';
import { SectionCustomersComponent } from './components/section-customers/section-customers.component';
import { SectionRelatedCompanyComponent } from './components/section-related-company/section-related-company.component';
import { SectionPepComponent } from './components/section-pep/section-pep.component';

import { SectionPepShareHoldersComponent } from './components/section-pep-shareholders/section-pep-shareholders.component';
import { SectionPepBoardOfDirectorsComponent } from './components/section-pep-boardofdirectors/section-pep-boardofdirectors.component';
import { ConstitutionDataComponent } from './components/section-constitution-data/section-constitution-data.component';
import { SwornComponent } from './components/sworn/sworn.component';
import { PayrollComponent } from './components/payroll/payroll.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SectionCommercialReferencesComponent } from './components/section-commercial-references/section-commercial-references.component';
import { SectionShareholdersRelatedsComponent } from './components/section-shareholders-relateds/section-shareholders-relateds.component';
import { RelatedDocumentsGenerated } from './components/related-documents-generated/related-documents-generated.component';


/* PN */

import { SectionLegalRepresentativePNComponent } from './components/section-legal-representative-pn/section-legal-representative-pn.component';
import { SectionCustomersPNComponent } from './components/section-customers-pn/section-customers-pn.component';
import { SectionPersonalReferencesPNComponent } from './components/section-personal-references-pn/section-personal-references-pn.component';
import { SectionSuppliersPNComponent } from './components/section-suppliers-pn/section-suppliers-pn.component';
import { SectionPepPNComponent } from './components/section-pep-pn/section-pep-pn.component';
import { SectionPepPNAssociationComponent } from './components/section-pep-pn-association/section-pep-pn-association.component';



import { environment } from 'src/environments/environment';
import { SectionFiscalDirectionsComponent } from './components/section-fiscal-directions/section-fiscal-directions.component';
import { GuideComponent } from './components/resumen/guide/guide.component';
import { IdentityVerificationStartComponent } from './components/identity-verification-start/identity-verification-start.component';
import { IdentityVerificationPhotosComponent } from './components/identity-verification-photos/identity-verification-photos.component';
import { IdentityVerificationEndComponent } from './components/identity-verification-end/identity-verification-end.component';
import { RequirementsCameraComponent } from './components/requirements-camera/requirements-camera.component';
import { AkeelaDashboardComponent } from './components/akeela-dashboard/akeela-dashboard.component';
import { AkeelaMeetDetailComponent } from './components/akeela-meet-detail/akeela-meet-detail.component';


@NgModule({
    imports: [
        CommonModule,
        I18nModule.config(environment.i18nConfig),
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
       
    ],
    providers: [],
    declarations: [
        ResumenComponent,
        WorkflowComponent,
        EmbedComponentsComponent,
        PaperworkComponent,
        BreadcrumbComponent,
        SectionLegalRepresentativeComponent,
        SectionLegalRepresentativePNComponent,
        SectionBoardOfDirectorComponent,
        SectionBankAccountInstitutionComponent,
        SectionBankAccountOtherInstitutionComponent,
        SectionShareholdersComponent,
        SectionShareholdersRelatedsComponent,
        SectionSuppliersComponent,
        SectionCustomersComponent,
        SectionRelatedCompanyComponent,
        ConstitutionDataComponent,
        SwornComponent,
        PayrollComponent,
        SectionPepComponent,
        SectionPepPNComponent,
        SectionPepPNAssociationComponent,
        SectionPepShareHoldersComponent ,
        SectionPepBoardOfDirectorsComponent,
        RequirementsComponent,
        ChangePasswordComponent,
        SectionCommercialReferencesComponent,
        SectionCustomersPNComponent,
        SectionPersonalReferencesPNComponent,
        SectionSuppliersPNComponent,
        SectionFiscalDirectionsComponent,
        RelatedDocumentsGenerated,
        GuideComponent,
        IdentityVerificationStartComponent,
        IdentityVerificationPhotosComponent,
        IdentityVerificationEndComponent,
        RequirementsCameraComponent,
        AkeelaDashboardComponent,
        AkeelaMeetDetailComponent
        ],
    exports: [
        ResumenComponent,
        WorkflowComponent,
        EmbedComponentsComponent,
        ChangePasswordComponent
    ],
})
export class PrivateModule {

    constructor() {
        I18nModule.config(environment.i18nConfig);
      }
 }