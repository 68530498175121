import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RequirementsService } from '@akeela/requirements';
import { PropertiesService } from '../../../../services/properties/properties.service';
import { AuthService } from '@akeela/auth';
import { UploadService, ErrorService } from '@akeela/common';
import { HttpService } from '@akeela/properties';
import { environment } from '../../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';
import { ActivatedRoute, Router } from '@angular/router';
// import { FaceCompareService } from '@akeela/ngx-face-api-js';
import { ModelLoaderService, ModelToken, FeatureToken } from '@akeela/ngx-face-api-js';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-requirements-camera',
  templateUrl: './requirements-camera.component.html',
  styleUrls: ['./requirements-camera.component.css']
})
export class RequirementsCameraComponent implements OnInit, AfterViewInit {
  config = null;
  extensions;
  mb_max_filesize: any = 10;
  env: any;
  client;



  constructor(
    private _properties: PropertiesService,
    private _requirementsService: RequirementsService,
    public _auth: AuthService,
    private _upload: UploadService,
    private localStorage: LocalService,
    private route: ActivatedRoute,
    private router: Router,
    private _modelLoadFaceApiService: ModelLoaderService,
    private _httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.env = environment;
    this.loadModel();
    this.setConfigRequirements();
  }

  setConfigRequirements = async () => {
    this.extensions = '.PNG .JPG .JPEG';

    const config_requirements = {
      "RecaudosCliente": [
        {
          "identifier": null,
          "status": "A",
          "dateOfThing": {
            "startDate": 1631198825659
          },
          "transaction": {
            "identifier": "null"
          },
          "requirement": {
            "identifier": null,
            "description": `
          
            <div class=" w-100 mt-3 mb-2">

            Formato vertical, cara bien iluminada, en lo posible, hacerlo con un fondo blanco

            </div>`,
            "name": "Selfie",
            "alternateName": "SELFIE",
            "status": "A",
            "dateOfThing": {
              "startDate": 1599247714473
            },
            "audience": {
              "audienceType": "NATURAL"
            },
            "required": true,
            "requirementType": "FILE",
            "checkmark": true,
            "requirementCategory": {
              "identifier": "12",
              "name": "Recaudos Principales",
              "alternateName": "<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD</b>\n<ul>\n<li>Recaudos principales del Representante Legal del menor</li>\n<li>Cédula de Identidad vigente del menor (si la posee)</li>\n<li>Partida de nacimiento</li>\n<li>Nombramiento del Tutor o Representante Legal (en caso que el Representante no sea ninguno de los padres)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n<li>Carta de autorización emitida y firmada por ambos padres o Representante Legal autorizando al menor de edad (igual o  mayor de 14 años) a movilizar los fondos</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD EMANCIPADO</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Acta de Matrimonio. (si aplica)</li>\n<li>Constancia de estudio o de trabajo (si aplica)</li>\n<li>Nombramiento del curador (si aplica)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA FIRMAS PERSONALES</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Declaración del ISLR correspondiente al último periodo fiscal</li>\n<li>Dos (2) referencias comerciales con dirección de ubicación y número local, debidamente firmada, con vigencia menor a tres(03) meses o dos (2) personales (en el caso de aquellas que estén iniciando actividad comercial)</li>\n<li>Registro de Firma Personal</li>\n<li>Copia del Registro de Identificación Fiscal (RIF) vigente. (Observar que haga mención a que el Titular posee una Firma Personal)</li>\n</ul>",
              "status": "A",
              "dateOfThing": {
                "startDate": 1586992357658
              }
            },
            "internal": false,
            "listOrder": 2,
            "showCheckList": true,
            "upload": true,
            "showInRequirements": true,
            "consignPhysicalDocument": true,
            "secondRequired": true,
            "showDateOfIssue": true,
            "showExpirationDate": true,
            "mandatoryIssueDate": false,
            "mandatoryExpirationDate": false
          },
          "person": {
            "identifier": "null"
          },
          "expirationDate": {
            "orderDate": 1631851200
          },
          "response": null,
          "responseDate": {
            "orderDate": 1631209839931
          },
          "responseDescription": "BNBN",
          "requirementArchives": [],
          "hasFiles": false,
          "consignedDate": {},
          "markConsignedDate": {},
          "link_youtube": "https://www.youtube.com/embed/oz8iYyRlkiQ"
        },
        {
          "identifier": null,
          "status": "A",
          "dateOfThing": {
            "startDate": 1631198825659
          },
          "transaction": {
            "identifier": "null"
          },
          "requirement": {
            "identifier": null,
            "description": `
          
            <div class=" w-100 mt-3 mb-2">

            Sácate una foto a tu Documento de Identidad, formato vertical, bien iluminado, en lo posible, hacerlo con un fondo blanco

            </div>
                        `,
            "name": "Documento de Identidad emitido por el Gobierno",
            "alternateName": "DOC_IDENTITY",
            "status": "A",
            "dateOfThing": {
              "startDate": 1599247714473
            },
            "audience": {
              "audienceType": "NATURAL"
            },
            "required": true,
            "requirementType": "FILE",
            "checkmark": true,
            "requirementCategory": {
              "identifier": "12",
              "name": "Recaudos Principales",
              "alternateName": "<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD</b>\n<ul>\n<li>Recaudos principales del Representante Legal del menor</li>\n<li>Cédula de Identidad vigente del menor (si la posee)</li>\n<li>Partida de nacimiento</li>\n<li>Nombramiento del Tutor o Representante Legal (en caso que el Representante no sea ninguno de los padres)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n<li>Carta de autorización emitida y firmada por ambos padres o Representante Legal autorizando al menor de edad (igual o  mayor de 14 años) a movilizar los fondos</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD EMANCIPADO</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Acta de Matrimonio. (si aplica)</li>\n<li>Constancia de estudio o de trabajo (si aplica)</li>\n<li>Nombramiento del curador (si aplica)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA FIRMAS PERSONALES</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Declaración del ISLR correspondiente al último periodo fiscal</li>\n<li>Dos (2) referencias comerciales con dirección de ubicación y número local, debidamente firmada, con vigencia menor a tres(03) meses o dos (2) personales (en el caso de aquellas que estén iniciando actividad comercial)</li>\n<li>Registro de Firma Personal</li>\n<li>Copia del Registro de Identificación Fiscal (RIF) vigente. (Observar que haga mención a que el Titular posee una Firma Personal)</li>\n</ul>",
              "status": "A",
              "dateOfThing": {
                "startDate": 1586992357658
              }
            },
            "internal": false,
            "listOrder": 2,
            "showCheckList": true,
            "upload": true,
            "showInRequirements": true,
            "consignPhysicalDocument": true,
            "secondRequired": true,
            "showDateOfIssue": true,
            "showExpirationDate": true,
            "mandatoryIssueDate": false,
            "mandatoryExpirationDate": false
          },
          "person": {
            "identifier": "null"
          },
          "expirationDate": {
            "orderDate": 1631851200
          },
          "response": null,
          "responseDate": {
            "orderDate": 1631209839931
          },
          "responseDescription": "BNBN",
          "requirementArchives": [],
          "hasFiles": false,
          "consignedDate": {},
          "markConsignedDate": {},
          "link_youtube": "https://www.youtube.com/embed/Sm9wqIN9Jt8"
        },
        {
          "identifier": null,
          "status": "A",
          "dateOfThing": {
            "startDate": 1631198825659
          },
          "transaction": {
            "identifier": "null"
          },
          "requirement": {
            "identifier": null,
            "description": `
          
            <div class=" w-100 mt-3 mb-2">

            Sácate una foto con tu Documento de Identidad, formato vertical, cara bien iluminada, en lo posible, hacerlo con un fondo blanco

            </div>
            `,

            "name": "Selfie + Documento de Identidad",
            "alternateName": "SELFIE_DOC_IDENTITY",
            "status": "A",
            "dateOfThing": {
              "startDate": 1599247714473
            },
            "audience": {
              "audienceType": "NATURAL"
            },
            "required": true,
            "requirementType": "FILE",
            "checkmark": true,
            "requirementCategory": {
              "identifier": "12",
              "name": "Recaudos Principales",
              "alternateName": "<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD</b>\n<ul>\n<li>Recaudos principales del Representante Legal del menor</li>\n<li>Cédula de Identidad vigente del menor (si la posee)</li>\n<li>Partida de nacimiento</li>\n<li>Nombramiento del Tutor o Representante Legal (en caso que el Representante no sea ninguno de los padres)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n<li>Carta de autorización emitida y firmada por ambos padres o Representante Legal autorizando al menor de edad (igual o  mayor de 14 años) a movilizar los fondos</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA MENOR DE EDAD EMANCIPADO</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Acta de Matrimonio. (si aplica)</li>\n<li>Constancia de estudio o de trabajo (si aplica)</li>\n<li>Nombramiento del curador (si aplica)</li>\n<li>Instrucción del Tribunal que ordena abrir la cuenta (si aplica)</li>\n</ul>\n<b>DOCUMENTOS ADICIONALES PARA FIRMAS PERSONALES</b>\n<ul>\n<li>Recaudos principales</li>\n<li>Declaración del ISLR correspondiente al último periodo fiscal</li>\n<li>Dos (2) referencias comerciales con dirección de ubicación y número local, debidamente firmada, con vigencia menor a tres(03) meses o dos (2) personales (en el caso de aquellas que estén iniciando actividad comercial)</li>\n<li>Registro de Firma Personal</li>\n<li>Copia del Registro de Identificación Fiscal (RIF) vigente. (Observar que haga mención a que el Titular posee una Firma Personal)</li>\n</ul>",
              "status": "A",
              "dateOfThing": {
                "startDate": 1586992357658
              }
            },
            "internal": false,
            "listOrder": 2,
            "showCheckList": true,
            "upload": true,
            "showInRequirements": true,
            "consignPhysicalDocument": true,
            "secondRequired": true,
            "showDateOfIssue": true,
            "showExpirationDate": true,
            "mandatoryIssueDate": false,
            "mandatoryExpirationDate": false
          },
          "person": {
            "identifier": "null"
          },
          "expirationDate": {
            "orderDate": 1631851200
          },
          "response": null,
          "responseDate": {
            "orderDate": 1631209839931
          },
          "responseDescription": "",
          "requirementArchives": [],
          "hasFiles": false,
          "consignedDate": {},
          "markConsignedDate": {},
          "link_youtube": "https://www.youtube.com/embed/9WE_AgJtllA"

        }
      ]
    };


    let fa_active = this.env ?.facialRecognition ?.active ? this.env ?.facialRecognition ?.active : false;
    const fa_debug = (this.env ?.facialRecognition ?.debug) ? this.env ?.facialRecognition ?.debug : false;
    const fa_draw = (this.env ?.facialRecognition ?.draw) ? this.env ?.facialRecognition ?.draw : false;
    const fa_landmarks = this.env ?.facialRecognition ?.landmarks ? this.env ?.facialRecognition ?.landmarks : [];

    // condición que valida si el cliente tiene activado o desactivado el reconocimiento facial
    this.client = await this.clientInformation();
    fa_active = this.facialRecognition(this.client, fa_active);

    this.config = {
      object: config_requirements,
      category: 'RecaudosCliente',
      isFilterRequirementsByResponse: false,
      isFilterRequirementsByCategory: false,
      viewName: 'UPLOAD_FILES_CAMERA',
      identifierPerson: this.client ?.person ?.identifier,
      maxAttempt: this.env ?.facialRecognition ?.maxAttempt ? this.env ?.facialRecognition ?.maxAttempt : null,
      file: {
        file_type_accepts: this._requirementsService.getPermitedExtensions(this.extensions),
        file_type_accepts_description: this._requirementsService.getPermitedExtensionsDescription(this.extensions),
        mb_max_filesize: this.mb_max_filesize
      },
      facialRecognition: {
        debug: fa_debug,
        active: fa_active,
        config: {
          draw: fa_draw,
          landmarks: fa_landmarks
        },
        rules: [
          {
            name: 'SELFIE',
            maxPersons: 1,
            compare: false
          },
          {
            name: 'SELFIE_DOC_IDENTITY',
            maxPersons: 2,
            compare: true
          },
          {
            name: 'DOC_IDENTITY',
            maxPersons: 1,
            compare: false
          }
        ]
      },
      endpoints: {
        uploadRequirement: {
          identifier: '',
          name: 'requirements$transaction_requirement$get',
          params: {
            request: {},
            path: {
              idTransaction: 'some',
              idRequirement: null,
              idPerson: 'some'
            },
            body: {}
          }
        },
        saveRequirement: {
          params: {
            request: {},
            path: {},
            body: {
              'description': null,
              'person': {
                'identifier': this._auth.getPersonIdentifier(),
              },
              'requirement': {
                'alternateName': null
              },
              'requirementArchives': [
                {
                  'creator': {
                    'identifier': this._auth.getUsername()
                  }
                }
              ]
            }
          }
        },
        deleteArchive: {
          name: 'archive$archive$delete',
          params: {
            request: {},
            path: {},
            body: {
              identifier: null
            }
          }
        },
        deleteRequirement: {
          name: 'requirements$requirement$delete',
          params: {
            request: {},
            path: {},
            body: {
              alternateName: 'ACCREDITED',
              requirement: {
                'identifier': null
              },
              person: {
                'identifier': null,
              },
              requirementArchives: [{
                identifier: null
              }]
            }
          }
        },
      }
    };
  }

  facialRecognition = (client, def?) => {
    if (!isNullOrUndefined(client.person.facialRecognitionActive)) {
      return client.person.facialRecognitionActive;
    }
    return def;
  }

  clientInformation = async () => {
    const params = {
      name: 'person$enduser$get',
      params: {
        request: {
          'username': this._auth.getUsername()
        },
        path: {},
        body: {}
      }
    };
    return await this._httpService.executeRequest(params.name, params.params, true).toPromise();
  }

  loadModel = async () => {
    if (this.env ?.facialRecognition ?.active) {
      const tokens = this._modelLoadFaceApiService.loadModels(this.env ?.facialRecognition ?.landmarks ? this.env ?.facialRecognition ?.landmarks : ['descriptors', 'expressions', 'landmarks', 'ageAndGender']);
      await this._modelLoadFaceApiService.loadForFeature(tokens);
    }
  }

  ngAfterViewInit() {
    if (!this.env ?.facialRecognition ?.active) {
      this.removeCanvas();
    }
  }


  removeCanvas = () => {
    const overlayElement = document.querySelectorAll(
      '.cdk-overlay-container'
    );
    if (overlayElement) {
      overlayElement[0].remove();
    }

    const overlayElement2 = document.querySelectorAll(
      '.cdk-overlay-pane'
    );
    if (overlayElement2) {
      overlayElement2[0].remove();
    }


  }



  response_requirements($event) {

  }

  show_file(file) {
    const archive = {
      identifier: file.identifier,
      name: file.name,
      dateCreated: {
        orderDate: null
      }
    };

    if (file.dateCreated) {
      archive['dateCreated']['orderDate'] = file.dateCreated.modifiedDate;
    } else {
      archive['dateCreated']['orderDate'] = null;
    }

    if (archive.identifier !== null) {
      this._upload.visor_params.name = '';
      this._upload.visor_params.date = '';
      this._upload.visor_params.endpoint = 'archive$downloadFile$get';
      this._upload.visor_params.archive = archive;
      this._upload.visor_params.deleteAfterShow = '';
      if (file['action'] === 'download') {
        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } if (file['action'] === 'downloadzip') {

        if (this.env.theme.images === 'FACEBANK') {
          this._upload.visor_params.endpoint = 'archive$downloadZipFile$get';
        }

        this._upload.visor_params.action = 'download';
        this._upload.visor_params.deleteAfterShow = {
          deleteRequirement: file['deleteAfterShow']
        };
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      } else {
        this._upload.visor_params.action = '';
        this._upload.visor = false;
        setTimeout(() => {
          this._upload.visor = true;
        }, 6);
      }
    }
  }

  finish(event) {
    this.redirect();
  }

  redirect() {


    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
    };

    const prefixed = 'private';
    const url = 'INTER_IDENTITY_VERIF/stages/INTER_IDENTITY_VERIF/steps/INTER_IDENTITY_VERIF_END';


    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



  }

}
