<nav class="navbar ak-nav-a ">
    <div class="w-100">

        <!--
        <span class="navbar-header pull-left">
            <a *ngIf="!showIdentityVerification" (click)=" toggleMenu()" class="navbar-brand">
                <i *ngIf="!showSidebarLeft" class="fa fa-bars " aria-hidden="true"></i>
                <i *ngIf="showSidebarLeft" class="fa fa-arrow-left " aria-hidden="true"></i>
            </a>
            <app-logo-img (click)="redirectHome()" *ngIf="!showSidebarLeft" [theme]="env?.theme?.logoConf?.themeColorNavAuth" [type]="env?.theme?.logoConf?.typeNavAuth"></app-logo-img>
        </span>
         -->

        <span class="navbar-header pull-left">
            <a  *ngIf="showBackDashboard" (click)=" redirectDashboard()" class="navbar-brand">
                <i  class="fa fa-arrow-left " aria-hidden="true"></i>
            </a>
            <app-logo-img  [theme]="env?.theme?.logoConf?.themeColorNavAuth" [type]="env?.theme?.logoConf?.typeNavAuth"></app-logo-img>
     
        </span>



        <div class="navbar-header top-nav">

            <div title="Verificación de identidad" (click)="setIdentity()">
                <div [ngClass]="{'active-cursor': !compleatVerifi}" class="content-huella-circle" *ngIf="!compleatVerifi">
                    <i class="fas fa-circle text-danger blink_me_circle" aria-hidden="true"></i>
                </div>

                <i class="huella3 fas fa-fingerprint fa-2x" [ngClass]="{'text-success': verifiedIdentification,'text-primary': !verifiedIdentification && compleatVerifi  }"></i>
            </div>

            <div ngbDropdown display="dynamic" placement="bottom-right">

                <div style="cursor: pointer" ngbDropdownToggle id="navbarDropdown3" class=" navbar-brand-profile ak-vertical-center">

                    <div style=" background: url({{filePreview}}) no-repeat center; border: 1px #ddd solid;" class=" animate__animated animate__fadeIn content-avata-img " *ngIf="dataAndSelfi?.description">
                    </div>
                    <div *ngIf="!dataAndSelfi?.description" class="ak-abatar ak-dinamic-color-abatar  ">{{ _auth.getInitials() }}</div>
                </div>

                <div style="width: 270px;" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                    <div class="col-12 pb-3  pt-2   text-center">
                        <div style="font-size:.82rem" class=" container w-100 mt-2">

                            <div class=" w-100 ">
                                <div style=" background: url({{filePreview}}) no-repeat center" class=" animate__animated animate__fadeIn content-avata-img2 " *ngIf="dataAndSelfi?.description">
                                </div>

                                <div *ngIf="!dataAndSelfi?.description" class=" ml-auto mr-auto mb-2  ak-abatar ak-dinamic-color-abatar  menu">{{ _auth.getInitials() }}</div>

                                <div class="font-weight-bold " *ngIf="_auth.getSession()?.businessName">
                                    <span>
                                                <b> {{_auth.getSession()?.businessName}}</b>
                                            </span>
                                    <br>
                                </div>

                                <div class="ml-auto mr-auto">

                                    <span *ngIf="_auth.getSession()?.firstName"> {{_auth.getSession()?.firstName}}</span>
                                    <span *ngIf="_auth.getSession()?.lastName"> {{_auth.getSession()?.lastName}}</span>
                                </div>
                                <div class="mt-2 mb-2" *ngIf="_auth.getSession()?.email"> {{_auth.getSession()?.email}}</div>
                            </div>


                            <hr>
                        </div>
                        <button (click)="setShowChangePassword(true)" class=" btn btn-block btn-outline-primary btn-sm ">
                                    Cambiar Contraseña
                                </button>


                        <hr>
                        <a href="javascript:void(0)" (click)="logout()" class="text-dark font-weight-normal center">
                            <span [innerHTML]=" '@i18n-menu-label-exit' | i18n"></span>
                        </a>
                    </div>

                </div>
            </div>



        </div>


    </div>
</nav>