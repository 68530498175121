import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '@akeela/properties';
import {
  AkeelaWorkflowService,
  AkeelaStageService,
  AkeelaStepService,
  AkeelaSectionService
} from '@akeela/workflow';
import swal from 'sweetalert2';
import { I18nPipe } from '@akeela/i18n';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Subscription } from 'rxjs';
import { LocalService } from '@akeela/local-storage';
// import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Table, Row, Column } from '@akeela/ak-table';


@Component({
  selector: 'app-section-fiscal-directions',
  templateUrl: './section-fiscal-directions.component.html',
  styleUrls: ['./section-fiscal-directions.component.css']
})
export class SectionFiscalDirectionsComponent implements OnInit, OnDestroy {

  workflow;
  stage;
  step;
  transactionNumber;
  transactionData;
  itemList: any;
  queryParams;
  readonlyMode = false;
  subscriptions: Subscription[] = [];
  role;
  loading_table = false;
  configuration_table: Table;
  paramsSubscription: Subscription;


  
  private transactionDataSubscription: Subscription;
  private workflowEventsSubscription: Subscription;
  private requestSubscription: Subscription;

  constructor(
    public _httpService: HttpService,
    private akeelaWorkflowService: AkeelaWorkflowService,
    private akeelaStageService: AkeelaStageService,
    private akeelaStepService: AkeelaStepService,
    private route: ActivatedRoute,
    private akeelaSectionService: AkeelaSectionService,
    private i18n: I18nPipe,
    private localStorage: LocalService

  ) { }

  ngOnInit() {
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');

    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.
    this.akeelaWorkflowService.worflowEvents$.next(null);
    this.role = this.localStorage.getItem('role');

    this.subscriptions.push(this.akeelaStepService.currentStepMode$.subscribe((readonly) => {
      this.readonlyMode = readonly;
    }));


    this.workflow = this.akeelaWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akeelaStageService.currentStage$.getValue();
    this.step = this.akeelaStepService.currentStep$.getValue();
    this.transactionData = this.akeelaWorkflowService.transactionData$.getValue();

    this.subscriptions.push(this.akeelaWorkflowService.transactionNumber.subscribe(transactionNumber => {
      if (transactionNumber) {
        this.transactionNumber = transactionNumber;
      }
    }));

    this.subscriptions.push(this.akeelaWorkflowService.transactionData.subscribe(transactionData => {
      this.transactionData = transactionData;
      if (!isNullOrUndefined(this.transactionData) &&
        (!isNullOrUndefined(this.transactionData.organization) || !isNullOrUndefined(this.transactionData.person))) {
        this.getItemList();
      }
    }));

    this.subscriptions.push(this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    }));

    this.subscriptions.push(this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      console.log('*****************************');
      console.log(response);
      if (response && (response.action === 'account$client_identification_fiscal_address$post' || response.action === 'account$client_identification_fiscal_address$post')) {
        if (isNullOrUndefined(this.transactionData.organization) && isNullOrUndefined(this.transactionData.person)) {
          this.getTransactionData().subscribe((transactionData) => {
            if (transactionData !== null) {
              this.transactionData = transactionData;
              this.getItemList();
            }
          });
        } else {
          this.getItemList();
        }
      }
    }));

  }

  getTransactionData() {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: this.transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }

  haveAtLeastOnePep() {
    const modalFather = this.akeelaSectionService.getModalFather();
    if (!isNullOrUndefined(modalFather) && !isNullOrUndefined(modalFather.person)) {
      //Esto es cuando la entidad Padre ya esta guardada en BD y se verifican sus PEP asociados en el JSON
      if (isNullOrUndefined(modalFather.person.relatedPeps) || (!isNullOrUndefined(modalFather.person.relatedPeps) && modalFather.person.relatedPeps.length === 0)) {
        return false;
      }
    } else if (isNullOrUndefined(modalFather)) {
      //Esto es cuando la entidad Padre no esta guardada en BD y se verifica si tiene PEP en memoria.
      if (this.akeelaSectionService.countModalChildList('new_pep_legal_representative') === 0) {
        return false;
      }
    }
    return true;
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      // prevent memory leak when component destroyed
      this.subscriptions.forEach(s => s.unsubscribe());
    }
    if (this.transactionDataSubscription) {
      this.transactionDataSubscription.unsubscribe();
    }
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getItemList() {
    this.localStorage.removeItem('excludeOptionsDirections');
    const params = {
      name: 'account_opening$list_account_address$get',
      params: {
        request: {},
        path: {
          transactionNumber: this.transactionData.transactionNumber,
          idPerson: !isNullOrUndefined(this.transactionData.organization) ? this.transactionData.organization.identifier : this.transactionData.person.identifier
        },
        body: {}
      }
    };
    this.itemList = null;
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.itemList = response;

      this.itemList.sort(function (a, b) {
        return a.addressType.identifier - b.addressType.identifier;
      });

      this.setConfigurationTable();
      if (this.itemList.length === 0) {
        this.akeelaWorkflowService.setResetEstatus(true);
      } //else {


      //Mario: Comento el inhabilitar valor de lista
      for (let item of this.itemList) {
        if (Number(item.addressType.identifier) === 1) {
          this.localStorage.setItem('excludeOptionsDirectionsOnly', '[1]');
        }
      }

    


      this.setConfigurationTable();
    this.updateFather();

  }, error => {
  this.itemList = [];
}));
  }




/**tabla */

setConfigurationTable() {
  const base: Column[] = this.setHeadTable();
  this.configuration_table = {
    cssCustom: '',
    width: '100%',
    header: {
      cssCustom: 'class-color-title-table-section',
      rows: [{
        backgroundColor: '#fff',
        columns: base
      }],
    },
    body: {
      cssCustom: '',
      rows: this.setContentTable(base)
    },
    footer: {
      cssCustom: '',
      rows: []
    }
  };

}

// setHeadTable() {
//   const base: Column[] = [
//     {
//       alignContent: 'left',
//       fixed: 'right',
//       maxWidth: '200',
//       minWidth: '100',
//       html: 'Tipo',
//       cssCustom: '',
//       enableRightBorder: true

//     },
//     {
//       alignContent: 'left',
//       fixed: false,
//       maxWidth: '200',
//       minWidth: '100',
//       html: 'Dirección',
//       cssCustom: ''
//     },
//     {
//       alignContent: 'left',
//       fixed: false,
//       maxWidth: '200',
//       minWidth: '100',
//       html: 'País',
//       cssCustom: ''
//     },
//     {
//       alignContent: 'center',
//       fixed: 'left',
//       maxWidth: '20',
//       minWidth: '20',
//       html: '',
//       cssCustom: ''
//     }
//   ]
//   return base;
// }


setContentTable(configuration): Row[] {
  const content: Row[] = [];
  let i = 0;
  for (const row of this.itemList) {
    console.log(row);
    let description =  (!isNullOrUndefined(row.description)?row.description: row.addressLocality.name  ) +", " +  (row.city && row.city.name ) +", " +  (row.state && row.state.name ) +", " + (row.addressCountry && row.addressCountry.description);
    
    i++;
    content.push(
      {
        identifier: row.identifier,
        cssCustom: '',
        enabled: true,
        columns: [

          this.setRowContentTable({
            configuration,
            number: 0,
            html: description ,

          }),
          
          this.setRowContentTable({

            configuration,
            number: 1,
            html: (row.addressType.alternateName),


          }),

          this.setRowContentTable({

            configuration,
            number: 2,
            html: 'Ver',
            cssCustom: 'ak-tabled-row-active text-capitalize'

          }),

          this.setRowContentTable({
            configuration,
            number: 3,
            html: '<i class="fa fa-trash" title="Eliminar" aria-hidden="true"></i>',
            click: 'delete_item',
          })
        ]
      }
    );
  }
  return content;
}

// setRowContentTable(row) {

//   const configuration = row.configuration;
//   const number = row.number;
//   const html = row.html;
//   const click = row.click ? row.click : null;
//   const dropdown = row.dropdown ? row.dropdown : null;
//   const cssCustom = row.cssCustom ? row.cssCustom : configuration[number].cssCustom;


//   const result = {
//     alignContent: configuration[number].alignContent,
//     fixed: configuration[number].fixed,
//     maxWidth: configuration[number].maxWidth,
//     minWidth: configuration[number].minWidth,
//     html: html,
//     click: click,
//     dropdown: dropdown,
//     cssCustom: cssCustom,
//     enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
//     enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
//   };

//   return result;

// }

// table_response(response) {

//   if (response.action === 'open') {
//     this.openItem(response.object.identifier);

//   }else if (response.action === 'action') {
//      if (response.object.action === 'delete_item' && !this.readonlyMode) {

//       this.confirmDelete(response.object.obj.identifier);
//     }
//   }
// }

table_response(response) {
  if (response.action === 'open') {

    let row = this.itemList.find((item: any) => {
      return item.identifier === response.object.identifier;
    });
    this.openItem(row);

  } else if (response.action === 'action') {
    if (response.object.action === 'delete_item' && !this.readonlyMode) {

      let row = this.itemList.find((item: any) => {
        return item.identifier === response.object.obj.identifier;
      });
      this.confirmDelete(row);
    }
  }

}

updateFather() {
  const self = this;
  const modalFather = self.akeelaSectionService.getModalFather();
  if (!isNullOrUndefined(modalFather)) {
    if (!isNullOrUndefined(modalFather.person)) {
      const fatherToUpdate = this.itemList.find(
        (_father) => (!isNullOrUndefined(_father.person)) && (_father.person.identifier === modalFather.person.identifier)
      );
      if (!isNullOrUndefined(fatherToUpdate)) {
        self.akeelaWorkflowService.worflowEvents$.next({
          response: fatherToUpdate,
          action: 'update_legal_representative_pep'
        });
      }
    }
  }
}

openItem(row) {
  this.getAddress(row).then((resp) => {
    if (!isNullOrUndefined(resp)) {
      // resp = { organization: { address: resp } };
      console.log(row);
      //resp['disableWhenUpdate'] = 'organization.address.addressType.identifier';
      // if (!isNullOrUndefined(this.localStorage.getItem('excludeOptionsDirections'))) {
      //   this.localStorage.setItem('excludeOptionsDirectionsModeEdit', this.localStorage.getItem('excludeOptionsDirections'));
      //   this.localStorage.removeItem('excludeOptionsDirections');
      //   if (this.localStorage.getItem('excludeOptionsDirections') === '[1]') {
      //     this.localStorage.setItem('excludeOptionsDirections', '[2]');
      //   } else if (this.localStorage.getItem('excludeOptionsDirections') === '[2]') {
      //     this.localStorage.setItem('excludeOptionsDirections', '[1]');

      //   }
      // }
      this.localStorage.setItem('modalFormModeUpdate', 'organization.address.addressType.identifier'); // le indico a wf, que está activado el modo de edición de un modal

      this.akeelaSectionService.addModalSection({ name: 'FISCAL_ADDRESS_MODAL' });
      this.akeelaSectionService.showModalSection('FISCAL_ADDRESS_MODAL');
      this.akeelaSectionService.setModalFormValue('FISCAL_ADDRESS_MODAL', resp);
    }
  });
}

getAddress(row) {
  return new Promise((resolve, reject) => {
    const params = {
      name: 'account_opening$account_address$get',
      params: {
        path: {
          identifierAccountAddress: row.identifier,
        },
        request: {}
      }
    };
    this.subscriptions.push(this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      resolve(response);
    }, error => {
      resolve(null);
    }));
  });
}

confirmDelete(row: any) {
  swal.fire({
    title: this.i18n.transform('@i18n-popup-text-delete-question'),
    text: this.i18n.transform('@i18n-popup-text-delete-question-text'),
    showCancelButton: true,
    confirmButtonText: this.i18n.transform('@i18n-popup-text-yes'),
    cancelButtonText: this.i18n.transform('@i18n-popup-text-no'),
    reverseButtons: true
  }).then((result) => {
    if (!isNullOrUndefined(result.value) && result.value) {
      this.deleteItem(row);
    }
  });
}

deleteItem(row: any) {
  const fiscalAddressId = row.identifier;
  const configuration = {
    name: 'account_opening$fiscalAddress$delete',
    params: {
      path: {
        fiscalAddressId: fiscalAddressId,
        transactionNumber: this.transactionNumber,
        workflow: this.workflow,
        stage: this.stage,
        step: this.step
      },
      request: {
        audience: this.queryParams.audience,
        role: this.queryParams.role
      }
    }
  };

  this.subscriptions.push(this._httpService.executeRequest(configuration.name, configuration.params).subscribe((response) => {
    this.localStorage.removeItem('excludeOptionsDirections');
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');


    swal.fire({
      title: this.i18n.transform('Éxito'),
      html: this.i18n.transform('@i18n-text-information-deleted')
    });
    this.updateList('account$client_identification_fiscal_address$post');
    this.akeelaWorkflowService.setResetEstatus(true);

  }, error => {
    // dfutrille esto debe borrarse.
    this.localStorage.removeItem('excludeOptionsDirectionsOnly');

    this.updateList('account$client_identification_fiscal_address$post');
  }));
}

updateList(action: string) {
  this.akeelaWorkflowService.worflowEvents$.next({
    response: {},
    action: action
  });
}



  setHeadTable(){
    
    const base: Column[] = [

      {
        alignContent: 'left',
        fixed: false,
        minWidth: '100',
        html: this.i18n.transform('Dirección'),
        cssCustom: '',

      },

      {
        alignContent: 'left',
        fixed: 'right',
        maxWidth: '200',
        minWidth: '100',
        html: 'Tipo',
        cssCustom: '',

      },  

      {
        alignContent: 'left',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html:'',
        cssCustom: ''
      },
     
      {
        alignContent: 'center',
        fixed: 'left',
        maxWidth: '20',
        minWidth: '20',
        html:'',
        cssCustom: ''
      }
  ]
    return base;
  }
  


  setRowContentTable(row){
    
    let configuration = row.configuration;
    let number = row.number;
    let html = row.html;
    let click = row.click?row.click:null ;
    let dropdown = row.dropdown?row.dropdown:null ;
    let cssCustom = row.cssCustom?row.cssCustom:configuration[number].cssCustom;


let result = {
      
      alignContent: configuration[number].alignContent,
      fixed: configuration[number].fixed,
      maxWidth: configuration[number].maxWidth,
      minWidth: configuration[number].minWidth,
      html: html,
      click:click,
      dropdown:dropdown,
      cssCustom: cssCustom,
      enableLeftBorder: !isNullOrUndefined(configuration[number].enableLeftBorder) ? configuration[number].enableLeftBorder : false,
      enableRightBorder: !isNullOrUndefined(configuration[number].enableRightBorder) ? configuration[number].enableRightBorder : false
    }
  
return result;

  }
  


}


