<akeela-template>

    <ng-template akeela-content componentName="ComponentProducts">
        <select-product></select-product>
    </ng-template>
    <ng-template akeela-content componentName="ComponentPlans">
        <public-plans></public-plans>
    </ng-template>
    <ng-template akeela-content componentName="ComponentConfirmationUserAccountOpening">
        <app-create-user></app-create-user>
    </ng-template>
</akeela-template>

<div>
    <div class="container-fluid ">
        <router-outlet class="animate__animated animate__fadeIn" ></router-outlet>
    </div>
</div>