import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpService } from '@akeela/properties';
import { AlertService } from '@akeela/alert';
import { ErrorService } from '@akeela/common';
import { environment } from '../../../../../environments/environment';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _httpService: HttpService,
    private _alert: AlertService,
    private _error: ErrorService,
    private i18n: I18nPipe

  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(queryParams => {
      const params = {
        name: 'person$confirm_user_email$put',
        params: {
          path: {},
          request: {},
          body: {
            name: queryParams['token']
          }
        }
      };
      this._httpService.executeRequest(params.name, params.params).subscribe((response: any) => {
   

        swal.fire({
          title: 'Confirmación exitosa!',
          html: 'Favor inicie sesión para continuar con el proceso',
          showCancelButton: false,
          confirmButtonText: this.i18n.transform('@i18n-popup-text-ok'),
          reverseButtons: true
        }).then((result) => {
    
          this._router.navigateByUrl(this._router.createUrlTree
            (['public/' + environment.workflow + '/stages/LOGIN_ACCOUNT_OPENING/steps/LOGIN_ACCOUNT_OPENING'],
            {
              queryParams: {}
            }
            )
          );


        });



      }, error => {

        swal.fire({
          title:'Link Inválido',
          text: 'La cuenta ya fue confirmada, o el link es inválido!',
          showCancelButton: false,
          confirmButtonText: this.i18n.transform('@i18n-popup-text-ok'),
          reverseButtons: true
        }).then((result) => {
    
          this._router.navigateByUrl(this._router.createUrlTree
            (['security/login'],
            {
              queryParams: {}
            }
            )
          );
          
         
        });

      });
    });
  }

}
