import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PUBLIC_ROUTES as PUBLIC_WF_ROUTES  } from '../modules/public/routes/public-wf.routes';
import { PUBLIC_ROUTES, PUBLIC_EXTERNAL_ROUTES } from '../modules/public/routes/public.routes';

import { PRIVATE_ROUTES } from '../modules/private/routes/private.routes';


// COMPONENTS...
import { DashboardComponent } from '../components/index';
import { LoginComponent, LogoutComponent, ValidateAuthCodeComponent } from '@akeela/security';
import { viewPdfComponent } from '@akeela/common';



const APP_ROUTES: Routes = [
    {
        path : '',
        component : DashboardComponent
    },
    PUBLIC_EXTERNAL_ROUTES,
    PUBLIC_WF_ROUTES,
    PUBLIC_ROUTES,
    PRIVATE_ROUTES,

    {
        path: 'security/login',
        component: LoginComponent
    },
    {
        path: 'security/logout',
        component: LogoutComponent
    },

    {
        path: 'security/validate_code',
        component: ValidateAuthCodeComponent
    },
    {
        path: 'security/validate_code',
        component: ValidateAuthCodeComponent
    },
    {
        path: 'view-pdf',
        component: viewPdfComponent
    },
    

];

export const ROUTES: ModuleWithProviders<any> = RouterModule.forRoot(APP_ROUTES, { onSameUrlNavigation: 'reload',  scrollPositionRestoration: 'enabled',
anchorScrolling: 'enabled' });
