import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpService } from '@akeela/properties';
import { AkeelaWorkflowService, AkeelaStageService, AkeelaStepService, AkeelaSectionService } from '@akeela/workflow';
import { AuthService } from '@akeela/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AkeelaBreadcrumbService } from '@akeela/breadcrumb';
import { isNullOrUndefined } from 'util';
import { TransitionService } from '../../../../services/transition.service';
import { environment } from '../../../../../environments/environment';
import { I18nPipe } from '@akeela/i18n';
import swal from 'sweetalert2';
import { LocalService } from '@akeela/local-storage';
import { AkeelaSidebarMenuService } from '@akeela/menu';
import { Subscription } from 'rxjs';

declare var Swal: any;
@Component({
  selector: 'app-paperwork',
  templateUrl: './paperwork.component.html',
  styleUrls: ['./paperwork.component.css']
})
export class PaperworkComponent implements OnInit, OnDestroy, AfterViewInit {
  workflow;
  stage;
  step;
  role;
  formalities = null;
  totalFormalities = null;

  sentFormalities = null;
  draftFormalities = null;
  inboxFormalities = null;

  formalitieI18n = '';

  queryParams;
  active_redirect = false;
  breadcrumdKey: any;
  redirect = true;
  cargando = '';
  url_transition = 'workflow$transitionssteps$get';
  trans_subscriber: Subscription;
  route_subscriber: Subscription;
  wf_events_subscriber: Subscription;
  trans3_subscriber: Subscription;
  trans2_subscriber: Subscription;
  trans1_subscriber: Subscription;
  cout_subscriber: Subscription;
  temp_subscriber: Subscription;
  indicatorsProgres;
  indicatorsLoading;


  code = '';

  usuario_session = '';
  transactionData;
  usuario;

  paginator: any = null;
  loading_table = true;
  env = environment;
  active;
  paramsSubscription: Subscription;


  constructor(
    public _httpService: HttpService,
    private akWorkflowService: AkeelaWorkflowService,
    private akStageService: AkeelaStageService,
    private akStepService: AkeelaStepService,
    private akSectionService: AkeelaSectionService,
    private _auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private akeelaSectionService: AkeelaSectionService,
    private akeelaBreadcrumbService: AkeelaBreadcrumbService,
    private _transition: TransitionService,
    private activatedRoute: ActivatedRoute,
    private _i18n: I18nPipe,
    private localStorage: LocalService,
    public _akeelaSidebarMenu: AkeelaSidebarMenuService,


  ) {

  }



  ngOnInit() {
    // debugger;

    // document.body.classList.add('teme-gray');


    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);
    this.akStepService.setModeCurrentStep(false);
    this.akWorkflowService.worflowEvents$.next(null);
    this.workflow = this.akWorkflowService.currentWorkflow$.getValue();
    this.stage = this.akStageService.currentStage$.getValue();
    this.akStageService.setshowHeaderStage(false);
    this.akStepService.setshowHeaderStep(false);
    this.akStepService.setshowSubHeaderStep(false);
    this.step = this.akStepService.currentStep$.getValue();
    this.akeelaSectionService.addModalSection({ name: 'PROD_PLAN_SELECTOR_MODAL' });
    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.setTransactionData(null);
    this.role = this.localStorage.getItem('role');

    this.akWorkflowService.currentWorkflow$.subscribe((response) => {
      if (!isNullOrUndefined(response)) {
        this.workflow = response;
      }
    });

    this.usuario_session = this._auth.getUsername();

    this.usuario = this._auth.getSession();

    this.temp_subscriber = this.activatedRoute.queryParams.subscribe(params => {
      let code: string;

      let tmp = params.transactionNumber;
      this.code = code = params.code;
      this.paginator = null;

      if (isNullOrUndefined(tmp)) {


        if (isNullOrUndefined(code)) {
          this.active = null;
          this.inboxTotalElements(code);

        } else {
          this.active = true;
          this.formalities = null;
          this.formalitieI18n = '';

          this.countAll(code);

        }

      }


    });

    if (isNullOrUndefined(this.code)) {
      this._akeelaSidebarMenu.setSidebarLeftActive('HOME');
    }


    this.route_subscriber = this.route.queryParams.subscribe(queryParams => {
      this.queryParams = queryParams;
    });




    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
    this.wf_events_subscriber = this.akWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (response) {
        if (response.action === 'account$accountopening$post' && response.response.transactionNumber && !this.active_redirect) {
          this.wait();
          this.active_redirect = true;
          this.akWorkflowService.setTransactionNumber(response.response.transactionNumber);
          this.getAccountproductinformation(response, response.response.transactionNumber);
        }
      }
    });

  }


  getAccountproductinformation(responseIn, transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };



    this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
      this.transactionData = response;

      this.akWorkflowService.transactionData$.next(response);

      const queryParams = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('audience'),
        transactionNumber: responseIn.response.transactionNumber
      };
      const parameters = {
        name: this.url_transition,
        params: {
          request: {
            role: 'CLIENT',
            profile: 'CLIENT',
            audience: this._auth.getUserAudience() ? this._auth.getUserAudience() : 'LEGAL',
            event_code: 'PRODUCT_PLAN_SELECTED'
          },
          path: {
            prefixWorkflow: 'workflow_manager',
            workflow: this.workflow,
            stage: this.stage,
            step: this.step
          }
        },
        o: {}
      };
      this._transition.next('private', parameters, queryParams);


      setTimeout(() => {
        this.akeelaSectionService.hideModalSection('PROD_PLAN_SELECTOR_MODAL');
        swal.close();

      }, 500);

      this.destroySubscribers();

    });
  }


  ngAfterViewInit() {


  }

  redirectInbox(code, sidebar) {

    this.paginator = null;
    const prefixed = 'private';
    const url = 'ACCOUNT_OPENING/stages/LIST_ACCOUNT_OPENING/steps/LIST_ACCOUNT_OPENING';

    const queryParams = {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience'),
      code: code
    };

    this._akeelaSidebarMenu.setSidebarLeftActive(sidebar);


    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });



  }


  countAll(code, page?, size?) {
    this.loading_table = true;

    let request = {};

    request = {
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'
    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.cout_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      if (response.totalElements < 1) {

        this.totalFormalities = response.totalElements;
        this.loading_table = false;


      } else {

        this.inbox(code);
        this.sent(code);
        this.draft(code);

      }
    }, error => {

    });


  }

  inbox(code, page?, size?) {
    let request = {};
    this.loading_table = true;

    request = {
      'is_response': true,
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'

    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      this.inboxFormalities = response.totalElements;
      if (code === 'inbox' || isNullOrUndefined(code) || code === 'HOME') {
        this.paginator = response;
        this.formalitieI18n = '@i18n-menu-label-client-inbox';
        this.formalities = response.content;
        this.getIndicatorsProgres(this.formalities);

      }

      this.loading_table = false;

    }, error => {
      this.loading_table = false;
      this.inboxFormalities = 0;
      if (code === 'inbox' || isNullOrUndefined(code) || code === 'HOME') {
        this.formalities = [];
      }

    });

  }

  sent(code, page?, size?) {
    this.loading_table = true;

    let request = {}; request = {
      'transacctionStatus': 'AC_PENDING',
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'

    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      this.loading_table = false;

      this.sentFormalities = response.totalElements;

      if (code === 'sent') {
        this.paginator = response;

        this.formalitieI18n = '@i18n-menu-label-client-sent';
        this.formalities = response.content;
        this.getIndicatorsProgres(this.formalities);


      }

    }, error => {
      this.loading_table = false;
      this.sentFormalities = 0;
      if (code === 'sent') {
        this.formalities = [];
      }

    });


  }

  draft(code, page?, size?) {
    this.loading_table = true;

    let request = {}; request = {
      'transacctionStatus': 'PI_IN_PROCESS',
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'
    };


    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      this.draftFormalities = response.totalElements;

      if (code === 'draft') {
        this.paginator = response;
        this.formalitieI18n = '@i18n-menu-label-client-draft';
        this.formalities = response.content;
        this.getIndicatorsProgres(this.formalities);


      }
      this.loading_table = false;

    }, error => {
      this.loading_table = false;
      this.draftFormalities = 0;
      if (code === 'draft') {
        this.formalities = [];
      }

    });


  }


  openFormalities(transactionNumber, requirements = false) {
    this.deleteEditTransaction();
    this.localStorage.removeItem('transactionModeEditShowFlag');
    this._transition.showWorkflow$.next(false);
    this.cargando = transactionNumber;


    this.akWorkflowService.setTransactionNumber(null);
    this.akWorkflowService.setTransactionNumber(transactionNumber);
    this.getTransactionData(transactionNumber).subscribe((response: any) => {
      this.transactionData = response;



      this.akWorkflowService.transactionData$.next(response);
      const queryParams = {
        role: this.localStorage.getItem('role'),
        profile: this.localStorage.getItem('profile'),
        audience: this.localStorage.getItem('audience'),
        transactionNumber: transactionNumber
      };
      let url;

      if (requirements) {

        url = [environment.staticLinks.requirementsUpload];
        this.router.navigateByUrl(
          this.router.createUrlTree(url,
            {
              queryParams: queryParams
            }
          )
        ).then(() => {
          this.destroySubscribers();
        });

        this.cargando = '';


      } else {


        if (response.accountStatus[0].additionalType == 'PI_IN_PROCESS') {

          const configuration = {
            name: 'account_opening$current_step$get',
            params: {
              path: {
                transactionNumber: this.transactionData.transactionNumber,
                idPerson: this.transactionData.holder ? this.transactionData.holder.identifier : null
              },
              request: {
                idPlan: this.transactionData.plan.identifier,
                role: this.localStorage.getItem('role'),
                audience: this.localStorage.getItem('audience'),
                depend: false
              }
            }
          };

          this._httpService.executeRequest(configuration.name, configuration.params, true).subscribe((resp: any) => {

            this.destroySubscribers();
            if (!isNullOrUndefined(resp)) {

              let stage = resp.stageParentCode;
              let step = resp.alternateName;
              let urlWf = resp.url;


              url = ['private/' + urlWf + '/stages/' + stage + '/steps/' + step];

            } else {

              url = ['private/' + environment.workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING'];

            }


            this.router.navigateByUrl(
              this.router.createUrlTree(url,
                {
                  queryParams: queryParams
                }
              )
            ).then(() => {
              this.destroySubscribers();
            });

            this.cargando = '';

          }, error => {


          });


        } else {

          url = ['private/' + environment.workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING'];

          this.router.navigateByUrl(
            this.router.createUrlTree(url,
              {
                queryParams: queryParams
              }
            )
          ).then(() => {
            this.destroySubscribers();
          });


        }


      }


    });

  }

  getTransactionData(transactionNumber) {
    const params = {
      name: 'account$accountproductinformation$get',
      params: {
        request: {
          role: this.role
        },
        path: {
          transactionNumber: transactionNumber
        },
        body: {}
      }
    };
    return this._httpService.executeRequest(params.name, params.params, true);
  }


  new() {

    this.localStorage.setItem('clientaudience', this.localStorage.getItem('audience'));
    this.akeelaSectionService.showModalSection('PROD_PLAN_SELECTOR_MODAL');
    this.akStepService.setModeCurrentStep(false);

  }

  destroySubscribers() {



    if (this.temp_subscriber) {
      this.temp_subscriber.unsubscribe();
    }

    if (this.trans_subscriber) {
      this.trans_subscriber.unsubscribe();
    }
    if (this.trans1_subscriber) {
      this.trans1_subscriber.unsubscribe();
    }
    if (this.trans2_subscriber) {
      this.trans2_subscriber.unsubscribe();
    }
    if (this.trans3_subscriber) {
      this.trans3_subscriber.unsubscribe();
    }
    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }
    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }
  }

  confirmDelete(row) {
    swal.fire({
      title: this._i18n.transform('@i18n-popup-text-delete-question'),
      text: this._i18n.transform('@i18n-popup-text-delete-question-text'),
      showCancelButton: true,
      confirmButtonText: this._i18n.transform('@i18n-popup-text-yes'),
      cancelButtonText: this._i18n.transform('@i18n-popup-text-no'),
      reverseButtons: true
    }).then((result) => {
      if (result) {
        this.trash(row);
      }
    });
  }

  trash(row) {
    const request = {};
    const param = {
      name: 'account_opening$account$delete',
      params: {
        request: {
          requestUser: this._auth.getUsername(),
          reason: 'SOMETEXT'
        },
        path: {
          transactionNumber: row.transactionNumber,
        },
        body: {}
      }
    };

    this.trans_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {
      this.inbox(this.code);
      this.sent(this.code);
      this.draft(this.code);

    });

  }

  ngOnDestroy() {

    document.body.classList.remove('teme-gray');

    this.redirect = false;
    this.akeelaBreadcrumbService.setbreadcrumbOptions([]);


    if (this.route_subscriber) {
      this.route_subscriber.unsubscribe();
    }

    if (this.wf_events_subscriber) {
      this.wf_events_subscriber.unsubscribe();
    }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }

    this.destroySubscribers();
  }

  deleteEditTransaction() {
    if (!isNullOrUndefined(this.localStorage.getItem('transactionModeEdit'))) {
      //this.breakfreeTransactionEdit(); // Mario: este caso era de liberación individual, pero debido a que ahora se va a liberar por usuario, se comenta para ver el comportamiento
      this.breakfreeTransactionsByUser();
    } else {
      this.breakfreeTransactionsByUser();
    }
  }

  breakfreeTransactionsByUser() {
    // alert('voy a ejecuctar 2');

    const configuration = {
      name: 'account$finish_editing_role_by_user$put',
      params: {
        path: {},
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this._auth.getPersonIdentifier()

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');
    });
  }

  breakfreeTransactionEdit() {
    // alert('voy a ejecuctar 111111');

    const configuration = {
      name: 'account_opening$finish_editing_role$put',
      params: {
        path: {
          transactionNumber: this.localStorage.getItem('transactionModeEdit')
        },
        request: {
          role: 'CLIENT',
          holderId: this.localStorage.getItem('audience') === 'LEGAL' ? this._auth.getAdminOrganizationIdentifier() : this._auth.getPersonIdentifier()

        }
      }
    };
    this._httpService.executeRequest(configuration.name, configuration.params).subscribe((resp: any) => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');

    }, error => {
      this.localStorage.removeItem('transactionModeEdit');
      this.localStorage.removeItem('transactionModeEditShow');

    });
  }

  response_paginator(page) {
    this.paginator = null;
    if (this.code === 'draft') {
      this.draft(this.code, page, environment.paginator.size);
    } else if (this.code === 'sent') {
      this.sent(this.code, page, environment.paginator.size);
    } else if (this.code === 'inbox' || this.code === 'HOME' || isNullOrUndefined(this.code)) {
      this.inbox(this.code, page, environment.paginator.size);
    }
  }


  inboxTotalElements(code, page?, size?) {
    let request = {};
    this.loading_table = true;

    request = {
      'is_response': true,
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'

    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };
    this.trans1_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      if (response.totalElements > 0) {

        this.redirectInbox('inbox', 'HOME');

      } else {

        this.draftTotalElements(code);

      }



    }, error => {


    });

  }

  sentTotalElements(code, page?, size?) {
    this.loading_table = true;

    let request = {}; request = {
      'transacctionStatus': 'AC_PENDING',
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'

    };

    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans2_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {


      if (response.totalElements > 0) {
        this.redirectInbox('sent', 'SENT');



      } else {
        this.redirectInbox('inbox', 'HOME');


      }




    }, error => {


    });


  }

  draftTotalElements(code, page?, size?) {

    let request = {}; request = {
      'transacctionStatus': 'PI_IN_PROCESS',
      page: page ? page : environment.paginator.page,
      size: size ? size : environment.paginator.size,
      sort_by: 'status_begin_date'
    };


    const param = {
      name: 'account$myformalities_paginated$get',
      params: {
        request: request,
        path: {
          username: this._auth.getUsername()
        },
        body: {}
      }
    };

    this.trans3_subscriber = this._httpService.executeRequest(param.name, param.params, true).subscribe((response: any) => {

      if (response.totalElements > 0) {


        this.redirectInbox('draft', 'DRAFT');

      } else {

        this.sentTotalElements(code);

      }



    }, error => {
    });


  }


  wait() {
    swal.fire({
      title: '',
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false,
      html: '<b>' + this._i18n.transform('@i18n-text-please-wait') + '...<b><br><i class="fa fa-spin fa-spinner"></i>',
    });
  }


getIndicatorsProgres(datas) {

  debugger;

  let transactions = [];

    for (const row of   datas ) {

      if(row?.accountStatus[0].additionalType =='PI_IN_PROCESS'){
        transactions = [...transactions, {transactionNumber:row?.transactionNumber}]
      }
            

    }

  
  const params = {


    name: 'accounts$progress$post',
    params: {
      request: {},
      path: {
        username: this._auth.getUsername(),
      },
      body: {
        bankAccountTransactions :transactions
        
      }
    }
  };


  this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {

    if (response) {
        this.indicatorsProgres = this.setIndicatorsProgres(response);

        this.indicatorsLoading = false;

      }


  });



}


setIndicatorsProgres(datas:any){

  let indicatorsProgres:any = {};

for (const data of datas) {


  let response : any = [] ;
  response = data?.modules;

  const items = [];
  response = response?.sort(function (a, b) {
    return a.identifier - b.identifier;
  });
  for (const resp of response) {
    items.push({
      identifier: resp.alternateName,
      name: resp.approvalUser,
      icon: resp.modifyUser,
      active: true,
      type: resp.additionalType,
      progress: {
        percent: null,
        total: resp.progress.maxValue ? resp.progress.maxValue : 0,
        completed: resp.progress.minValue ? resp.progress.minValue : 0
      },
      link: resp.url ? JSON.parse(resp.url) : null
    });
  }
  let modules = items;
  let module_AO;
  let module_RQ;
  let AO_range;
  let RQ_range;


  let compleat = true;
  for (let item of modules) {

    if (item.identifier == 'ACCOUNT_OPENING') {

      if (item.progress.completed == item.progress.total) {
        item.progress.completed--;
      }

      item.progress.total--;
    }

    item.progress.completed != item.progress.total

    if (item) {

      if (item.progress.completed != item.progress.total) {
        compleat = false;
      }
    }
  }

  for (let item of modules) {

    if (item.identifier == 'ACCOUNT_OPENING') {

      module_AO = item;
      AO_range = this.round(module_AO?.progress?.completed*100/module_AO?.progress?.total);

    }   
      if (item.identifier == 'REQUIREMENT') {


      module_RQ = item;
      RQ_range = this.round(module_RQ?.progress?.completed*100/module_RQ?.progress?.total);

    }

  }

    indicatorsProgres[data?.bankAccountTransaction?.transactionNumber] = {module_AO,AO_range,module_RQ,RQ_range};
}

 return indicatorsProgres;

}


round(value) {
  return Math.round(value);
}



}
