import { I18nPipe } from '@akeela/i18n';
import { AkeelaWorkflowService } from '@akeela/workflow';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../environments/environment';


@Component({
  selector: 'app-section-constitution-data',
  templateUrl: './section-constitution-data.component.html',
  styleUrls: ['./section-constitution-data.component.css']
})
export class ConstitutionDataComponent implements OnInit {

  private workflowEventsSubscription: Subscription;

  constructor(
    private akeelaWorkflowService: AkeelaWorkflowService,
    private i18n: I18nPipe
  ) { }

  ngOnInit() {
    //[dfutrille] Esta ejecucion de evento es para prevenir la suscripcion al ultimo evento ejecutado.
    //Esto se debe evaluar, ya que es algo que molesta en muchas ejecuciones.
    this.akeelaWorkflowService.worflowEvents$.next(null);

    this.workflowEventsSubscription = this.akeelaWorkflowService.worflowEvents$.subscribe((response: any) => {
      if (!isNullOrUndefined(response) && response.action === 'send_formValidationEvent_constitution_data') {
        if (this.validateDates(response) && this.validateLastUpdate(response)) {
          this.sendEventToContinue(response.response);
        }
      }
    });

  }

  validateDates(response) {
    if (!isNullOrUndefined(response.response.forms.controls.organization.controls.registryLastUpdate)) {




      let date = new Date()

      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()


      //Validacion de Fechas de Modificacion y Constitucion

      const valueDateMayor = response.response.forms.controls.organization.controls.registryLastUpdate.controls.registryDate.value;
      const valueDateMinor = response.response.forms.controls.organization.controls.registryData.controls.registryDate.value;


      const dateNow = new Date(year + "-" + month + "-" + day);
      const dateMayor = new Date(valueDateMayor);
      const dateMinor = new Date(valueDateMinor);
      console.log(dateNow + ' < ' + dateMinor);
      if (!isNullOrUndefined(valueDateMinor) && valueDateMinor !== '') {

        if (dateNow.getTime() < dateMinor.getTime()) {
          swal.fire({
            icon: 'warning',
            text: this.i18n.transform('@i18n-validation-constitution-data-date-registryData'),
          });
          return false;
        }



      }

      if (!isNullOrUndefined(valueDateMayor) && valueDateMayor !== '') {


        if (dateNow.getTime() < dateMayor.getTime()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-constitution-data-date-registryLastUpdate'),
          })
          return false;
        }



        if (dateMayor.getTime() <= dateMinor.getTime()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-constitution-data-dates'),
          })
          return false;
        }
      }

    } else if (!isNullOrUndefined(response.response.forms.controls.organization.controls.registryData.controls.appointmentExpiryDate)) { // CASO FACEBANK
      let date = new Date()

      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()


      //Validacion de Fechas de Nombramiento y Fecha de Vencimiento del Nombramiento

      const valueDateMayor = response.response.forms.controls.organization.controls.registryData.controls.appointmentExpiryDate.value;
      const valueDateMinor = response.response.forms.controls.organization.controls.registryData.controls.appointmentDate.value;


      const dateNow = new Date(year + "-" + month + "-" + day);
      const dateMayor = new Date(valueDateMayor);
      const dateMinor = new Date(valueDateMinor);
      console.log(dateNow + ' < ' + dateMinor);
      if (!isNullOrUndefined(valueDateMinor) && valueDateMinor !== '') {

        if (dateMinor.getTime() > dateMayor.getTime()) {
          swal.fire({
            icon: 'warning',
            text: this.i18n.transform('@i18n-validation-constitution-data-date-registryData-facebank'),
          });
          return false;
        }



      }

      if (!isNullOrUndefined(valueDateMayor) && valueDateMayor !== '') {


        if (dateNow.getTime() > dateMayor.getTime()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-constitution-data-date-registryData-facebank-expiration'),
          })
          return false;
        }



        if (dateMayor.getTime() <= dateMinor.getTime()) {
          swal.fire({
            icon: "warning",
            text: this.i18n.transform('@i18n-validation-constitution-data-date-registryData-facebank-expiration'),
          })
          return false;
        }
      }
    }
    return true;
  }


  validateLastUpdate(response) {
    if (response.response.forms.controls.organization.controls.registryLastUpdate) {


      if (environment.theme.images === 'FACEBANK') {

        const pais = response.response.forms.controls.organization.controls.address.controls.addressCountry.value.identifier;
        const folio = response.response.forms.controls.organization.controls.registryLastUpdate.controls.folio.value;
        const name = response.response.forms.controls.organization.controls.registryLastUpdate.controls.name.value;
        const registryDate = response.response.forms.controls.organization.controls.registryLastUpdate.controls.registryDate.value;
        const registryNumber = response.response.forms.controls.organization.controls.registryLastUpdate.controls.registryNumber.value;
        const socialCapital = response.response.forms.controls.organization.controls.registryLastUpdate.controls.socialCapital.value;
        const tomo = response.response.forms.controls.organization.controls.registryLastUpdate.controls.tomo.value;


        if (pais == "VE") {


          if (

            (folio !== '' && name !== '' && registryDate !== '' && registryNumber !== '' && tomo !== '') ||

            (folio === '' && name === '' && registryDate === '' && registryNumber === '' && (socialCapital === '' || isNullOrUndefined(socialCapital)) && tomo === '')

          ) {
            return true;
          }


        } else {

          if (

            (registryDate !== '' && (socialCapital !== '' || isNullOrUndefined(socialCapital))) ||

            (folio === '' && name === '' && registryDate === '' && registryNumber === '' && (socialCapital === '' || isNullOrUndefined(socialCapital)) && tomo === '')

          ) {
            return true;
          }


        }


      } else {

        const folio = response.response.forms.controls.organization.controls.registryLastUpdate.controls.folio.value;
        const name = response.response.forms.controls.organization.controls.registryLastUpdate.controls.name.value;
        const registryDate = response.response.forms.controls.organization.controls.registryLastUpdate.controls.registryDate.value;
        const registryNumber = response.response.forms.controls.organization.controls.registryLastUpdate.controls.registryNumber.value;
        const socialCapital = response.response.forms.controls.organization.controls.registryLastUpdate.controls.socialCapital.value;
        const tomo = response.response.forms.controls.organization.controls.registryLastUpdate.controls.tomo.value;
        if (
          (folio !== '' && name !== '' && registryDate !== '' && registryNumber !== '' && socialCapital !== '' && tomo !== '') ||
          (folio === '' && name === '' && registryDate === '' && registryNumber === '' && (socialCapital === '' || isNullOrUndefined(socialCapital)) && tomo === '')
        ) {
          return true;
        }

      }

      swal.fire({
        icon: "warning",
        text: this.i18n.transform('@i18n-validation-constitution-last-update'),
      })
      return false;


    } else {

      return true;
    }

  }




  sendEventToContinue(response) {
    this.akeelaWorkflowService.worflowEvents$.next({
      response: {
        fn: response.fn,
        event: event,
        sectionIdentifier: response.sectionIdentifier,
        field: response.field
      },
      action: 'receive_formValidationEvent'
    });
  }

  ngOnDestroy() {
    if (this.workflowEventsSubscription) {
      this.workflowEventsSubscription.unsubscribe();
    }
  }

}
