import { Component, OnInit, Input, EventEmitter, Output, ViewChild, HostListener, DoCheck } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from '@akeela/properties';
import { TransitionService } from '../../../services/transition.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@akeela/security';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit, DoCheck {
  _transactionData = null;
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() link?;
  @Input() currentWorkflowChange?;
  @Input() currentWorkflowDefault?;
  @Input() set transactionData(value) {
    this._transactionData = value;

    //obtiene el valor para el boton en la validacon cuando es incompleto 
    this.getShowCommentsBooleanButton();


  }


  @Output() response = new EventEmitter<any>();
  tooltip = '';
  showModalResponses;
  incomplete;
  public dropdownactive = false;


  @ViewChild('ngbDropdownMenu', {static: false}) mydrop;
  view_more = false;
  showCommentsBoolean = [];
  showTemplates;
  pos;
  elemento;
  compleatTransaction = false;
  constructor(
    public config: NgbDropdownConfig,
    private _httpService: HttpService,
    private _transition: TransitionService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalService


  ) 
    
    {
    config.placement = 'bottom-left';
    config.autoClose = true;

  }

  ngOnDestroy() {
    this.showTemplates = false;

  }


public ngDoCheck() {
  this.pos = window.pageYOffset;

  if ( this.pos > 250) {

     this.elemento.classList.add('yy');
     this.elemento.classList.remove('xx');

  }else{

      this.elemento.classList.add('xx');
      this.elemento.classList.remove('yy');

  }

}


@HostListener('window:scroll', ['$event'])

  ngOnInit() {
    
    this.elemento = document.getElementById('enviar');

    this.showTemplates = true;

    if (this.link === 'requirements') {
      this.tooltip = 'Ir a Recaudos';
    } else if (this.link === 'clientinfo') {
      this.tooltip = 'Ir a Datos de la Solicitud';
    } else {
      this.tooltip = '';
    }
    if (isNullOrUndefined(this.link) || this.link === '') {
      this.link = null;
    }


    this.showCommentsBoolean['ACCOUNT_OPENING'] = true;
    this.showCommentsBoolean['REQUIREMENT'] = true;

    this._transition.compleatTransaction$.subscribe((response: any) => {
      
     this.compleatTransaction =  response;
    
  });


  }



  getShowCommentsBooleanButton() {
    

    if (!isNullOrUndefined(this._transactionData)) {
      if (this._transactionData.accountStatus[0].additionalType === 'AC_INCOMPLETE') {

        this.incomplete = true;

      } else {
        this.getHistoryStatusTransaction().then((history: any) => {
          if (history.length >= 2) {
            if (history[0].name === 'PI_IN_PROCESS' && history[1].name === 'AC_INCOMPLETE') {

              this.incomplete = true;

            }
          }
        });
      }
    }


  }
  getHistoryStatusTransaction() {
    return new Promise((resolve, reject) => {
      const params = {
        name: 'accounts$accountstates$get',
        params: {
          request: {},
          path: {
            transactionNumber: this._transactionData.transactionNumber
          },
          body: {}
        }
      };
    

      

      this._httpService.executeRequest(params.name, params.params, true).subscribe((response: any) => {
        resolve(response);
      }, error => {
        reject(false);
      });
    });


  }




  // @HostListener('click') open() {
  //   this.dropdownactive = !this.dropdownactive;
  // }

  event_response(response) {
    this.dropdownactive = !this.dropdownactive;
    this.response.emit(response);
  }

  refresh_status_dropdown(event) {
    this.dropdownactive = !this.dropdownactive;
  }

  view_mode_active() {
    this.view_more = !this.view_more;
  }


  redirectEnviar(code,sidebar) {   
    

    const prefixed = 'private';
    const url = 'ACCOUNT_OPENING/stages/ACCOUNT_CONTRACT/steps/ACCOUNT_CONTRACT';
    let queryParams= {
      role: this.localStorage.getItem('role'),
      profile: this.localStorage.getItem('profile'),
      audience: this.localStorage.getItem('audience')
    }

    this.router.navigate([prefixed + '/' + url], { queryParams: queryParams });
    



  }


}
