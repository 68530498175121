import { Route } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { WorkflowRoutes } from '@akeela/workflow';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { PublicDashboardComponent } from '../components/dashboard/dashboard.component';
import { RecoveryPasswordComponent } from '../components/recovery-password/recovery-password.component';
import { CreateUserPasswordComponent } from '../components/create-user-password/create-user-password.component';
import { UnlockPasswordComponent } from '../components/unlock-password/unlock-password.component';
import { ActivateAccountComponent } from '../components/activate-account/activate-account.component';
import { ValidateSession } from '../../../guards/validatesession.guard';
import { LogoutComponent } from '../components/logout/logout.component';
import { ChangePasswordPublicComponent } from '../components/change-password/change-password.component';

import { ProdutTypeComponent } from '../components/product-type/produt-type.component';
import { ProdutTypeRqComponent } from '../components/product-type-rq/product-type-rq.component';
import { OtpComponent } from '../components/otp/otp.component';
import { FaceApiComponent } from '../components/face-api/face-api.component';
import { AuthGuard } from '../../../guards/auth.guard';
import { AkeelaDashboardComponent } from '../../../modules/private/components/akeela-dashboard/akeela-dashboard.component';



export const PUBLIC_EXTERNAL_ROUTES: Route = {
    path: '',
    children: [
        {
            path: 'recovery-password',
            component: RecoveryPasswordComponent
        },
        {
            path: 'unlock',
            component: UnlockPasswordComponent
        },
        {
            path: 'create-new-password/:username/:token',
            component: CreateUserPasswordComponent
        },
        {
            path: 'change-password/:username/:token',
            component: ChangePasswordPublicComponent
        },
        {
            path: 'activate-account/:username/:token',
            component: ActivateAccountComponent
        },
        {
            path: 'logout',
            component: LogoutComponent
        },
        {
            path: 'products-type/:typeId',
            component: ProdutTypeComponent
        },
        {
            path: 'products-type-rq/:typeId',
            component: ProdutTypeRqComponent
        },
        {
            path: 'otp',
            component: OtpComponent
        },
        {
            path: 'faceapi',
            component: FaceApiComponent
        },
        {
            path: 'dashboard',
            component: AkeelaDashboardComponent,
            canActivate: [AuthGuard]
    
        },
    ]
};

export const PUBLIC_ROUTES: Route = {
    path: 'public',
    component: WorkflowComponent,
    children: WorkflowRoutes,
    canActivate: [ValidateSession]
};
