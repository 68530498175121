import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalService } from '@akeela/local-storage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Output() lang = new EventEmitter();
  url: String = '/login';
  token;
  env = environment;

  lang_local: string;
  constructor(
    private router: Router,
    private localStorage: LocalService
  ) {
    this.lang_local = 'es';
  }

  ngOnInit() {

  }

  changelang(i18n) {
    this.lang_local = i18n;
    this.lang.emit({ lang: this.lang_local });
  }

  login() {

    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.security.uriLogin],
      {
        queryParams: {}
      }
      )
    );
  }

  register() {
    this.localStorage.setItem('role', 'CLIENT');
    this.router.navigateByUrl(this.router.createUrlTree
      ([environment.staticLinks.register],
      {
        queryParams: {
          role: 'CLIENT'
        }
      }
      )
    );
  }

  redirectHome(){

    window.location.href = `${environment.defaultStorefrondRoute}`;  }

}
