import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '@akeela/auth';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit, AfterViewInit {
  @Input('parent') parent: string;
  @Input('line') line: string;

  lang: string;
  @ViewChild('footer', {static: false}) footer: ElementRef;

  height: number;
  environment;
  isAuth;

  constructor(private renderer: Renderer2,        public _auth: AuthService,
  ) { }
  ngOnInit() {
    this.environment = environment;


    this._auth.isAuth$.subscribe((isAuth: boolean) => {
      if (isAuth !== this.isAuth) {
          if (!isAuth) {
              if (this.isAuth) {
                  this.isAuth = isAuth;
              } else {
                  this.isAuth = isAuth;
              }
          } else {
              this.isAuth = isAuth;
          }
      }
  });
  }

  ngAfterViewInit() {

    setTimeout(() => {
      document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + 'px';
    this.height = this.footer.nativeElement.offsetHeight;
  
  }, 0);
   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    
    document.body.style.paddingBottom =
      this.footer.nativeElement.offsetHeight + 'px';
    this.height = this.footer.nativeElement.offsetHeight;
  }
}
