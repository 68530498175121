<!--
<div *ngIf="documents?.all" class="ak-shadow box-section p-2 pt-3">

                <h5>
                   <span >Documentos generales</span>
                </h5>
                <div class="text-secondary">
                      Documentos disponibles xxx xxx
                </div>
           
                <div class=" animate__animated animate__bounceInUp">
                    <div class="list d-flex bd-highlight justify-left-left text-center  list-flex flex-wrap  pt-2">
                      
                                   
                   
                   </div>
                </div>
           
           </div>-->

           <div *ngIf="documents?.all || documents?.dowload " class="ak-shadow box-section p-2 pt-3 mt-4">

                <h5>
                                <i class=" text-secondary pl-2 pr-2 fas fa-file-alt " aria-hidden="true" ></i>
                   <span >Documentos vinculados con el solicitante </span>
                </h5>
           
                <div class=" animate__animated animate__bounceInUp">

                    <div class="list d-flex bd-highlight justify-left-left text-center  list-flex flex-wrap  pt-2">
                                <div  *ngIf="documents?.all?.ficha" class="bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-50 w-sm-100">
                                             
                                                <a  href="javascript:void(0)" (click)="get_response(documents.all?.ficha.response)" class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                                             <div class="card-body" [ngStyle]="styleBox">
                                                         
                                                                    <div class="d-flex bd-highlight">
                       
                                                                            <div  class=" bd-highligh w-20">
                                                                                    <img  [src]="url+'/pdf50.png'" alt="">
                                                                             </div>
                                                                    
                                                                            <div  class=" bd-highligh w-80 text-left">
                            
                                                                                <div class="mb-1">
                                                                                        <span class=" font-weight-bold " [innerHTML]="'Ficha de Identificación '+ (documents.all?.ficha?.preview == true? '':'')"></span>
                                                                                        
                                                                                </div>  
                                                                                <div *ngIf="documents?.all?.preview != 'completed'" class="text-secondary" > {{name}} </div>                      
                          
                                                                            </div>
                                                                    </div>                   
                                         </div>
                                    </a>
                                </div>

                             <div *ngIf="documents?.dowload?.w8" class=" bd-highlight w-sm-100  ak-w-500 ">
                                        
                                              
                                <div  class="car pr-2 pl-2 pr-md-2  pl-md-2 ">

                                                <!-- {{urlAppPdf}}  http://localhost:4201/ -->
                                               <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.w8}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                                <div class="card-body" [ngStyle]="styleBox">
                                                        <div class="d-flex bd-highlight">
                    
                                                                <div  class=" bd-highligh w-20">
                                                                                <img  [src]="url+'/pdf50.png'" alt="">                                                                   </div>
                                                        
                                                                <div  class=" bd-highligh w-80 text-left">
                    
                                                                    <div class="mb-1">
                                                                            <span class=" font-weight-bold " [innerHTML]="'W8' | i18n"></span>
                                                                    </div>
                                                                    <div class="text-secondary" > {{name}} </div>                      
                    
                                                                </div>
                                                        </div>                
                                                </div>
                                                </a>
                                </div>

                                            <div class="text-secondary-2 ml-2 "style="margin-top: -13px;" >
                                                        Firme y adjunte en recaudos
                                                <!--
                                                        <a [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']" class="text-primary"  >   <i class=" fas fa-paperclip pl-1" ></i> </a>  --> 
                                          </div>

                                                        
                                        </div>
                    
                                        <div *ngIf="documents?.dowload?.w9" class="bd-highlight w-sm-100  ak-w-500 ">

                                            <div  *ngIf="documents?.dowload?.w9" class=" pr-2 pl-2 pr-md-2  pl-md-2 ">
                                
                                                    <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.w9}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                                        <div class="card-body" [ngStyle]="styleBox">
                                                                <div class="d-flex bd-highlight">
                    
                                                                        <div  class=" bd-highligh w-20">
                                                                                        <img  [src]="url+'/pdf50.png'" alt="">                                                                           </div>
                                                                
                                                                        <div  class=" bd-highligh w-80 text-left">
                        
                                                                            <div class="mb-1">
                                                                                    <span class=" font-weight-bold " [innerHTML]="'W9' | i18n"></span>
                                                                            </div>
                                                                            <div class="text-secondary" > {{name}} </div>                      
                        
                                                                        </div>
                                                                </div>                    
                                                        </div>
                                                     </a>
                                                     
                                                 </div>

                                                         <div class="text-secondary-2 ml-2 "style="margin-top: -13px;" >
                                                        Firme y adjunte en recaudos
                                                <!--
                                                        <a [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']" class="text-primary"  >   <i class=" fas fa-paperclip pl-1" ></i> </a>  --> 
                                          </div>

                                                        
                                        </div>
                    
                                        <div *ngIf="documents?.dowload?.cli" class="bd-highlight w-sm-100  ak-w-500 ">

                                                 <div *ngIf="documents?.dowload?.cli" class="pr-2 pl-2 pr-md-2  pl-md-2 ">
                                
                                                    <a    href="{{urlAppPdf}}/?code={{documents?.dowload?.cli}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                                        <div class="card-body" [ngStyle]="styleBox">
                                                     
                                                                <div class="d-flex bd-highlight">
                    
                                                                        <div  class=" bd-highligh w-20">
                                                                                        <img  [src]="url+'/pdf50.png'" alt="">                                                                           </div>
                                                                
                                                                        <div  class=" bd-highligh w-80 text-left">
                        
                                                                            <div class="mb-1">
                                                                                    <span class=" font-weight-bold " [innerHTML]="'CLI' | i18n"></span>
                                                                            </div>
                                                                            <div class="text-secondary" > {{name}} </div>                      
                        
                                                                        </div>
                                                                </div>  
                                                        </div>
                                                     </a>
                                                     
                                                 </div>

                                                         <div class="text-secondary-2 ml-2 "style="margin-top: -13px;" >
                                                        Firme y adjunte en recaudos
                                                <!--
                                                        <a [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']" class="text-primary"  >   <i class=" fas fa-paperclip pl-1" ></i> </a>  --> 
                                          </div>
                                                 </div>


                                                 <div *ngIf="documents?.dowload?.car" class="bd-highlight w-sm-100  ak-w-500 ">

                                                 <div  *ngIf="documents?.dowload?.car" class=" pr-2 pl-2 pr-md-2  pl-md-2  ">
                                
                                                    <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.car}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                                         <div class="card-body" [ngStyle]="styleBox">
                                                     
                                                                <div class="d-flex bd-highlight">
                    
                                                                        <div  class=" bd-highligh w-20">
                                                                                        <img  [src]="url+'/pdf50.png'" alt="">                                                                           </div>
                                                                
                                                                        <div  class=" bd-highligh w-80 text-left">
                        
                                                                            <div class="mb-1">
                                                                                    <span class=" font-weight-bold " [innerHTML]="'Carta Resolución de Autorización Corporativa' | i18n"></span>
                                                                            </div>
                                                                            <div class="text-secondary" > {{name}} </div>                      
                        
                                                                        </div>
                                                                </div>                   
                                                         </div>
                                                     </a>
                                                     
                                                 </div>

                                                 <div class="text-secondary-2 ml-2 "style="margin-top: -13px;" >
                                                                Firme y adjunte en recaudos
                                                        <!--
                                                                <a [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']" class="text-primary"  >   <i class=" fas fa-paperclip pl-1" ></i> </a>  --> 
                                                  </div>
                                                         </div>
        
                                
                   </div>
                </div>
           
           
           
           </div>

<!--

        
<div>
            <h5 class=" -section" (click)="showView = !showView" >
                    <i *ngIf="showView" class="fas fa-chevron-down "></i>
                    <i *ngIf="!showView" class="fas fa-chevron-up "></i>
               <span class="pl-2"> Documentos vinculados con el solicitante</span>
            </h5>

            <hr class="mb-1 mt-1">
            </div>

            <div class="pt-3 pb-1" *ngIf="dowloadAlert">
                    <div class=" p-2 alert ak-status alert-secondary text-left p-1 pt-2 pb-2 mb-2 bg-white" >
                            <i class=" text-secondary pr-2 fas fa-info-circle " aria-hidden="true"  ></i>
                 
                   <span class="text-dark" >Descargue, firme y adjunte mediante la opción de
                                
                                  <a [routerLink]="['DOC_SUPPORT/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS']" class="text-primary"  >   <i class=" fas fa-paperclip pl-1" ></i> Recaudos</a>         
                       
                  </span> 
                  
                   </div>
            </div>
           
            <div  *ngIf="showView" class="mt-2 animate__animated animate__bounceInUp">

                          <div class="list d-flex bd-highlight justify-left-left text-center  list-flex flex-wrap  pb-3 pt-2">
                            
                            
                            
                                <div  *ngIf="documents?.dowload?.ficha" class="bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-500 w-sm-100">
                                  
                                <a  href="javascript:void(0)" (click)="get_response(documents.ficha.response)" class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                             <div class="card-body" [ngStyle]="styleBox">
                                         
                                                    <div class="d-flex bd-highlight">
        
                                                            <div  class=" bd-highligh w-20">
                                                                    <img  src="../assets/facebank/public/images/pdf50.png" alt="">
                                                             </div>
                                                    
                                                            <div  class=" bd-highligh w-80 text-left">
            
                                                                <div class="mb-1">
                                                                        <span class=" font-weight-bold " [innerHTML]="'Ficha de Identificación '+ (documents.ficha.preview == true? previewText:'')"></span>
                                                                        
                                                                </div>  
                                                                <div *ngIf="documents?.dowload?.preview != 'completed'" class="text-secondary" > {{name}} </div>                      
          
                                                            </div>
                                                    </div>                   
                                             </div>
                                         </a>
                                         
                              </div>


                        <div *ngIf="documents?.dowload?.w8" class=" car bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-500 w-sm-100">

                            <!-- {{urlAppPdf}}  http://localhost:4201/ --><!--
                           <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.w8}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                            <div class="card-body" [ngStyle]="styleBox">
                                    <div class="d-flex bd-highlight">

                                            <div  class=" bd-highligh w-20">
                                                    <img  src="../assets/facebank/public/images/pdf50.png" alt="">
                                             </div>
                                    
                                            <div  class=" bd-highligh w-80 text-left">

                                                <div class="mb-1">
                                                        <span class=" font-weight-bold " [innerHTML]="'W8' | i18n"></span>
                                                </div>
                                                <div class="text-secondary" > {{name}} </div>                      

                                            </div>
                                    </div>                
                            </div>
                            </a>
                        </div>

                        <div  *ngIf="documents?.dowload?.w9" class="bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-500 w-sm-100">
            
                                <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.w9}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                    <div class="card-body" [ngStyle]="styleBox">
                                            <div class="d-flex bd-highlight">

                                                    <div  class=" bd-highligh w-20">
                                                            <img  src="../assets/facebank/public/images/pdf50.png" alt="">
                                                     </div>
                                            
                                                    <div  class=" bd-highligh w-80 text-left">
    
                                                        <div class="mb-1">
                                                                <span class=" font-weight-bold " [innerHTML]="'W9' | i18n"></span>
                                                        </div>
                                                        <div class="text-secondary" > {{name}} </div>                      
    
                                                    </div>
                                            </div>                    
                                    </div>
                                 </a>
                                 
                             </div>


                             <div *ngIf="documents?.dowload?.cli" class="bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-500 w-sm-100">
            
                                <a    href="{{urlAppPdf}}/?code={{documents?.dowload?.cli}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                    <div class="card-body" [ngStyle]="styleBox">
                                 
                                            <div class="d-flex bd-highlight">

                                                    <div  class=" bd-highligh w-20">
                                                            <img  src="../assets/facebank/public/images/pdf50.png" alt="">
                                                     </div>
                                            
                                                    <div  class=" bd-highligh w-80 text-left">
    
                                                        <div class="mb-1">
                                                                <span class=" font-weight-bold " [innerHTML]="'CLI' | i18n"></span>
                                                        </div>
                                                        <div class="text-secondary" > {{name}} </div>                      
    
                                                    </div>
                                            </div>  
                                    </div>
                                 </a>
                                 
                             </div>

                             <div  *ngIf="documents?.dowload?.car" class="bd-highlight pr-2 pl-2 pr-md-2  pl-md-2  ak-w-500 w-sm-100">
            
                                <a   href="{{urlAppPdf}}/?code={{documents?.dowload?.car}}"  target="_blank"  class="  mt-3 mb-3 active-cursor card w-100 border  animate__animated animate__fadeIn">
                                     <div class="card-body" [ngStyle]="styleBox">
                                 
                                            <div class="d-flex bd-highlight">

                                                    <div  class=" bd-highligh w-20">
                                                            <img  src="../assets/facebank/public/images/pdf50.png" alt="">
                                                     </div>
                                            
                                                    <div  class=" bd-highligh w-80 text-left">
    
                                                        <div class="mb-1">
                                                                <span class=" font-weight-bold " [innerHTML]="'Carta Resolución de Autorización Corporativa' | i18n"></span>
                                                        </div>
                                                        <div class="text-secondary" > {{name}} </div>                      
    
                                                    </div>
                                            </div>                   
                                     </div>
                                 </a>
                                 
                             </div>

  
</div>

-->