// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// VERSIÓN FACEBANK CALIDAD
const workflow = 'ACCOUNT_OPENING'; // Esté siempre será el por defecto
const workflow2 = 'DOC_SUPPORT';
export const environment = {
    calidad:true,
    production: true,
    procedureMail: 'Apertura de Cuenta',
    urlFront: 'http://clientcert.mibanco.com.ve',
    workflow: workflow,
    workflow2: workflow2,
    defaultInitRoute:'public/' + workflow + '/stages/LOGIN_' + workflow + '/steps/LOGIN_' + workflow,
    defaultStorefrondRoute: 'dashboard/' + workflow + '/stages/ACCOUNT_SELECTION/steps/ACCOUNT_SELECTION',
    defaultInitRouteAuthenticated: '/dashboard',
    middleware: {
        environment: 'accounts',
        module: 'REQUIREMENT'
    },
    staticLinks: {
        login: 'public/' + workflow + '/stages/LOGIN_' + workflow + '/steps/LOGIN_' + workflow,
        register: 'public/' + workflow + '/stages/CREATE_USER_' + workflow + '/steps/CREATE_USER_' + workflow,
        listTransactions: 'private/' + workflow + '/stages/LIST_TRANSACTIONS/steps/LIST_TRANSACTIONS',
        requirementsUpload: 'private/' + workflow2 + '/stages/DOC_SUPPORT/steps/UPLOAD_REQUIREMENTS',
        detailClientResumen: 'private/' + workflow + '/stages/SUMMARY_ACCOUNT_OPENING/steps/SUMMARY_ACCOUNT_OPENING',
        firstStep: 'private/' + workflow + '/stages/PRODUCT_INFORMATION/steps/PRODUCT_INFORMATION'


    },
    theme: {
        images: 'MIBANCO',
        backgroundColor: '#1e7e34',
        backgroundColorBorderMail: '#1e7e34',
        fontColor: '#FFFFFF',
        site: {
            name: 'Mi Banco, Banco Microfinanciero C.A.',
            title: 'ARN | CLIENT',
            url: 'http://www.mibanco.com.ve/',
            supportMail: 'online@mibanco.com.ve'

        },
        image: {
            active: true,
            urlImageHeader: 'https://apertura.mibanco.com.ve/arn/assets/mibanco/public/images/image.png'
        },
        textFooterMail: 'Copyright 2006 Mi Banco, Banco Microfinanciero. C.A. RIF: J-31594102-3, Todos los derechos reservados.',

        sizeImageLogoNavbar: "3",
        sizeImageLogoNavbarMovil: "3",
        sizeImageLogoMenu: "3",
        logoConf: {
            theme: {
                sure: {
                    urlLogo: 'assets/mibanco/public/images/image.png',
                    urlLogoMin: 'assets/mibanco/public/images/image.png',
                },

                dark: {
                    urlLogo: 'assets/mibanco/public/images/mibanco2.svg',
                    urlLogoMin: 'assets/mibanco/public/images/mibanco2.svg'
                }
            },
            themeColorDefault: 'sure',
            themeColorNav: 'sure',
            themeColorNavAuth: 'sure',
            themeColorMenu: 'sure',
            themeColorNavModal: 'sure',
            themeColorPdf: 'sure',


            typeDefault: null,
            typeNav: 1,
            typeNavAuth: 1,
            typeMenu: 1,
            typePdf: 1,
            typeNavModal: null,
        },
        showRegister: true



    },
    apiConfig: {
        useMock: false,
        urlApi: 'http://201.249.47.52:8080/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: true,
        mask: {
            telephone: 'mask_tlf_3'
        },
        autoSave:true,
        autoSaveTime:15000
    },
    appPropertiesConfig: {
        useMock: false,
        urlApi: 'http://201.249.47.52:8080/',
        uriPropertiesPrefix: 'app_properties'
    },
    appEndPontDefaultConfig: {
        workflowState: 'workflowState',
        stepState: 'stepState',
        transitions: 'transitions',
        sectionsState: 'sectionsState',
        menu: 'menu',
        transactionCode: 'transactionCode',
        stageStatus: 'stageStatus',
        sidebarLeftActive:'HOME',
        showPayroll:true,
        isFieldNotRequired:false


    },
    i18nConfig: {
        tagOpen: '{',
        tagClose: '}',
        constants: {
            clientName: 'Mi Banco, Banco Microfinanciero, C.A.',
            clientNameShort: 'Mi Banco',
            bankAccountInstitution: 'Mi Banco',
            url: 'http://www.mibanco.com.ve',
            urlDocumentConditions: '/assets/mibanco/public/documents/contratounico05032015.pdf'
        }
    },
    wfConfig: {
        useMock: false,
        urlApi: 'http://201.249.47.52:8080/',
        uriWorkflowPrefix: 'workflow_manager',
        initiallyOpenSections: true,
        autoSave:true,
        autoSaveTime:15000   
    },
    security: {
        urlApi: 'http://201.249.47.52:8080/authorization-manager/api/v1',
        clientCode: 'QUALITY_CLIENT',
        uriLogin: 'security/login',
        uriLogout: 'logout',
        urlForgotPassword: 'security/forgot-password',
        uriRefreshToken: 'refresh',
        urlSecurityApi: 'http://201.249.47.52:8080/person'
    },
    paginator: {
        show: true,
        page: 0,
        size: 10,
        maxPages: 10
    },
    auth: {
        activeEncrypt: true,
        hasProfiles: false,
        inactivity: {
            active: true
        }
    },
    configDefauld: {
        showDescriptionReq: false,
        showNoteCategory: false,
        iconProduct: false,
        styleBox: { "min-height": "auto" }

    },
    formsValidations: {
        login: {
            username: {
                minLength: 6,
                maxLength: 12
            },
            password: {
                minLength: 8,
                maxLength: 12
            }
        }
    },
    requirements: {
      file: {
        mb_max_filesize: 1
      }
    },
    localStorageConfig: {
        activeEncrypt: true
    },
    responseOptionalFieldsIndividualResponse: {
        observationInside: true,
        reasonIncompleted: [
            {
                identifier: '',
                name: '@i18n-requirements-ilegible'
            },
            {
                identifier: '',
                name: '@i18n-requirements-desactualizado'
            },
            {
                identifier: '',
                name: '@i18n-requirements-information-additional-required'
            },
        ]
    },
    showOperationDataResumen: true,
    showTransactionsRelatedResumen: true,
    showTips: true,
    facialRecognition: {
        debug: false,
        active: true,
        draw: false,
        landmarks: ['expressions', 'landmarks', 'descriptors', 'ageAndGender'],
        maxAttempt: 2
    },
    visor: 'alternative'

};

