

<div class="container animated fadeIn px-0">
    <div class="mb-0 row box  box-section skin-white  animated fadeIn" style="padding-left: 0; padding-right:0">
        <div class="col-12 mt-2">
            <ng-container *ngIf="((itemList && itemList.length> 0)) && !loading_table && tableShow">

                <div *ngIf="(itemList && itemList.length>0) ">
                    <div class="text-dark ak-title-table mt-2 mb-4 ">
                        <span [innerHTML]="'@i18n-register-text-standard-table' | i18n:lang "></span>
                        <span [innerHTML]="'@i18n-quote-suppliers-section.title' | i18n:lang "></span>
                    </div>
                    <akeela-table [configuration]="configuration_table" [loading]="loading_table" (response)="table_response($event)">
                    </akeela-table>
                </div>

                <!--
                    <div *ngIf="!((itemList && itemList.length===0) && !showLoading && !checkDoNotHaveSuppliers)"  class="d-none bd-highlight list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2 d-lg-flex">
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-40  order-1 ml-4 ml-md-0  ">
                                <div class="font-weight-bold text-capitalize">
                                    Identificación 
                                </div>
                            </div>
    
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  order-2 ml-4 ml-md-0 ">
                                    <div class="font-weight-bold text-capitalize">
                                        Tipo
                                    </div>
                            </div>
                            
                            <div class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  order-3   ml-4 ml-md-0 ">
                                    <div class="font-weight-bold text-capitalize">
                                        Destinatario
                                    </div>
                            </div>
                     </div>
               
                <div class="w-100 fz-body" *ngFor="let row of itemList; let first = first; let i_stf = index">
                    <hr *ngIf="!first" class="m-0 ">
                    <div class="d-flex bd-highlight active-cursor  list-flex flex-wrap pb-3 pt-3 pb-md-2 pt-md-2">
                        <div *ngIf="row?.personContact" (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-40 w-sm-100  order-1  ml-4 ml-md-0 ">
                            <div class="text-capitalize">
                                {{row?.personContact?.givenName}} {{row?.personContact?.familyName}}
                            </div>
                        </div>

                        <div *ngIf="!row?.personContact" (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-40  w-sm-100 order-1  ml-4 ml-md-0 ">
                            <div class="text-capitalize">
                                {{row?.organizationContact?.legalName}}
                            </div>
                        </div>



                        <div (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  w-sm-100  order-1  ml-4 ml-md-0 ">
                                <div *ngIf="row?.alternateName" >
    
                                        <span *ngIf="row?.alternateName == 'NATURAL'"   [innerHTML]="'@i18n-quote-option-list-applicantType-option-natural' | i18n:lang "></span>
                                        <span *ngIf="row?.alternateName == 'LEGAL'"   [innerHTML]="'@i18n-quote-option-list-applicantType-option-legal' | i18n:lang "></span>
                                    
                                    </div>
                         </div>

                        <div  (click)="openItem(row)" class="mb-1 mb-md-0  bd-highlight  pl-md-2 pr-md-2 w-20  order-1  w-sm-50  ml-4 ml-md-0 ">
                                <div class="text-capitalize">
                                    {{row?.isRecipient?'Si':'No'}}
                                </div>
                         </div>


                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  w-sm-40  order-2">
                            <a *ngIf="!readonlyMode" href="javascript:void(0)" (click)="confirmDelete(row)" class="font-weight-bold">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>

                        <div class=" mb-1 mb-md-0 text-md-right  mt-md-0 w-10 pl-md-2 pr-md-2  order-3 d-none d-md-block ">
                            <a href="javascript:void(0)" (click)="openItem(row)" class="font-weight-bold">
                                <i class="fas fa-angle-right text-primary"></i>
                            </a>
                        </div>

                    </div>
                </div>
            -->
            </ng-container>
            <div class="w-100 ak-table-0-comment">

                    <div class="text-dark mt-2 mb-4 "   *ngIf="(itemList && itemList.length===0) && !loading_table && tableShow">
                            <i class="text-secondary far fa-comment mr-3"></i>               
                                  <span [innerHTML]="'@i18n-register-text-standard' | i18n:lang "></span>
                    <span [innerHTML]="'@i18n-quote-suppliers-section.title' | i18n:lang "></span>
                </div>

                <ng-container *ngIf="loading_table">
                    <div>
                        <img  src="./assets/public/images/loading.gif" alt="">
                    </div>
                </ng-container>
            </div>
        </div>
    </div>