import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from "@angular/core";

//MODULES...
import { I18nModule } from "@akeela/i18n";
import { SharedModule } from "../../shared/shared.module";
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";
import { ProductsComponent } from './components/products/products.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { PublicDashboardComponent as DashboardComponent } from './components/dashboard/dashboard.component';
import { AkeelaTemplateService as AkeelaTemplateServiceProductsPlans } from '@akeela/products-plans';
import { PlansComponent } from './components/plans/plans.component';
import { LoginComponent } from './components/login/login.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { SubheaderComponent } from './components/plans/subheader/subheader.component';
import { RecoveryPasswordComponent as PublicRecoveryPasswordComponent } from './components/recovery-password/recovery-password.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { CreateUserPasswordComponent as PublicCreateUserPasswordComponent } from './components/create-user-password/create-user-password.component';
import { UnlockPasswordComponent } from './components/unlock-password/unlock-password.component';
import { LogoutComponent } from './components/logout/logout.component';

import { ProdutTypeComponent } from './components/product-type/produt-type.component';
import { ProdutTypeRqComponent } from './components/product-type-rq/product-type-rq.component';

import { ChangePasswordPublicComponent } from './components/change-password/change-password.component';
import { OtpComponent } from './components/otp/otp.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { FaceApiComponent } from './components/face-api/face-api.component';


@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [AkeelaTemplateServiceProductsPlans],
  declarations: [LogoutComponent, ProductsComponent, WorkflowComponent, DashboardComponent, PlansComponent, LoginComponent, CreateUserComponent, SubheaderComponent, PublicRecoveryPasswordComponent, ActivateAccountComponent, PublicCreateUserPasswordComponent, UnlockPasswordComponent, ProdutTypeComponent, ProdutTypeRqComponent, ChangePasswordPublicComponent, OtpComponent, OtpVerificationComponent, FaceApiComponent],
  exports: [LogoutComponent, ProductsComponent, WorkflowComponent]
})
export class PublicModule { }